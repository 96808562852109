export const wardType = {
  id: 'Id',
  ward: 'Ward Or Room',
  floor: 'Floor Number',
  location: 'Operating Unit',
  noWards: 'No  Wards Found',
  maximumBeds: 'Maximum Beds',
}
export const bedType = {
  id: 'Id',
  bedName: 'Bed',
  floor: 'Floor Number',
  roomName: 'Room',
  location: 'Operating Unit',
  bedCategory: 'Bed Category',
  noBeds: 'No Beds Found',
  seqNo: 'Seq.No',
}

export const roomType = {
  id: 'Id',
  wardName: 'Ward Name',
  floor: 'Floor Number',
  roomNUmber: 'Room Number',
  roomName: 'Room Name',
  location: 'Operating Unit',
  noRooms: 'No Rooms Found',
}

export const AdminIntent = {
  DeletepanelById: 'deletePanelById',
  InactiveTests: 'InactiveTests',
  DeletePackageById: 'DeletePackage',
  InactivePackage: 'inactivePackage',
}
export const referredTo = 'Referred To'
export const validatedOn = 'Validated on'

export const Labintent = {
  getReferredLabResults: 'getReferredLabResults',
  getPatientsByIdsApi: 'getPatientsByIdsApi',
  getOrderEventsByOrder: 'getOrderEventsByOrder',
}
