export const SYMPTOMS = [
  'tachypnea',
  'tachycardia',
  'fever',
  'headache',
  'abdominalPain',
  'bleeding',
]

export const PROGRESSNOTE_ERROR_MESSAGES = {
  issues: {
    signsAndSymptoms: {
      tachypnea: 'Tachypnea is required',
      tachycardia: 'Tachycardia is required',
      fever: 'Fever is required',
      headache: 'Headache is required',
      abdominalPain: 'Abdominal pain is required',
      bleeding: 'Bleeding is required',
      otherObservations: 'Other observations are required',
    },
    allergies: 'Required',
    pastMedicalHistory: 'Past medical history is required',
  },
  hoPresentingcomplaints: {
    chestPain: {
      chestPainOnset: 'Chest pain onset is required',
      chestPainProgression: 'Chest pain progression is required',
      chestPainOtherFindings: 'Chest pain other findings are required',
    },
    palpitations: {
      palpitationsDuration: '',
      palpitationsOnset: 'Palpitations onset is required',
      palpitationsProgression: 'Palpitations progression is required',
      palpitationsOtherFindings: 'Palpitations other findings are required',
    },
    breathlessness: {
      breathlessnessDuration: 'Breathlessness duration is required',
      breathlessnessOnset: 'Breathlessness onset is required',
      breathlessnessProgression: 'Breathlessness progression is required',
      breathlessnessOtherFindings: 'Breathlessness other findings are required',
    },
  },
  assessment: {
    airway: {
      patent: 'Airway patent is required',
      advancedAirway: 'Advanced airway is required',
      confirmPlacement: 'Confirm placement is required',
    },
    breathing: {
      ventilator: 'Ventilator is required',
      mode: 'Mode is required',
      saturation: 'Saturation is required',
      respiratoryRate: 'Respiratory rate is required',
    },
    circulation: {
      bloodPressure: 'Blood pressure is required',
      heartRate: 'Heart rate is required',
      vasopressors: 'Vasopressors are required',
      rhythmChange: 'Rhythm change is required',
      defibMedication: 'Defibrillation/medication is required',
    },
    disability: {
      gcs: 'GCS is required',
      grbs: 'GRBS is required',
      pupils: 'Pupils are required',
      temperature: 'Temperature is required',
    },
    exposure: {
      bedSores: 'Bed sores are required',
      abrasions: 'Abrasions/burns/unusual markings are required',
    },
  },
  others: {
    feeding: {
      lastMealConsumedEvents: 'Last meal consumed events are required',
      NBM: 'NBM is required',
      Enteral: 'Enteral feeding is required',
      TPN: 'TPN is required',
    },
    analgesia: {
      VASscore: 'VAS score is required',
    },
    sedation: 'Sedation is required',
    thromboprophylaxis: 'Thromboprophylaxis is required',
    headAndElevation: 'Head and elevation is required',
    ulcerProhylaxis: 'Ulcer prophylaxis is required',
    glycemicControl: 'Glycemic control is required',
    bowelBladder: 'Bowel/bladder is required',
    intravascularDevices: 'Intravascular devices are required',
    drugsDeescalationOfAntibiotics:
      'Drugs de-escalation of antibiotics is required',
    spontaneousBreathingTrial: 'Spontaneous breathing trial is required',
  },
}
