export interface DeviceDetails {
  ambulanceIdentifier: string
  ambulanceNumber: string
  emtPhone: string
  driverPhone: string
  proDeviceId: string
  ambulanceStatus: string
  mapId: number | undefined | null
  totalCount: number | undefined | null
  id: number | undefined | null
}

export interface DeviceForm {
  ambulanceIdentifier: string
  ambulanceNumber: string
  emtPhone: string
  driverPhone: string
  proDeviceId: string
  ambulanceStatus: string
  mapId: number | undefined | null
}

export const INTENT_CREATE_UPDATE_DEVICE = 'insertProrithmDetails'
export const INTENT_GET_DEVICES = 'getProRithmDeviceDetails'
export const INTENT_DELETE_DEVICE = 'deleteProrithmDetails'

export const AMBULANCE_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'InActive',
    value: 'INACTIVE',
  },
]
