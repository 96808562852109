import React, { useState } from 'react'
import './Filter.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  setPatientStatusActive,
  fetchPatientsAsync,
  setPatientData,
} from '../patientsSlice'
import { Button } from '@mui/material'

const FilterButton = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  // const [statusFilter, setStatusFilter] = useState<'active' | 'inactive'>(
  //   'active'
  // )
  const [isActionOpen, setIsActionOpen] = useState(false)

  const dispatch = useAppDispatch()
  const patients = useAppSelector((state) => state.patients?.data)

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen)
    setIsActionOpen(false)
  }

  const handleStatusFilterChange = (filter: 'active' | 'inactive') => {
    // setStatusFilter(filter)
    dispatch(setPatientStatusActive(filter === 'active'))
    dispatch(fetchPatientsAsync())
  }

  const handleDownload = () => {
    console.log('Downloading...')
    setIsActionOpen(false)
  }

  const toggleAction = () => {
    setIsActionOpen(!isActionOpen)
    setIsFilterOpen(false)
  }
  const handleFilterData = () => {
    if (!patients) return
    const filteredPatients = patients.filter((patient) => {
      const identifier = patient?.resource?.identifier?.[2]?.value
      return (
        identifier?.startsWith('MUH888') || identifier?.startsWith('MUH777')
      )
    })
    dispatch(setPatientData(filteredPatients))
  }

  return (
    <div className='filter-dropdown'>
      <Button
        sx={{
          textTransform: 'none',
        }}
        className='filter-button'
        onClick={toggleFilter}
        disableRipple
      >
        Filter
      </Button>
      <Button
        sx={{
          textTransform: 'none',
        }}
        className='filter-button'
        onClick={toggleAction}
      >
        Action
      </Button>
      {isFilterOpen && (
        <div className='dropdown-content'>
          <div
            className='menu2'
            onClick={() => handleStatusFilterChange('active')}
          >
            Active
          </div>
          <div
            className='menu2'
            onClick={() => handleStatusFilterChange('inactive')}
          >
            Inactive
          </div>
          <div className='menu2' onClick={handleFilterData}>
            Other Registrations
          </div>
        </div>
      )}
      {isActionOpen && (
        <div className='dropdown-content' style={{ marginLeft: '4rem' }}>
          <div className='menu3' onClick={handleDownload}>
            Export
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterButton
