import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  fetchPatientsAsync,
  resetPatientStatus,
} from '../../patients/patientsSlice'
import IPDPatientTable from './IPDPatientTable'
import IpdSearch from './IpdSearch'
import IpdVisitCreateForm from './IpdVisitCreateForm'
import '../bms.scss'
import { selectPatientId, setQueryParam } from './IpdVisitSlice'
import { Dialog, IconButton, DialogContent } from '@mui/material'
import { allocation } from '../modals'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'

const IpdDashBoard = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPatientsAsync())
    return () => {
      dispatch(resetPatientStatus())
    }
  }, [dispatch])

  // const [show, setShow] = useState<boolean>(false)
  const [initialDisplay, setInitialDisplay] = useState<boolean>(false)
  // const [query, setQuery] = useState<string>('')
  const [param, setParam] = useState<string>('')
  // const [patientId, setPatientId] = useState<string>('')
  const [dataUpdated, setDataUpdated] = useState(false)
  const selectedPatientId = useAppSelector(selectPatientId)
  const [visit, setVisit] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (selectedPatientId) {
      setInitialDisplay(true)
    }
  }, [selectedPatientId])
  const handleSearch = (q: string, p: string) => {
    // setQuery(q)
    setParam(p)
    // setPatientId('')
    setInitialDisplay(true)
    if (!q || !p) {
      // setShow(false)
    } else {
      // setShow(true)
    }
    dispatch(setQueryParam({ patientId: p }))
  }
  const visitCreate = () => {
    setVisit(true)
  }
  const handleCloseForm = () => {
    setVisit(false)
    setDataUpdated((prev) => !prev)
  }
  const handleBackToDashboard = () => {
    navigate('/bedIpd/bedAssignmentList')
  }
  return (
    <>
      {visit && (
        <Dialog open={visit} maxWidth='sm'>
          <IconButton
            onClick={handleCloseForm}
            className='flex-end f12 ml-auto'
          >
            <CloseIcon fontSize='small' />
          </IconButton>
          <DialogContent className='padding20 margin2'>
            <IpdVisitCreateForm
              patientExist='noExist'
              onClose={handleCloseForm}
              patientUhid={param ? param : selectedPatientId}
            />
          </DialogContent>
        </Dialog>
      )}
      <div className='layoutcontent'>
        <div className='dashboard-context'>
          <span
            onClick={handleBackToDashboard}
            className='back-button-cursor-dashboard'
          >
            <KeyboardBackspaceIcon />
          </span>
          <span className='margin3 fw600'>{allocation.ipVisitDashboard}</span>
          <IpdSearch onSearch={handleSearch} onCreateVisit={visitCreate} />
        </div>
        <IPDPatientTable
          patientId={selectedPatientId}
          dataUpdated={dataUpdated}
          initialDisplay={initialDisplay}
        />
      </div>
    </>
  )
}
export default IpdDashBoard
