import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  useTheme,
  Box,
  FormGroup,
  FormControlLabel,
  Radio,
  TextField,
  MenuItem,
  FormControl,
  RadioGroup,
  FormHelperText,
} from '@mui/material'
import useCustomStyles from '../../../../../utils/hooks/CustomStylesHook'
import {
  DECISION_OPTIONS,
  OBSTETRIC_FORM_PARITY,
  OBSTETRIC_OPTION_DATA,
  OBSTETRIC_PARITY,
  OBSTETRIC_RADIO_OPTIONS,
  REVIEW_SYSTEMS_DATA,
} from './Constants'

const styles = () => ({
  reviewSystemsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 20,
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      // fontWeight: 600,
    },
  },
  table: {
    minWidth: 650,
    border: '1px solid #e6e6e6',
  },
  checkbox: {
    padding: '0 12px',
  },
  checkboxCell: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tableHeadCell: {
    backgroundColor: '#4682b4',
    color: '#ffffff',
    border: '1px solid #e6e6e6',
    padding: '5px 10px',
  },
  tableCell: {
    border: '1px solid #e6e6e6',
  },
  formFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 30,
  },
  formSubHeading: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#183f7c',
  },
  obstetricValueOptions: {
    display: 'flex',
    flexDirection: 'row',
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
    flex: '0 1 23%',
  },
})

type ObstetricOptionKey = keyof typeof OBSTETRIC_OPTION_DATA

const ReviewDetails = ({ form }: any) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const selectedParityLabel =
    OBSTETRIC_FORM_PARITY?.find(
      (item) => item.value === form?.values?.obstetric?.parity
    )?.label || OBSTETRIC_PARITY.parity

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    system: string
  ) => {
    const { name, checked } = event.target

    form?.setFieldValue(`reviewOfSystems.${system}`, {
      ...form?.values?.reviewOfSystems?.[system],
      [name]: checked,
    })
  }

  return (
    <div className={classes?.reviewSystemsContainer}>
      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>General Examination</div>
        <FormGroup row>
          {Object.keys(form?.values?.generalExamination)?.map((key) => (
            <FormControlLabel
              labelPlacement='top'
              control={
                <Checkbox
                  size='small'
                  checked={form?.values?.generalExamination[key]}
                  onChange={(event) =>
                    form?.setFieldValue(
                      `generalExamination.${key}`,
                      event.target.checked
                    )
                  }
                  name={`generalExamination.${key}`}
                />
              }
              label={key.toUpperCase()}
              key={key}
            />
          ))}
        </FormGroup>
      </div>
      <div className={classes?.formFlexColumn}>
        <div className={classes?.formFlexColumn}>
          <div className={classes?.formSubHeading}>Obstetric</div>
          <FormGroup row>
            {OBSTETRIC_RADIO_OPTIONS?.map((option) => (
              <FormControlLabel
                labelPlacement='top'
                control={
                  <Radio
                    size='small'
                    checked={
                      form?.values?.obstetric?.obstetricValue === option?.value
                    }
                    onChange={() =>
                      form?.setFieldValue(
                        'obstetric.obstetricValue',
                        option?.value
                      )
                    }
                    name='obstetricValue'
                  />
                }
                label={option?.label}
                key={option?.value}
              />
            ))}
          </FormGroup>
        </div>
        {form?.values?.obstetric?.obstetricValue ===
        DECISION_OPTIONS?.[0]?.value ? (
          <div className={classes?.formFlexColumn}>
            <>
              <div className={classes?.formField}>
                <label>Gravidity</label>
                <TextField
                  size='small'
                  type='number'
                  name='obstetric.gravidity'
                  placeholder='Gravidity'
                  value={form?.values?.obstetric?.gravidity}
                  onChange={form?.handleChange}
                  onBlur={form?.handleBlur}
                  error={
                    form?.touched?.obstetric?.gravidity &&
                    Boolean(form?.errors?.obstetric?.gravidity)
                  }
                  helperText={
                    form?.touched?.obstetric?.gravidity &&
                    form?.errors?.obstetric?.gravidity
                  }
                />
              </div>
              <div className={classes?.formField}>
                <label>Parity</label>
                <TextField
                  select
                  size='small'
                  name='obstetric.parity'
                  placeholder='Parity'
                  value={form?.values?.obstetric?.parity}
                  onChange={form?.handleChange}
                  onBlur={form?.handleBlur}
                  error={
                    form?.touched?.obstetric?.parity &&
                    Boolean(form?.errors?.obstetric?.parity)
                  }
                  helperText={
                    form?.touched?.obstetric?.parity &&
                    form?.errors?.obstetric?.parity
                  }
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                    },
                  }}
                >
                  {OBSTETRIC_FORM_PARITY?.map(
                    (item: { label: string; value: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </div>
              {form?.values?.obstetric?.parity ? (
                <div className={classes?.formField}>
                  <label>Parity Details</label>
                  <TextField
                    size='small'
                    name='obstetric.parityDetails'
                    placeholder={`${selectedParityLabel} Details`}
                    value={form?.values?.obstetric?.parityDetails}
                    onChange={form?.handleChange}
                    onBlur={form?.handleBlur}
                    error={
                      form?.touched?.obstetric?.parity &&
                      Boolean(form?.errors?.obstetric?.parity)
                    }
                    helperText={
                      form?.touched?.obstetric?.parity &&
                      form?.errors?.obstetric?.parity
                    }
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                      },
                    }}
                  ></TextField>
                </div>
              ) : null}
              <div className={classes?.formField}>
                <label>Last Menstrual Period</label>
                <TextField
                  size='small'
                  type='date'
                  name='obstetric.lastMenstrualPeriod'
                  placeholder='Last Menstrual Period'
                  InputLabelProps={{ shrink: true }}
                  value={form?.values?.obstetric?.lastMenstrualPeriod}
                  onChange={form?.handleChange}
                  onBlur={form?.handleBlur}
                  error={
                    form?.touched?.obstetric?.lastMenstrualPeriod &&
                    Boolean(form?.errors?.obstetric?.lastMenstrualPeriod)
                  }
                  helperText={
                    form?.touched?.obstetric?.lastMenstrualPeriod &&
                    form?.errors?.obstetric?.lastMenstrualPeriod
                  }
                />
              </div>
              <div className={classes?.formField}>
                <label>Days</label>
                <TextField
                  size='small'
                  name='obstetric.days'
                  placeholder='Days'
                  type='number'
                  value={form?.values?.obstetric?.days}
                  onChange={form?.handleChange}
                  onBlur={form?.handleBlur}
                  error={
                    form?.touched?.obstetric?.days &&
                    Boolean(form?.errors?.obstetric?.days)
                  }
                  helperText={
                    form?.touched?.obstetric?.days &&
                    form?.errors?.obstetric?.days
                  }
                />
              </div>

              <div className={classes?.formField}>
                <label>History of Contraception</label>
                <FormControl component='fieldset'>
                  <RadioGroup
                    row
                    name='obstetric.historyOfContraception'
                    value={form?.values?.obstetric?.historyOfContraception}
                    onChange={form?.handleChange}
                    onBlur={form?.handleBlur}
                  >
                    {DECISION_OPTIONS?.map((option) => (
                      <FormControlLabel
                        key={option?.value}
                        value={option?.value}
                        control={<Radio size='small' />}
                        label={option?.label}
                      />
                    ))}
                  </RadioGroup>
                  {form?.touched?.obstetric?.historyOfContraception &&
                    form?.errors?.obstetric?.historyOfContraception && (
                      <FormHelperText error>
                        {form?.errors?.obstetric?.historyOfContraception}
                      </FormHelperText>
                    )}
                </FormControl>

                {form?.values?.obstetric?.historyOfContraception ===
                  DECISION_OPTIONS?.[0]?.value && (
                  <>
                    <label>Contraception Details</label>
                    <TextField
                      variant='outlined'
                      fullWidth
                      placeholder='Provide contraception details'
                      value={form?.values?.obstetric?.contraceptionDetails}
                      size='small'
                      name='obstetric.contraceptionDetails'
                      onChange={form?.handleChange}
                      onBlur={form?.handleBlur}
                      error={
                        form?.touched?.obstetric?.contraceptionDetails &&
                        Boolean(form?.errors?.obstetric?.contraceptionDetails)
                      }
                      helperText={
                        form?.touched?.obstetric?.contraceptionDetails &&
                        form?.errors?.obstetric?.contraceptionDetails
                      }
                    />
                  </>
                )}
              </div>
              <div className={classes?.formField}>
                <label>Fertility Treatment</label>
                <TextField
                  size='small'
                  name='obstetric.fertilityTreatment'
                  placeholder='Fertility Treatment'
                  value={form?.values?.obstetric?.fertilityTreatment}
                  onChange={form?.handleChange}
                  onBlur={form?.handleBlur}
                  error={
                    form?.touched?.obstetric?.fertilityTreatment &&
                    Boolean(form?.errors?.obstetric?.fertilityTreatment)
                  }
                  helperText={
                    form?.touched?.obstetric?.fertilityTreatment &&
                    form?.errors?.obstetric?.fertilityTreatment
                  }
                />
              </div>

              <div className={classes?.obstetricValueOptions}>
                {Object.keys(OBSTETRIC_OPTION_DATA)?.map((optionKey) => {
                  const key = optionKey as ObstetricOptionKey // Type assertion
                  const optionLabel = OBSTETRIC_OPTION_DATA[key]
                  return (
                    <FormControlLabel
                      labelPlacement='top'
                      control={
                        <Radio
                          size='small'
                          checked={
                            form?.values?.obstetric?.obstetricOptions === key
                          }
                          onChange={() =>
                            form?.setFieldValue(
                              'obstetric.obstetricOptions',
                              key
                            )
                          }
                          name='obstetricOptions'
                        />
                      }
                      label={optionLabel}
                      key={key}
                    />
                  )
                })}
              </div>
            </>
            {form?.values?.obstetric?.obstetricOptions ? (
              <TextField
                label={`Details for ${
                  OBSTETRIC_OPTION_DATA[
                    form?.values?.obstetric
                      ?.obstetricOptions as ObstetricOptionKey
                  ]
                }`}
                variant='outlined'
                size='small'
                placeholder={`Details for ${
                  OBSTETRIC_OPTION_DATA[
                    form?.values?.obstetric
                      ?.obstetricOptions as ObstetricOptionKey
                  ]
                }`}
                error={
                  form?.touched?.obstetric?.obstetricOptionDetails &&
                  Boolean(form?.errors?.obstetric?.obstetricOptionDetails)
                }
                helperText={
                  form?.touched?.obstetric?.obstetricOptionDetails &&
                  form?.errors?.obstetric?.obstetricOptionDetails
                }
                name='obstetric.obstetricOptionDetails'
                onChange={form?.handleChange}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>Review Of Systems</div>
        <TableContainer sx={{ height: 500 }}>
          <Table className={classes?.table} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes?.tableHeadCell}>
                  Systems
                </TableCell>
                <TableCell className={classes?.tableHeadCell}>
                  Positive Findings
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {REVIEW_SYSTEMS_DATA?.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes?.tableCell}
                  >
                    {row?.system}
                  </TableCell>
                  <TableCell className={classes?.tableCell}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      flexWrap={'wrap'}
                      gap={3}
                    >
                      {row?.findings?.map((finding, index) => (
                        <Box
                          key={index}
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                        >
                          {finding?.label}
                          <Checkbox
                            size='small'
                            className={classes?.checkbox}
                            name={finding?.value}
                            checked={
                              form?.values?.reviewOfSystems?.[row?.system]?.[
                                finding?.value
                              ]
                            }
                            onChange={(e) =>
                              handleCheckboxChange(e, row?.system)
                            }
                          />
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default ReviewDetails
