import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
  useTheme,
} from '@mui/material'
import React from 'react'
import useCustomStyles from '../../../../../utils/hooks/CustomStylesHook'
import {
  ARRIVAL_MODE_OPTIONS,
  CONSCIOUSNESS_OPTIONS,
  DECISION_OPTIONS,
} from './Constants'

const styles = () => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 40,
  },

  generalFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    rowGap: 25,
    width: '100%',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      // fontWeight: 600,
    },
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
    flex: '0 1 23%',
  },
  formFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 30,
  },
  formSubHeading: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#183f7c',
  },
})

const GeneralDetails = ({ form }: any) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  return (
    <div className={classes?.formContainer}>
      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>General</div>
        <div className={classes?.generalFormContainer}>
          <div className={classes?.formField}>
            <label>Arrival Mode</label>
            <TextField
              size='small'
              select
              name='arrivalMode'
              placeholder='Arrival Mode'
              value={form?.values?.arrivalMode}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.arrivalMode && Boolean(form?.errors?.arrivalMode)
              }
              helperText={
                form?.touched?.arrivalMode && form?.errors?.arrivalMode
              }
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {ARRIVAL_MODE_OPTIONS?.map(
                (item: { label: string; value: string }, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>

          <div className={classes?.formField}>
            <label>Arrival Notification</label>
            <TextField
              size='small'
              select
              name='arrivalNotification'
              placeholder='Arrival Notification'
              value={form?.values?.arrivalNotification}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.arrivalNotification &&
                Boolean(form?.errors?.arrivalNotification)
              }
              helperText={
                form?.touched?.arrivalNotification &&
                form?.errors?.arrivalNotification
              }
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {DECISION_OPTIONS?.map(
                (item: { label: string; value: string }, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>
          {form?.values?.arrivalNotification === 'yes' && (
            <div className={classes?.formField}>
              <label>Hospital Name</label>
              <TextField
                size='small'
                name='hospitalName'
                placeholder='Hospital Name'
                value={form?.values?.hospitalName}
                onChange={form?.handleChange}
                onBlur={form?.handleBlur}
                error={
                  form?.touched?.hospitalName &&
                  Boolean(form?.errors?.hospitalName)
                }
                helperText={
                  form?.touched?.hospitalName && form?.errors?.hospitalName
                }
              />
            </div>
          )}
          <div className={classes?.formField}>
            <label>Level Of Consciousness</label>
            <TextField
              size='small'
              select
              name='levelOfConsciousness'
              placeholder='Level Of Consciousness'
              value={form?.values?.levelOfConsciousness}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.levelOfConsciousness &&
                Boolean(form?.errors?.levelOfConsciousness)
              }
              helperText={
                form?.touched?.levelOfConsciousness &&
                form?.errors?.levelOfConsciousness
              }
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {CONSCIOUSNESS_OPTIONS?.map(
                (item: { label: string; value: string }, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>
          <div className={classes?.formField}>
            <label>Pregnant</label>
            <TextField
              size='small'
              select
              name='pregnant'
              placeholder='Pregnant'
              value={form?.values?.pregnant}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={form?.touched?.pregnant && Boolean(form?.errors?.pregnant)}
              helperText={form?.touched?.pregnant && form?.errors?.pregnant}
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {DECISION_OPTIONS?.map(
                (item: { label: string; value: string }, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>
          <div className={classes?.formField}>
            <label>Allergies</label>
            <TextField
              size='small'
              name='allergies'
              placeholder='Allergies'
              value={form?.values?.allergies}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.allergies && Boolean(form?.errors?.allergies)
              }
              helperText={form?.touched?.allergies && form?.errors?.allergies}
            />
          </div>

          <FormControlLabel
            labelPlacement='top'
            label='MLC'
            control={
              <Checkbox
                size='small'
                checked={form?.values?.mlc}
                onChange={form?.handleChange}
                name='mlc'
              />
            }
          />
        </div>
      </div>

      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>Bought By</div>
        <div className={classes?.generalFormContainer}>
          <div className={classes?.formField}>
            <label>Name</label>
            <TextField
              size='small'
              name='broughtBy.name'
              placeholder='Name'
              value={form?.values?.broughtBy?.name}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.broughtBy?.name &&
                Boolean(form?.errors?.broughtBy?.name)
              }
              helperText={
                form?.touched?.broughtBy?.name && form?.errors?.broughtBy?.name
              }
            />
          </div>
          <div className={classes?.formField}>
            <label>Relationship</label>
            <TextField
              size='small'
              name='broughtBy.relationship'
              placeholder='Relationship'
              value={form?.values?.broughtBy?.relationship}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.broughtBy?.relationship &&
                Boolean(form?.errors?.broughtBy?.relationship)
              }
              helperText={
                form?.touched?.broughtBy?.relationship &&
                form?.errors?.broughtBy?.relationship
              }
            />
          </div>
          <div className={classes?.formField}>
            <label>Phone Number</label>
            <TextField
              size='small'
              name='broughtBy.phoneNumber'
              placeholder='phoneNumber'
              value={form?.values?.broughtBy?.phoneNumber}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.broughtBy?.phoneNumber &&
                Boolean(form?.errors?.broughtBy?.phoneNumber)
              }
              helperText={
                form?.touched?.broughtBy?.phoneNumber &&
                form?.errors?.broughtBy?.phoneNumber
              }
            />
          </div>
        </div>
      </div>
      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>Vitals</div>
        <div className={classes?.generalFormContainer}>
          <div className={classes?.formField}>
            <label>Pulse</label>
            <TextField
              size='small'
              name='vitals.pulse'
              placeholder='Pulse'
              value={form?.values?.vitals?.pulse}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.vitals?.pulse &&
                Boolean(form?.errors?.vitals?.pulse)
              }
              helperText={
                form?.touched?.vitals?.pulse && form?.errors?.vitals?.pulse
              }
            />
          </div>
          <div className={classes?.formField}>
            <label>SPO2</label>
            <TextField
              size='small'
              name='vitals.spo2'
              placeholder='SPO2'
              value={form?.values?.vitals?.spo2}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.vitals?.spo2 &&
                Boolean(form?.errors?.vitals?.spo2)
              }
              helperText={
                form?.touched?.vitals?.spo2 && form?.errors?.vitals?.spo2
              }
            />
          </div>
          <div className={classes?.formField}>
            <label>BP</label>
            <TextField
              size='small'
              name='vitals.bp'
              placeholder='BP'
              value={form?.values?.vitals?.bp}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.vitals?.bp && Boolean(form?.errors?.vitals?.bp)
              }
              helperText={form?.touched?.vitals?.bp && form?.errors?.vitals?.bp}
            />
          </div>
          <div className={classes?.formField}>
            <label>RR</label>
            <TextField
              size='small'
              name='vitals.rr'
              placeholder='RR'
              value={form?.values?.vitals?.rr}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.vitals?.rr && Boolean(form?.errors?.vitals?.rr)
              }
              helperText={form?.touched?.vitals?.rr && form?.errors?.vitals?.rr}
            />
          </div>
          <div className={classes?.formField}>
            <label>GRBS</label>
            <TextField
              size='small'
              name='vitals.grbs'
              placeholder='GRBS'
              value={form?.values?.vitals?.grbs}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.vitals?.grbs &&
                Boolean(form?.errors?.vitals?.grbs)
              }
              helperText={
                form?.touched?.vitals?.grbs && form?.errors?.vitals?.grbs
              }
            />
          </div>
          <div className={classes?.formField}>
            <label>Temperature</label>
            <TextField
              size='small'
              name='vitals.temperature'
              placeholder='Temperature'
              value={form?.values?.vitals?.temperature}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.vitals?.temperature &&
                Boolean(form?.errors?.vitals?.temperature)
              }
              helperText={
                form?.touched?.vitals?.temperature &&
                form?.errors?.vitals?.temperature
              }
            />
          </div>
          <div className={classes?.formField}>
            <label>GCS</label>
            <TextField
              size='small'
              name='vitals.gcs'
              placeholder='GCS'
              value={form?.values?.vitals?.gcs}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.vitals?.gcs && Boolean(form?.errors?.vitals?.gcs)
              }
              helperText={
                form?.touched?.vitals?.gcs && form?.errors?.vitals?.gcs
              }
            />
          </div>
          <div className={classes?.formField}>
            <label>Pain Score</label>
            <TextField
              size='small'
              select
              name='vitals.painScore'
              placeholder='Pain Score'
              value={form?.values?.vitals?.painScore}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.vitals?.painScore &&
                Boolean(form?.errors?.vitals?.painScore)
              }
              helperText={
                form?.touched?.vitals?.painScore &&
                form?.errors?.vitals?.painScore
              }
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {Array.from({ length: 10 }, (_, index) => index + 1)?.map(
                (item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>
        </div>
      </div>
      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>Comorbidities</div>
        <div className={classes?.generalFormContainer}>
          <FormGroup row>
            {Object.keys(form?.values.comorbidities)?.map((key) => (
              <FormControlLabel
                labelPlacement='top'
                control={
                  <Checkbox
                    size='small'
                    checked={form?.values.comorbidities[key]}
                    onChange={(event) =>
                      form?.setFieldValue(
                        `comorbidities.${key}`,
                        event.target.checked
                      )
                    }
                    name={`comorbidities.${key}`}
                  />
                }
                label={key.toUpperCase()}
                key={key}
              />
            ))}
          </FormGroup>
        </div>
      </div>
    </div>
  )
}

export default GeneralDetails
