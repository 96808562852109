import React, { useEffect, useState } from 'react'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID } from '../../utils/constants'
import { Package } from '../lms/models'
import { getTestNames, testCount } from './utils'
import Tick from '../../assets/images/check.png'
import { LabTest, Panel } from './models'

type Props = {
  id: number
  status: string
  onSave: (
    id: number,
    price: number,
    panels: Panel[],
    labTests: LabTest[]
  ) => void
}

const PackageTests = ({ id, status }: Props) => {
  const [packages, setPackages] = useState<Package[]>([])
  const [selectedId, setSelectedId] = useState(id)
  const [tests, setTests] = useState<string[]>([])

  useEffect(() => {
    const intent = 'getAllPackagesApi'
    startSxpProxy(LABS_PROJECT_ID, intent, {})
      .then((data: any) => {
        setPackages(data.data?.packages ?? [])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (id > 0) {
      const pkg = packages.find((pk) => pk.id === id)
      if (pkg) {
        const names = getTestNames(pkg)
        setTests(names)
      }
    }
  }, [id, packages])

  const handlePackageClick = (id: number) => {
    if (status && status !== 'ORDERED') {
      return
    }
    setSelectedId(id)
    const pkg = packages.find((pk) => pk.id === id)
    if (pkg) {
      const names = getTestNames(pkg)
      setTests(names)
    }
  }

  return (
    <div className='packages-container'>
      {packages.length > 0 ? (
        <>
          <div className='packages-row'>
            {packages.map((pkg) => (
              <div
                key={pkg.id}
                className={`package-item${
                  selectedId === pkg.id ? ' active' : ''
                }`}
                onClick={() => handlePackageClick(pkg.id)}
              >
                {selectedId === pkg.id && (
                  <img className='package-check' src={Tick} alt='Checked' />
                )}
                <div>
                  <h2>{pkg.name}</h2>
                  <p className='test-count'>{testCount(pkg)} Tests</p>
                </div>
                <div>
                  <p className='package-price'>Price: {pkg.price}</p>
                </div>
              </div>
            ))}
          </div>
          {tests.length > 0 && (
            <div>
              <div className='admin-header'>Tests</div>
              <div className='package-tests'>{tests.join(', ')}</div>
              {/* needed */}
              {/* <button
                className={`packages-btn ${
                  KeycloakService.hasRole([LAB_ORDERS_WRITE])
                    ? ''
                    : 'auth-disabled'
                }`}
                onClick={handleSave}
              >
                Save
              </button> */}
            </div>
          )}
        </>
      ) : (
        <p>No Packages Found</p>
      )}
    </div>
  )
}

export default PackageTests
