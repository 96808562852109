import { Button, IconButton, TextField } from '@mui/material'
import Select from 'react-select'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import KeycloakService from '../../../../utils/keycloakService'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { PACKAGES_WRITE } from '../../../../utils/roles'
import DatePicker from 'react-datepicker'
import { LabOrder, LabTests, NewRow } from '../../../labTests/models'
import { setQueryParam } from '../../../membership/membershipSlice'
import { selectedLmsItemId, setLmsFormMode } from '../../lmsSlice'
import { Package, toastOptions } from '../../models'
import ToastMessage from '../ToastMessage'
import { useSamples } from '../useSamples'
import { PackageItem } from './Packages'
import { forms, PackageErrorMsg } from '../../../administration/constants'

const PackageOrder = () => {
  const { id }: any = useParams()
  const [order, setOrder] = useState<LabOrder>({})
  const [totalItems, setTotalItems] = useState(0)
  const [rows, setRows] = useState<NewRow[]>([])
  const [isLoading, setIsloading] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [testAdded, setTestAdded] = useState(false)
  const [testNameOptions, setTestNameOptions] = useState<
    { label: string; value: string; panels: any }[]
  >([])
  const [newRow, setNewRow] = useState<NewRow>({
    sampleName: null,
    testName: null,
    panel: null,
  })
  const dispatch = useAppDispatch()
  const handleSampleNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      sampleName: selectedOption,
      testName: null,
    }))
    if (!selectedOption?.value) return
    startSxpProxy(LABS_PROJECT_ID, 'getAllTestsBySampleIdApi', {
      sampleId: parseInt(selectedOption?.value),
    })
      .then((response) => {
        const testNames =
          response?.data?.sample?.[0]?.panels?.map((test: any) => ({
            label: test?.name,
            value: String(test?.id),
            panel: {
              ...test,
              sampleId: parseInt(selectedOption?.value),
              sampleName: selectedOption?.label,
            },
          })) || []
        const selectedTestLabels = rows?.map((row) => row?.testName?.label)
        const filteredTestNames = testNames?.filter(
          (test: any) => !selectedTestLabels?.includes(test?.label)
        )
        setTestNameOptions(filteredTestNames)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }
  useEffect(() => {
    if (order?.panels) {
      setTotalItems(order?.panels?.length + rows?.length)
    } else if (newRow?.panel) {
      setTotalItems(rows?.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.panels, rows])

  const executeFunction = () => {
    const state = {}
    startSxpProxy(LABS_PROJECT_ID, 'getAllPanelsApi', state)
      .then((data) => {
        const panelData: any = data?.data?.panel ?? []
        const tests = panelData?.map((test: any) => ({
          label: test?.name,
          value: String(test?.id),
          panel: {
            name: test?.name,
            description: test?.description,
            id: test?.id,
            lab_tests: test?.lab_tests,
            sampleId: parseInt(test?.sample?.value),
            sampleName: test?.sample?.name,
          },
        }))
        const selectedTestLabels = rows?.map((row) => row?.testName?.label)
        const filteredTests = tests?.filter(
          (test: any) => !selectedTestLabels?.includes(test?.label)
        )
        setTestNameOptions(filteredTests)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }

  const handleTestNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      testName: { label: selectedOption?.label, value: selectedOption?.value },
      sampleName: {
        label: selectedOption?.panel?.sampleName,
        value: selectedOption?.panel?.sampleId,
      },
      panel: selectedOption?.panel,
    }))
  }

  const handleSave = () => {
    setHasChanges(false)
    if (!KeycloakService.hasRole([PACKAGES_WRITE])) {
      return
    }
    if (!item.name) {
      toast(<ToastMessage message={PackageErrorMsg?.InvalidName} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    if (!item.price) {
      toast(<ToastMessage message={PackageErrorMsg?.InvalidPrice} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    if (!item.from) {
      toast(<ToastMessage message={PackageErrorMsg?.InvalidDate} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    if (totalItems == 0) {
      toast(<ToastMessage message={PackageErrorMsg?.InvalidTest} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    setIsloading(true)
    const newPanels = rows?.map((row: any) => row?.panel) || []
    const existingPanels = order?.panels || []
    const combinedPanels = [
      ...existingPanels,
      ...(newPanels || []).filter(
        (newPanel: any) =>
          !existingPanels?.some(
            (existingPanel: any) => existingPanel?.id === newPanel?.id
          )
      ),
    ]

    const labTests = order?.lab_tests || []
    const testName = combinedPanels
      ?.map((panel: any) => panel?.name || '')
      ?.filter((description) => description)
      ?.join('\n')
    const data: Partial<Package> = {
      name: item?.name,
      price: parseInt(item?.price),
      active_from: item?.from,
      active_till: item?.till || null,
      panels: combinedPanels,
      lab_tests: labTests,
      updated_by: KeycloakService?.getFullname(),
    }

    if (!packageId) {
      data.created_by = KeycloakService?.getFullname()
    }

    const intent = packageId ? 'updatePackageById' : 'createPackage'
    const state: any = {
      packageName: item?.name,
      test_name: testName,
      list_price: parseInt(item?.price),
      package: data,
      isodoo: createOdoo,
      odooPackageId: item?.odooPackageId,
    }
    if (packageId) {
      state.id = packageId
    }

    startSxpProxy(LABS_PROJECT_ID, intent, state)
      ?.then(() => {
        dispatch(setLmsFormMode('none'))
        toast(<ToastMessage message={PackageErrorMsg?.PckSuccessMsg} />, {
          ...toastOptions,
          type: 'success',
        })
      })
      .catch((err) => {
        console.log(err)
        toast(<ToastMessage message={PackageErrorMsg?.PckErrorMsg} />, {
          ...toastOptions,
          type: 'error',
        })
        setNewRow({
          sampleName: null,
          testName: null,
          panel: null,
        })
      })
      .finally(() => {
        setIsloading(false)
      })
  }

  const handleSaveRow = useCallback(() => {
    if (!newRow.sampleName) {
      toast(<ToastMessage message={PackageErrorMsg?.SelectSample} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    if (!newRow.testName) {
      toast(<ToastMessage message={PackageErrorMsg?.SelectTest} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    setRows((prevRows) => {
      const updatedRows = [...prevRows, newRow]
      setHasChanges(updatedRows?.length > prevRows?.length)
      setTotalItems(updatedRows?.length)
      return updatedRows
    })

    setNewRow({
      sampleName: null,
      testName: null,
      panel: null,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRow])

  const samples = useSamples()
  const sampleOptions = samples?.map((sample) => ({
    label: sample?.name,
    value: sample?.id?.toString(),
  }))

  const handleDeleteRow = (index: any) => {
    setHasChanges(true)
    setTotalItems(rows?.length - 1)
    setRows((prevRows) => prevRows?.filter((_, i) => i !== index))
  }
  const handleDeletePanelRow = (index: number) => {
    setHasChanges(true)
    setTotalItems(rows?.length - 1)
    setOrder((prevOrder) => ({
      ...prevOrder,
      panels: prevOrder?.panels
        ? prevOrder?.panels?.filter((_, i) => i !== index)
        : [],
    }))
  }

  const VisitTableRef = useRef<HTMLDivElement | null>(null)

  const renderParameters = (parameters: LabTests[]) => {
    return (
      <div>
        {parameters?.map((item: LabTests) => {
          return <li key={item?.id}>{item?.name}</li>
        })}
      </div>
    )
  }

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 90}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  useEffect(() => {
    executeFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testAdded])

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const packageId = useAppSelector(selectedLmsItemId)
  const [item, setItem] = useState<PackageItem>({})
  const [createOdoo, setcreateOdoo] = useState(true)

  const isEditable = !item?.odooPackageId
  useEffect(() => {
    if (packageId) {
      const intent = 'getPackageById'
      const state = { id: packageId }
      startSxpProxy(LABS_PROJECT_ID, intent, state)
        ?.then((data) => {
          const details = data?.data?.packages_by_pk
          const pkg: PackageItem = {
            name: details?.name,
            price: details?.price,
            from: details?.active_from?.slice(0, 10),
            till: details?.active_till?.slice(0, 10),
            odooPackageId: details?.package_id,
            id: details?.id,
          }
          setItem(pkg)
          setOrder({ panels: details?.panels, lab_tests: details?.lab_tests })
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId])

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setItem({
      ...item,
      [name]: value,
    })
  }

  const handleDateChange = (name: string, date: Date | null) => {
    setItem({
      ...item,
      [name]: date ? date.toISOString().split('T')[0] : '',
    })
  }
  const handleCheckboxChange = (event: any) => {
    if (isEditable) {
      setcreateOdoo((prevChecked) => !prevChecked)
    }
  }
  const convertToIST = (date: any) => {
    const istOffset = 5.5 * 60
    return new Date(date?.getTime() + istOffset * 60 * 1000)
  }
  return (
    <div className='orders-container'>
      <div className='createlaborder-table'>
        <div className='laborder-sub-btn'>
          <label htmlFor='hasInventory'>Odoo:</label>
          <input
            type='checkbox'
            name='isodoo'
            className='isodoocheckbox'
            onChange={handleCheckboxChange}
            checked={
              createOdoo === true &&
              (!item?.id || (item?.id && !!item?.odooPackageId))
            }
          />
          <Button
            onClick={handleSave}
            size='small'
            variant='contained'
            disabled={isLoading}
          >
            {isLoading ? forms?.Submitting : forms?.submit}
          </Button>
        </div>
        <table className='data-table admin-table'>
          <thead className='sticky'>
            <tr>
              <th className='th-sample'>Package Name</th>
              <th className='th-sample'>Price</th>
              <th className='th-sample'>Active From</th>
              <th className='th-sample'>Active Till</th>
              <th className='th-sample'>Sample Name</th>
              <th className='th-test'>Test Name</th>
              <th className='th-parameters'>Parameters</th>
              <th className='th-action'>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TextField
                  type='text'
                  placeholder='Enter Name'
                  name='name'
                  value={item?.name || ''}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <TextField
                  type='number'
                  placeholder='Enter Price'
                  name='price'
                  inputProps={{ min: '0' }}
                  value={item?.price || ''}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <DatePicker
                  className='datePickerUI'
                  placeholderText='dd-mm-yyyy'
                  selected={
                    item?.from
                      ? convertToIST(new Date(item?.from))
                      : selectedDate
                  }
                  onChange={(date) =>
                    handleDateChange('from', convertToIST(date))
                  }
                  dateFormat='dd-MM-yyyy'
                />
              </td>
              <td>
                <DatePicker
                  className='datePickerUI'
                  placeholderText='dd-mm-yyyy'
                  selected={
                    item?.till ? convertToIST(new Date(item?.till)) : null
                  }
                  onChange={(date) =>
                    handleDateChange('till', convertToIST(date))
                  }
                  minDate={
                    item?.from ? convertToIST(new Date(item?.from)) : null
                  }
                  dateFormat='dd-MM-yyyy'
                />
              </td>
              <td>
                <Select
                  options={sampleOptions?.map((option) => ({
                    ...option,
                    isDisabled: rows?.some(
                      (r) =>
                        r?.sampleName && r?.sampleName?.value === option?.value
                    ),
                  }))}
                  value={newRow?.sampleName}
                  onChange={handleSampleNameChange}
                  placeholder='Select Sample Name'
                />
              </td>
              <td>
                <Select
                  options={testNameOptions?.map((option) => ({
                    ...option,
                    isDisabled: rows?.some(
                      (row) =>
                        row?.testName && row?.testName?.value === option?.value
                    ),
                  }))}
                  value={newRow?.testName}
                  onChange={handleTestNameChange}
                  placeholder='Select Test Name'
                />
              </td>
              <td>{renderParameters(newRow?.panel?.lab_tests || [])}</td>
              <td>
                <button onClick={handleSaveRow}>Add</button>
              </td>
            </tr>
            {order?.panels?.map((panel, index) => (
              <tr key={`saved-panel-${index}`}>
                {index === 0 ? (
                  <>
                    <td>{item?.name}</td>
                    <td>{item?.price}</td>
                    <td>{item?.from}</td>
                    <td>{item?.till}</td>
                  </>
                ) : (
                  <>
                    <td colSpan={4}></td>
                  </>
                )}
                <td>{panel?.sampleName}</td>
                <td>{panel?.name}</td>
                <td>{renderParameters(panel?.lab_tests || [])}</td>
                <td>
                  <IconButton
                    size='small'
                    onClick={() => handleDeletePanelRow(index)}
                  >
                    <DeleteOutlinedIcon fontSize='small' />
                  </IconButton>
                </td>
              </tr>
            ))}

            {rows?.map((row, index) => (
              <tr key={`new-row-${index}`}>
                <td colSpan={4}></td>
                <td>{row?.sampleName?.label}</td>
                <td>{row?.testName?.label}</td>
                <td>{renderParameters(row?.panel?.lab_tests || [])}</td>
                <td>
                  <IconButton
                    size='small'
                    onClick={() => handleDeleteRow(index)}
                  >
                    <DeleteOutlinedIcon fontSize='small' />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PackageOrder
