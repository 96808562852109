import React, { useRef } from 'react'
import './IpdVisitSummary.scss'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import dayjs from 'dayjs'
import { IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

interface DaysProgressStepperProps {
  label: string
  isComplete: boolean
  fill: string
}

export const DayProgress = ({ activeDays }: any) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const radius = 5 // Radius of the circle
  const strokeWidth = 2 // Width of the connecting line
  const circleSpacing = 30 // Space between circles
  // const labelOffset = 20 // Offset for the label above the circle

  return (
    <div className='flex-conatiner-center'>
      <svg height='70' width={days.length * circleSpacing}>
        {/* Draw the connecting line */}
        <line
          x1={radius}
          y1='35'
          x2={days.length * circleSpacing - radius - 15}
          y2='35'
          stroke='grey'
          strokeWidth={strokeWidth}
        />
        {days.map((day, index) => (
          <g key={day}>
            {/* Draw the day label */}
            <text
              x={index * circleSpacing + radius + 4}
              y='15'
              textAnchor='middle'
              fill='black'
              fontSize='10'
            >
              {day}
            </text>
            {/* Draw the circle */}
            <circle
              cx={index * circleSpacing + radius}
              cy='35'
              r={radius}
              fill={activeDays.includes(index) ? '#4682b4' : 'grey'}
            />
          </g>
        ))}
      </svg>
    </div>
  )
}

export const MealProgress = ({ activeMeals }: any) => {
  const meals = ['breakfast', 'lunch', 'dinner']
  const times = ['before', 'after']
  const radius = 5 // Radius of the circle
  const strokeWidth = 2 // Width of the connecting line
  const circleSpacing = 30 // Space between circles for the same meal
  const mealSpacing = 80 // Space between different meals
  // const labelOffset = 20 // Offset for the label above the circle

  const isActive = (meal: any, time: any) => {
    return activeMeals.some(
      (active: any) => active.meal === meal && active.time === time
    )
  }

  const totalWidth =
    meals.length * mealSpacing + (times.length - 1) * circleSpacing
  const lastCircleX =
    (meals.length - 1) * mealSpacing +
    (times.length - 1) * circleSpacing +
    radius

  return (
    <div className='flex-conatiner-center'>
      <svg height='50' width={totalWidth}>
        {/* Draw the connecting line */}
        <line
          x1={radius}
          y1='30'
          x2={lastCircleX}
          y2='30'
          stroke='grey'
          strokeWidth={strokeWidth}
        />
        {meals.map((meal, mealIndex) => (
          <g key={meal}>
            {/* Draw the meal label */}
            <text
              x={mealIndex * mealSpacing + circleSpacing - 8}
              y='15'
              textAnchor='middle'
              fill='black'
              fontSize='10'
            >
              {meal.charAt(0).toUpperCase() + meal.slice(1)}
            </text>
            {times.map((time, timeIndex) => (
              <g key={time}>
                {/* Draw the circle */}
                <circle
                  cx={
                    mealIndex * mealSpacing + timeIndex * circleSpacing + radius
                  }
                  cy='30'
                  r={radius}
                  fill={isActive(meal, time) ? '#4682b4' : 'grey'}
                />
                {/* Draw the time label */}
                {/* <text
                x={mealIndex * mealSpacing + timeIndex * circleSpacing + radius}
                y='70'
                textAnchor='middle'
                fill='black'
                fontSize='10'
              >
                {time.charAt(0).toUpperCase() + time.slice(1)}
              </text> */}
              </g>
            ))}
          </g>
        ))}
      </svg>
    </div>
  )
}

export const EveryFourHourProgress = ({ activeHours }: any) => {
  const hours = ['4AM', '8AM', '12PM', '4PM', '8PM', '12AM']
  const radius = 5 // Radius of the circle
  const strokeWidth = 2 // Width of the connecting line
  const circleSpacing = 35 // Space between circles
  // const labelOffset = 20 // Offset for the label above the circle

  return (
    <div className='flex-container-center'>
      <svg height='50' width={hours.length * circleSpacing}>
        {/* Draw the connecting line */}
        <line
          x1={radius}
          y1='35'
          x2={hours.length * circleSpacing - radius - 15}
          y2='35'
          stroke='grey'
          strokeWidth={strokeWidth}
        />
        {hours.map((hour, index) => (
          <g key={hour}>
            {/* Draw the hour label */}
            <text
              x={index * circleSpacing + radius + 6}
              y='15'
              textAnchor='middle'
              fill='black'
              fontSize='10'
            >
              {hour}
            </text>
            {/* Draw the circle */}
            <circle
              cx={index * circleSpacing + radius + 5}
              cy='35'
              r={radius}
              fill={activeHours.includes(index) ? 'green' : 'grey'}
            />
          </g>
        ))}
      </svg>
    </div>
  )
}

export const EverySixHourProgress = ({ activeHours }: any) => {
  const hours = ['6AM', '12PM', '6PM', '12AM']
  const radius = 5 // Radius of the circle
  const strokeWidth = 2 // Width of the connecting line
  const circleSpacing = 60 // Space between circles
  // const labelOffset = 20 // Offset for the label above the circle

  return (
    <div className='flex-container-center'>
      <svg height='50' width={hours.length * circleSpacing}>
        {/* Draw the connecting line */}
        <line
          x1={radius}
          y1='35'
          x2={hours.length * circleSpacing - radius - 40}
          y2='35'
          stroke='grey'
          strokeWidth={strokeWidth}
        />
        {hours.map((hour, index) => (
          <g key={hour}>
            {/* Draw the hour label */}
            <text
              x={index * circleSpacing + radius + 6}
              y='15'
              textAnchor='middle'
              fill='black'
              fontSize='10'
            >
              {hour}
            </text>
            {/* Draw the circle */}
            <circle
              cx={index * circleSpacing + radius + 5}
              cy='35'
              r={radius}
              fill={activeHours.includes(index) ? 'green' : 'grey'}
            />
          </g>
        ))}
      </svg>
    </div>
  )
}

export const DaysProgressStepper = ({
  daysProgress,
  progressWidth,
}: {
  daysProgress: DaysProgressStepperProps[]
  progressWidth: string
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const handleNext = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 100, behavior: 'smooth' })
    }
  }

  const handleBack = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -100, behavior: 'smooth' })
    }
  }

  return (
    <Box width={progressWidth} display={'flex'} alignItems={'center'}>
      <IconButton onClick={handleBack}>
        <ArrowBackIosIcon fontSize='small' />
      </IconButton>
      <Box
        ref={scrollContainerRef}
        sx={{
          display: 'flex',
          overflowX: 'hidden',
          width: 'calc(100% - 96px)',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Stepper alternativeLabel sx={{ display: 'flex' }}>
          {daysProgress?.map((day) => (
            <Step key={day.label} completed={day.isComplete}>
              <StepLabel
                StepIconProps={{
                  style: {
                    color: day.fill || 'grey',
                    fontSize: '18px',
                    minWidth: '50px',
                  },
                }}
                sx={{
                  '& .MuiStepLabel-alternativeLabel': {
                    marginTop: '2px',
                  },
                }}
              >
                <Box fontSize={'11px'}>{dayjs(day.label).format('DD MMM')}</Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <IconButton onClick={handleNext}>
        <ArrowForwardIosIcon fontSize='small' />
      </IconButton>
    </Box>
  )
}
