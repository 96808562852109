import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HealthcareService, Slot } from 'fhir/r4'
import { Doctor, formatDoctors } from '../scheduler/schedulerSlice'
import { LocationData } from '../location/locationSlice'
import { RootState } from '../../app/store'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID } from '../../utils/constants'

export type ServiceData = {
  resource: HealthcareService
}

export type SlotDuration = 1 | 2 | 5 | 10 | 15 | 20 | 30 | 60

export type SlotConfig = {
  id: string
  start: string
  end: string
  duration: SlotDuration
}

export type FhirSlot = {
  resource: Slot
}

export type SlotSchedule = {
  id: string
  date: string
  slots: Slot[]
  start: string
  end: string
}

type SlotSliceData = {
  status: 'loading' | 'success' | 'error'
  doctors: Doctor[]
  selectedDoctor: string
  locations: LocationData[]
  selectedLocation: string
  services: ServiceData[]
  selectedService: string
  startDate: string
  endDate?: string
  configs: SlotConfig[]
  configError: string
  availableSlots: FhirSlot[]
  createStatus: '' | 'success' | 'error'
}

const initialState: SlotSliceData = {
  status: 'loading',
  doctors: [],
  selectedDoctor: '',
  locations: [],
  selectedLocation: '',
  services: [],
  selectedService: '',
  startDate: new Date().toISOString().slice(0, 10),
  endDate: undefined,
  configs: [],
  configError: '',
  availableSlots: [],
  createStatus: '',
}

export const fetchSlotsDoctorsAsync = createAsyncThunk(
  'slots/fetchDoctors',
  async () => {
    const intent = 'getDoctorsAPI'
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent)
    console.log('fetchSlotsDoctorsAsync', response?.data)
    return response.data
  }
)

export const fetchSlotsLocationsAsync = createAsyncThunk(
  'slots/fetchLocations',
  async () => {
    const intent = 'getLocationsAPI'
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent)
    return response.data
  }
)

export const fetchSlotsServicesAsync = createAsyncThunk(
  'slots/fetchServices',
  async () => {
    const intent = 'getServicesAPI'
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent)
    return response.data
  }
)

export const fetchSlotsAsync = createAsyncThunk(
  'slots/fetchSlots',
  async (_arg, { getState }) => {
    const intent = 'getDoctorSlotsByDates'
    const start = selectSlotsStartDate(getState() as RootState)
    const end = selectSlotsEndDate(getState() as RootState)
    const state = {
      serviceId: selectSlotsSelectedService(getState() as RootState),
      locationId: selectSlotsSelectedLocation(getState() as RootState),
      doctorId: selectSlotsSelectedDoctor(getState() as RootState),
      startDate: start.slice(0, 10),
      endDate: end ? end.slice(0, 10) : start.slice(0, 10),
    }
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
    return response.data
  }
)

export const createSlotsAsync = createAsyncThunk(
  'slots/createSlots',
  async (_arg, { getState, rejectWithValue }) => {
    const intent = 'uploadSlotsAPI'
    const start = selectSlotsStartDate(getState() as RootState)
    const end = selectSlotsEndDate(getState() as RootState)
    const serviceId = selectSlotsSelectedService(getState() as RootState)
    const locationId = selectSlotsSelectedLocation(getState() as RootState)
    const state = {
      doctorId: selectSlotsSelectedDoctor(getState() as RootState),
      locationId: locationId,
      serviceId: serviceId,
      startDate: start,
      endDate: end ? end : start,
      slots: selectSlotsConfigs(getState() as RootState),
    }
    try {
      const response = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue({
        data: err?.response?.data,
        status: err.response?.status,
      })
    }
  }
)

const slotsSlice = createSlice({
  name: 'slots',
  initialState,
  reducers: {
    resetSlotsStatus: (state) => {
      state.status = 'loading'
      state.configs = []
      state.availableSlots = []
      state.configError = ''
    },
    setSelectedSlotsDoctor: (state, action: PayloadAction<string>) => {
      state.selectedDoctor = action.payload
    },
    setSelectedSlotsLocation: (state, action: PayloadAction<string>) => {
      state.selectedLocation = action.payload
    },
    setSelectedSlotsService: (state, action: PayloadAction<string>) => {
      state.selectedService = action.payload
    },
    setSlotsStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },
    setSlotsEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },
    addSlotConfig: (state, action: PayloadAction<SlotConfig>) => {
      state.configs = [...state.configs, action.payload]
    },
    deleteSlotConfig: (state, action: PayloadAction<string>) => {
      state.configs = state.configs.filter((c) => c.id !== action.payload)
      state.configError = ''
    },
    clearSlotConfigs: (state) => {
      state.configs = []
    },
    setConfigError: (state, action: PayloadAction<string>) => {
      state.configError = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlotsDoctorsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSlotsDoctorsAsync.fulfilled, (state, action) => {
        state.status = 'success'
        state.doctors = action.payload.entry
          ? formatDoctors(action.payload.entry)
          : []
      })
      .addCase(fetchSlotsLocationsAsync.fulfilled, (state, action) => {
        state.locations = action.payload.entry ?? []
      })
      .addCase(fetchSlotsServicesAsync.fulfilled, (state, action) => {
        state.services = action.payload.entry ?? []
      })
      .addCase(fetchSlotsAsync.fulfilled, (state, action) => {
        state.availableSlots = action.payload.entry ?? []
      })
      .addCase(createSlotsAsync.fulfilled, (state) => {
        state.createStatus = 'success'
      })
      .addCase(createSlotsAsync.rejected, (state) => {
        state.createStatus = 'error'
      })
  },
})

export const {
  resetSlotsStatus,
  setSelectedSlotsDoctor,
  setSelectedSlotsLocation,
  setSelectedSlotsService,
  setSlotsStartDate,
  setSlotsEndDate,
  addSlotConfig,
  deleteSlotConfig,
  clearSlotConfigs,
  setConfigError,
} = slotsSlice.actions

export const selectSlotsStatus = (state: RootState) => state.slots.status
export const selectSlotsDoctors = (state: RootState) => state.slots.doctors
export const selectSlotsLocations = (state: RootState) => state.slots.locations
export const selectSlotsServices = (state: RootState) => state.slots.services
export const selectSlotsStartDate = (state: RootState) => state.slots.startDate
export const selectSlotsEndDate = (state: RootState) => state.slots.endDate
export const selectSlotsConfigs = (state: RootState) => state.slots.configs
export const selectSlotsConfigError = (state: RootState) =>
  state.slots.configError
export const selectAvailableSlots = (state: RootState) =>
  state.slots.availableSlots
export const selectSlotsSelectedDoctor = (state: RootState) =>
  state.slots.selectedDoctor
export const selectSlotsSelectedLocation = (state: RootState) =>
  state.slots.selectedLocation
export const selectSlotsSelectedService = (state: RootState) =>
  state.slots.selectedService

export default slotsSlice.reducer
