import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { allocateApi } from '../modals'

export const fetchAll = createAsyncThunk(
  'appointments/fetchAll',
  async (data: { id: string }) => {
    const intent = allocateApi?.getAllSubEncounters
    const state = { patientId: data?.id }

    const response = await startSxpProxy(IPD_PROJECT_ID, intent, state)
    return response.data
  }
)

const appointmentsEncounterSlice = createSlice({
  name: 'appointments',
  initialState: {
    appointments: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAll.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.appointments = action.payload?.entry
      })
      .addCase(fetchAll.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export default appointmentsEncounterSlice.reducer
