import React from 'react'
import { NavLink } from 'react-router-dom'
import KeycloakService from '../../../utils/keycloakService'
import {
  DIAGNOSIS_READ,
  DIAGNOSIS_WRITE,
  LAB_ORDERS_READ,
  LAB_ORDERS_WRITE,
  MEDICATIONS_READ,
  MEDICATIONS_WRITE,
} from '../../../utils/roles'
import '../../opd/opd.scss'

interface MenuProps {
  setSelectedMenu: (menu: string) => void
  setSelectedDate?: any
}

const IpdVisitMenu = (props: MenuProps) => {
  const { setSelectedMenu, setSelectedDate } = props
  return (
    <div className='opd-menu'>
      <ul>
        {KeycloakService.hasRole([LAB_ORDERS_READ, LAB_ORDERS_WRITE]) && (
          <li>
            <NavLink
              to='vitals'
              onClick={() => {
                setSelectedMenu('vitals')
                setSelectedDate()
              }}
            >
              Vitals
            </NavLink>
          </li>
        )}
        {KeycloakService.hasRole([DIAGNOSIS_READ, DIAGNOSIS_WRITE]) && (
          <li>
            <NavLink
              to='diagnosisIpd'
              onClick={() => setSelectedMenu('diagnosisIpd')}
            >
              Diagnosis
            </NavLink>
          </li>
        )}
        {KeycloakService.hasRole([MEDICATIONS_READ, MEDICATIONS_WRITE]) && (
          <li>
            <NavLink
              to='medications'
              onClick={() => setSelectedMenu('medications')}
            >
              Medications
            </NavLink>
          </li>
        )}
        {KeycloakService.hasRole([MEDICATIONS_READ, MEDICATIONS_WRITE]) && (
          <li>
            <NavLink
              to='treatmentPlan'
              onClick={() => setSelectedMenu('treatmentPlan')}
            >
              Treatment Plan
            </NavLink>
          </li>
        )}
        {KeycloakService.hasRole([LAB_ORDERS_READ, LAB_ORDERS_WRITE]) && (
          <li>
            <NavLink
              to='lab-orders'
              onClick={() => setSelectedMenu('lab-orders')}
            >
              Lab Orders
            </NavLink>
          </li>
        )}
        {KeycloakService.hasRole([LAB_ORDERS_READ, LAB_ORDERS_WRITE]) && (
          <li>
            <NavLink
              to='radiology-orders'
              onClick={() => setSelectedMenu('radiology-orders')}
            >
              Radiology Orders
            </NavLink>
          </li>
        )}
        {KeycloakService.hasRole([LAB_ORDERS_READ, LAB_ORDERS_WRITE]) && (
          <li>
            <NavLink to='icuChart' onClick={() => setSelectedMenu('icuChart')}>
              ICU Chart
            </NavLink>
          </li>
        )}
        {KeycloakService.hasRole([DIAGNOSIS_READ, DIAGNOSIS_WRITE]) && (
          <li>
            <NavLink
              to='invasiveLineIpd'
              onClick={() => setSelectedMenu('invasiveLineIpd')}
            >
              Invasive Lines
            </NavLink>
          </li>
        )}
        {/* {KeycloakService.hasRole([LAB_ORDERS_READ, LAB_ORDERS_WRITE]) && (
          <li>
            <NavLink
              to='progressNote'
              onClick={() => setSelectedMenu('progressBar')}
            >
              Progress Notes
            </NavLink>
          </li>
        )} */}
      </ul>
    </div>
  )
}

export default IpdVisitMenu
