import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { DashboardTab, FormMode } from './models'

export type LmsSliceData = {
  item: string
  itemId: string
  formMode: FormMode
  activeTab: DashboardTab
  searchParam: string
  dateFilter: string
  adminSearch: string
  locationSearch: string
  startDate: string
  endDate: string
  orderId: string
  preferredCommunication: string
  patientPhone: string
  patientName: string
  selectedValue: string | null
  dmsManualRetry: number
}

const initialState: LmsSliceData = {
  item: '',
  itemId: '',
  formMode: 'none',
  activeTab: 'collection',
  searchParam: '',
  dateFilter: new Date().toISOString().slice(0, 10),
  adminSearch: '',
  locationSearch: '',
  startDate: '',
  endDate: '',
  orderId: '',
  preferredCommunication: '',
  patientPhone: '',
  patientName: '',
  selectedValue: null,
  dmsManualRetry: 0,
}

const lmsSlice = createSlice({
  name: 'lms',
  initialState,
  reducers: {
    setLmsItem: (state, action: PayloadAction<string>) => {
      state.item = action.payload
    },
    setLmsStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },
    setLmsEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },
    setLmsItemId: (state, action: PayloadAction<string>) => {
      state.itemId = action.payload
    },
    setLmsFormMode: (state, action: PayloadAction<FormMode>) => {
      state.formMode = action.payload
    },
    setLmsActiveTab: (state, action: PayloadAction<DashboardTab>) => {
      state.activeTab = action.payload
    },
    setLmsSearchParam: (state, action: PayloadAction<string>) => {
      state.searchParam = action.payload
    },
    setLmsDateFilter: (state, action: PayloadAction<string>) => {
      state.dateFilter = action.payload
    },
    setLmsAdminSearch: (state, action: PayloadAction<string>) => {
      state.adminSearch = action.payload
    },
    setLmsLocationSearch: (state, action: PayloadAction<string>) => {
      state.locationSearch = action.payload
    },
    setLmsOrderId: (state, action: PayloadAction<string>) => {
      state.orderId = action.payload
    },
    setLmsPreferredCommunication: (state, action: PayloadAction<string>) => {
      state.preferredCommunication = action.payload
    },
    setLmsPatientPhone: (state, action: PayloadAction<string>) => {
      state.patientPhone = action.payload
    },
    setLmsPatientName: (state, action: PayloadAction<string>) => {
      state.patientName = action.payload
    },
    setSelectedValue: (state, action: PayloadAction<string | null>) => {
      state.selectedValue = action.payload
    },
    setDmsManualRetry: (state) => {
      state.dmsManualRetry += 1
    },
  },
})

export const {
  setLmsItem,
  setLmsItemId,
  setLmsFormMode,
  setLmsActiveTab,
  setLmsSearchParam,
  setLmsDateFilter,
  setLmsAdminSearch,
  setLmsLocationSearch,
  setLmsEndDate,
  setLmsStartDate,
  setLmsOrderId,
  setLmsPreferredCommunication,
  setLmsPatientPhone,
  setLmsPatientName,
  setSelectedValue,
  setDmsManualRetry,
} = lmsSlice.actions

export const selectedLmsItem = (state: RootState) => state.lms.item
export const selectedLmsStartDate = (state: RootState) => state.lms.startDate
export const selectedLmsEndDate = (state: RootState) => state.lms.endDate
export const selectedLmsItemId = (state: RootState) => state.lms.itemId
export const selectedLmsFormMode = (state: RootState) => state.lms.formMode
export const selectedLmsActiveTab = (state: RootState) => state.lms.activeTab
export const selectedLmsSearchParam = (state: RootState) =>
  state.lms.searchParam
export const selectedLmsDateFilter = (state: RootState) => state.lms.dateFilter
export const selectedLmsAdminSearch = (state: RootState) =>
  state.lms.adminSearch
export const selectedLmsLocationSearch = (state: RootState) =>
  state.lms.locationSearch
export const selectedLmsOrderId = (state: RootState) => state.lms.orderId
export const selectedLmsPreferredCommunication = (state: RootState) =>
  state.lms.preferredCommunication
export const selectedLmsPatientPhone = (state: RootState) =>
  state.lms.patientPhone
export const selectedLmsPatientName = (state: RootState) =>
  state.lms.patientName
export const selectedLmsSelectedValue = (state: RootState) =>
  state.lms.selectedValue
export const selectedDmsManualRetry = (state: RootState) =>
  state.lms.dmsManualRetry

export default lmsSlice.reducer
