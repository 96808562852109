/* eslint-disable camelcase */
import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { LabResult, LmsValues, RefLabResult, toastOptions } from '../../models'
import TestGroup from '../TestGroup'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../../../utils/constants'
import { useOrganizations } from '../useOrganizations'
import {
  encodeNewLineText,
  matchPatientName,
  matchPatientUhid,
  sortResultsBySequence,
} from '../../utils'
import KeycloakService from '../../../../utils/keycloakService'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage'
import { useNavigate } from 'react-router-dom'
import { OrderEvent, OrderEventTest } from '../../../labTests/models'
import { useAppSelector } from '../../../../app/hooks'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../../../location/locationSlice'
import LoadingButton from '../../../../components/LoadingButton'
import { Labintent } from '../../constants'
import { completed, rejected } from '../../../administration/constants'

const Referred = () => {
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const [active, setActive] = useState(0)
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<RefLabResult[]>([])
  const [searchParam, setSearchParam] = useState('')
  const organizations = useOrganizations()
  const navigate = useNavigate()
  const [apiLoading, setApiLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const intent = Labintent?.getReferredLabResults
    const state = {
      source: location?.resource?.name ?? '',
    }
    startSxpProxy(LABS_PROJECT_ID, intent, state)
      .then((data) => {
        const results: RefLabResult[] = data?.data?.lab_result ?? []
        const patIds = results?.map((r) => r?.patient_id)?.join(',')
        if (patIds?.length) {
          startSxpProxy(OPD_PROJECT_ID, Labintent?.getPatientsByIdsApi, {
            ids: patIds,
          })?.then((data) => {
            const entry = data?.data?.entry
            if (entry?.length) {
              const patientObj = entry?.reduce(
                (acc: any, cur: any) => ({
                  ...acc,
                  [cur.resource.id]: cur.resource,
                }),
                {}
              )
              const mapped: RefLabResult[] = results?.map((node) => {
                const pat = node?.patient_id
                  ? patientObj?.[node?.patient_id]
                  : null
                return {
                  ...node,
                  patient: pat,
                }
              })
              setResults(mapped)
            } else {
              setResults(results)
            }
            setLoading(false)
          })
        } else {
          setResults(results)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }, [location])

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchParam(target.value)
  }

  const handleValueChange = (val: string, index: number) => {
    setResults((r) =>
      r?.map((rs) => {
        if (rs?.id === index) {
          rs.value = val
        }
        return rs
      })
    )
  }

  const handleValuesChange = (values: LmsValues, index: number) => {
    setResults((r) =>
      r?.map((rs) => {
        if (rs?.id === index) {
          rs.values = values
        }
        return rs
      })
    )
  }

  const handleExtraValueChange = (val: string, index: number) => {
    setResults((r) =>
      r?.map((rs) => {
        if (rs?.id === index) {
          rs.extra_value = val
        }
        return rs
      })
    )
  }

  const handleNotesChange = (val: string, index: number) => {
    setResults((r) =>
      r?.map((rs) => {
        if (rs?.id === index) {
          rs.observation = val
        }
        return rs
      })
    )
  }

  const handleEnter = (testId: number) => {
    const found = ids?.findIndex((i) => i === testId)
    if (found !== -1) {
      setActive(found + 1)
    } else {
      setActive((id) => id + 1)
    }
  }

  const handleDocUrlChange = (val: string, index: number, pId?: string) => {
    setResults((r) =>
      r?.map((rs) => {
        if (
          index === rs?.lab_test?.panel?.id &&
          pId === rs?.patient_id &&
          rs?.test_status !== rejected
        ) {
          rs.document_url = val
        }
        return rs
      })
    )
  }

  const handleSave = (items: RefLabResult[]) => {
    const orderId = items?.[0].lab_order?.id
    setApiLoading(true)
    startSxpProxy(LABS_PROJECT_ID, Labintent?.getOrderEventsByOrder, {
      orderId,
    })?.then((data) => {
      const events: OrderEvent[] = data?.data?.order_event
      const lastEventTests = events?.[events?.length - 1]?.tests ?? []
      const updated = items?.map((item) => item?.lab_test_id)
      const now = new Date().toISOString()
      const user = KeycloakService.getUsername()
      const currentTests: OrderEventTest[] = lastEventTests?.map((lt) => {
        if (updated?.includes(lt?.test_id)) {
          return {
            test_id: lt?.test_id,
            status: 'ENTERED_REFERRED',
            updatedBy: user,
            updatedOn: now,
          }
        }
        return lt
      })
      const stateObj: Partial<OrderEvent> = {
        updated_by: user,
        order_id: '' + orderId,
        type: 'REFERRED_RESULTS_ENTERED',
        tests: currentTests,
      }
      startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
        event: stateObj,
      }).then(() => {
        const refId = KeycloakService?.getSub()
        const refName = KeycloakService?.getFullname()
        const mapped = items?.map((rs) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { lab_order, lab_test, organization, patient, ...rest } = rs
          const updatedResult: Partial<LabResult> = {
            ...rest,
            value: encodeNewLineText(rs?.value),
            values: rs?.values,
            extra_value: rs?.extra_value,
            observation: encodeNewLineText(rs?.observation),
            document_url: rs?.document_url,
            result_entered_on: now,
            result_entered_by: refName,
            result_entered_by_id: refId,
          }
          return { where: { id: { _eq: rs.id } }, _set: updatedResult }
        })
        const intent = 'updateLabResults'
        const state = {
          results: mapped,
        }
        startSxpProxy(LABS_PROJECT_ID, intent, state)
          ?.then(() => {
            toast(<ToastMessage message='Lab Results Saved' />, {
              ...toastOptions,
              type: 'success',
            })
            navigate(`/lms/search/results/${orderId}`)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setApiLoading(false)
          })
      })
    })
  }

  const filtered = results.filter(
    (r) =>
      matchPatientName(searchParam, r?.patient?.name?.[0]) ||
      matchPatientUhid(searchParam, r?.patient?.identifier)
  )

  const groupedTests: Record<string, RefLabResult[]> = filtered.reduce(
    (acc: Record<string, RefLabResult[]>, cur) => {
      const panelId = cur?.lab_test?.panel?.id
      const patientId = cur?.patient_id
      const key = `${patientId}${panelId}`
      if (acc[key]) {
        acc[key].push(cur)
      } else {
        acc[key] = [cur]
      }
      return acc
    },
    {}
  )
  const ids = Object?.keys(groupedTests)?.flatMap((ok) => {
    return groupedTests[parseInt(ok)]?.map((gtf) => gtf?.id)
  })
  for (const key in groupedTests) {
    const unsorted = groupedTests[key]
    const seq =
      unsorted?.[0]?.lab_test?.panel?.lab_test_sequence?.sequence ?? []
    groupedTests[key] = sortResultsBySequence(unsorted, seq)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <input
          type='search'
          value={searchParam}
          onChange={handleSearch}
          className='referred-search'
          placeholder='Search by Patient UHID'
        />
        <div>
          <button className='btn btn-primaryBtn'>Search</button>
        </div>
      </div>
      {loading ? (
        <div className='admin-header'>Loading Results</div>
      ) : filtered?.length > 0 ? (
        <div>
          {Object?.keys(groupedTests)?.map((k) => {
            const items = groupedTests[parseInt(k)]
            const patient = items?.[0]?.patient
            return (
              <Fragment key={k}>
                <TestGroup
                  activeId={ids[active]}
                  tests={items}
                  patient={patient}
                  onDocUrlChange={handleDocUrlChange}
                  onValueChange={handleValueChange}
                  onNotesChange={handleNotesChange}
                  onExtraValueChange={handleExtraValueChange}
                  onValuesChange={handleValuesChange}
                  onEnter={handleEnter}
                  isReferred={true}
                  disabled={items?.[0]?.lab_order?.status === completed}
                  organizations={organizations}
                  apiLoading={apiLoading}
                  mode={'referred'}
                />
                {items?.[0]?.document_url &&
                  items?.every(
                    (item) => item?.value || item?.values?.summary
                  ) && (
                    <div className='mt10'>
                      <LoadingButton
                        title='Save'
                        onClick={() => handleSave(items)}
                        loading={apiLoading}
                        compact
                      />
                    </div>
                  )}
              </Fragment>
            )
          })}
        </div>
      ) : (
        <div className='admin-header'>No Results to Display</div>
      )}
    </div>
  )
}

export default Referred
