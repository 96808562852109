import React, { useRef, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import { AdminTableProps, Package, toastOptions } from '../../models'
import LinkButton from './LinkButton'
import { LabTests } from '../../../labTests/models'
import ProvenanceView from '../../../../components/ProvenanceView'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { toast } from 'react-toastify'
import {
  create,
  forms,
  messages,
  odooServerError,
  update,
} from '../../../administration/constants'
import { AdminIntent } from '../../constants'
import ToastMessage from '../ToastMessage'
import KeycloakService from '../../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'

const PackagesTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const [tableData, setTableData] = useState(data)
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())

  const packagesTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = packagesTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window?.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window?.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const toggleRow = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev)
      if (newExpandedRows?.has(rowIndex)) {
        newExpandedRows?.delete(rowIndex)
      } else {
        newExpandedRows?.add(rowIndex)
      }
      return newExpandedRows
    })
  }
  const renderLabTests = (labTests: LabTests[], rowIndex: number) => {
    const initialItemCount = 2
    const isExpanded = expandedRows?.has(rowIndex)

    return (
      <div>
        {labTests
          ?.slice(0, isExpanded ? labTests?.length : initialItemCount)
          ?.map((item: LabTests) => (
            <li key={item?.id}>{item?.name}</li>
          ))}
        {labTests?.length > initialItemCount && (
          <span onClick={() => toggleRow(rowIndex)} className='linkbtns'>
            {isExpanded ? '...View Less' : '...View More'}
          </span>
        )}
      </div>
    )
  }
  useEffect(() => {
    if (Array?.isArray(data) && data?.length > 0) {
      const updatedData = data?.map((pkg) => {
        const activeTill = pkg?.active_till ? new Date(pkg?.active_till) : null
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        if (
          !activeTill ||
          (activeTill >= today && pkg?.status === Status.Active)
        ) {
          return { ...pkg, status: Status.Active }
        }
        if (activeTill < today && pkg?.status === Status.Active) {
          return { ...pkg, status: Status.Inactive }
        }
        return pkg
      })
      setTableData(updatedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const filtered = getSearchResults(tableData, searchParam)

  const handleChangeStatus = (
    id: string,
    status: 'active' | 'inactive',
    odooPackageId: any
  ) => {
    startSxpProxy(LABS_PROJECT_ID, AdminIntent?.InactivePackage, {
      id,
      status,
      odooPackageId,
    })
      ?.then((response) => {
        toast.success(messages?.updatedsuccess, toastOptions)
        setTableData((prevData: any) =>
          prevData?.map((pkg: any) =>
            pkg.id === id ? { ...pkg, status } : pkg
          )
        )
      })
      ?.catch((err) => console.error(messages?.ErrorMessage, err))
  }
  enum Status {
    Active = 'active',
    Inactive = 'inactive',
  }

  const renderStatusSelect = (d: any) => {
    return (
      <select
        className='medication-input w75'
        value={d.status === Status.Active ? Status.Active : Status.Inactive}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          handleChangeStatus(d?.id, e.target.value as Status, d?.package_id)
        }}
      >
        <option value={Status.Active}>{forms?.active}</option>
        <option value={Status.Inactive}>{forms?.inactive}</option>
      </select>
    )
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null)

  const handleOpenDeleteDialog = (pkg: Package) => {
    setSelectedPackage(pkg)
    setDeleteDialogOpen(true)
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setSelectedPackage(null)
  }
  const handleConfirmDelete = () => {
    if (selectedPackage) {
      startSxpProxy(LABS_PROJECT_ID, AdminIntent?.DeletePackageById, {
        id: selectedPackage?.id,
        odooPackageId: selectedPackage?.package_id,
      })
        ?.then((response: any) => {
          if (response?.status === 400) {
            toast?.error(response?.message || messages?.ErrorMessage)
          } else {
            toast?.success(messages?.Deletedsuccessfully)
            setTableData((prevData: any) =>
              prevData?.filter(
                (panel: any) => panel?.id !== selectedPackage?.id
              )
            )
            handleCloseDeleteDialog()
          }
        })
        .catch((error) => {
          const errorMessage = error?.response?.data ?? odooServerError
          toast(<ToastMessage message={errorMessage} />, {
            ...toastOptions,
            type: 'error',
          })
          handleCloseDeleteDialog()
        })
    }
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={packagesTableContainerRef}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th className='table-w-15'>Package Name</th>
            <th className='table-w-20'>Test Name</th>
            <th>Test Count</th>
            <th>Price</th>
            <th className='table-w-15'>Start-End Date</th>
            <th className='table-w-10'>Created</th>
            <th className='table-w-10'>Updated</th>
            <th className='table-w-8'>Status</th>
            <th className='table-w-6'>Delete</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any, index: any) => {
              const allLabTests =
                d?.panels?.flatMap((panel: any) => panel?.lab_tests) || []
              const testCount =
                (d?.lab_tests?.length ?? 0) + allLabTests?.length

              return (
                <tr key={d?.id}>
                  <td>{d?.id}</td>
                  <td>
                    <LinkButton
                      id={d?.id}
                      name={d?.name}
                      onClick={() => onEdit(d?.id)}
                    />
                  </td>
                  <td>{renderLabTests(allLabTests, index)}</td>
                  <td>{testCount}</td>
                  <td>{d?.price}</td>
                  <td>
                    {d?.active_from
                      ? new Date(d?.active_from).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })
                      : 'N/A'}
                    {'  -  '}
                    {d?.active_till
                      ? new Date(d?.active_till).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })
                      : 'N/A'}
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={d?.created_at}
                      name={d?.created_by}
                      mode={create}
                    />
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={d?.updated_at ?? d?.createdOn}
                      name={d?.updated_by ?? d?.createdBy}
                      mode={update}
                    />
                  </td>
                  <td className='text-center'>{renderStatusSelect(d)}</td>
                  <td>
                    <IconButton
                      size='small'
                      onClick={() => handleOpenDeleteDialog(d)}
                      disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
                    >
                      <DeleteOutlined fontSize='small' />
                    </IconButton>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={8} className='text-center'>
                No Packages Configured
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby='delete-dialog-title'
        aria-describedby='delete-dialog-description'
      >
        <DialogTitle id='delete-dialog-title'>Delete Package</DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-dialog-description'>
            Are you sure you want to delete the package &nbsp;
            <strong>{selectedPackage?.name}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color='secondary' autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PackagesTable
