import React, { FC, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Rating, { IconContainerProps } from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import { Typography, Box } from '@mui/material'
import './IpdProgress.scss'

interface PainScaleProps {
  setFieldValue: (field: string, value: any) => void
}

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
  '& .MuiRating-icon': {
    marginRight: '2rem',
  },
}))

const customIcons: {
  [index: string]: {
    icon: React.ReactElement<any>
    label: string
  }
} = {
  1: {
    icon: (
      <SentimentVerySatisfiedIcon color='success' className='painScale-icon' />
    ),
    label: 'No hurt',
  },
  2: {
    icon: (
      <SentimentSatisfiedAltIcon color='success' className='painScale-icon' />
    ),
    label: 'Hurts little bit',
  },
  3: {
    icon: <SentimentSatisfiedIcon color='warning' className='painScale-icon' />,
    label: 'Hurts even more',
  },
  4: {
    icon: (
      <SentimentDissatisfiedIcon color='error' className='painScale-icon' />
    ),
    label: 'Hurts whole lot',
  },
  5: {
    icon: (
      <SentimentVeryDissatisfiedIcon color='error' className='painScale-icon' />
    ),
    label: 'Hurts worst',
  },
}

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

const PainScale: FC<PainScaleProps> = ({ setFieldValue }) => {
  const [selectedPainValue, setSelectedPainValue] = React.useState<
    number | null
  >(null)
  const [hoveredPainValue, setHoveredPainValue] = React.useState<number | null>(
    null
  )

  const handleChange = (event: React.SyntheticEvent, value: number | null) => {
    if (value !== null) {
      setSelectedPainValue(value)
      setFieldValue('others.analgesia.VASscore', value)
    }
  }

  const [displayLabelValue, setDisplayLabelValue] = useState<string>('')
  const [displayLabel, setDisplayLabel] = useState<string>('')

  useEffect(() => {
    setDisplayLabelValue(
      selectedPainValue !== null
        ? customIcons[selectedPainValue]?.label
        : hoveredPainValue !== null
        ? customIcons[hoveredPainValue]?.label
        : ''
    )
  }, [hoveredPainValue, selectedPainValue])

  useEffect(() => {
    setDisplayLabel(
      hoveredPainValue !== null ? customIcons[hoveredPainValue]?.label : ''
    )
  }, [hoveredPainValue])

  return (
    <Box className='painScale-container'>
      <Box className='painScale-innerBox'>
        <Box className='painScale-ratingContainer'>
          <StyledRating
            name='pain-scale-rating'
            value={selectedPainValue}
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons?.[value]?.label}
            onChange={handleChange}
            onChangeActive={(event, newHover) => {
              setHoveredPainValue(newHover)
            }}
            {...(selectedPainValue !== null && (
              <Box sx={{ ml: 2 }}>
                {
                  customIcons?.[
                    hoveredPainValue !== null
                      ? hoveredPainValue
                      : selectedPainValue
                  ]?.label
                }
              </Box>
            ))}
            highlightSelectedOnly
          />
        </Box>

        <Typography variant='h6' className='painScale-label'>
          {displayLabel ? displayLabel : displayLabelValue}
        </Typography>
      </Box>
    </Box>
  )
}

export default PainScale
