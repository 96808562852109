import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PartnerForm from './PartnerForm'
import { startSxpProxy } from '../../utils/api'
import { ADMIN_PROJECT_ID } from '../../utils/constants'
import { IconButton } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { OrganizationIntent, PartnerTable } from '../administration/constants'

const EditOrganization = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [partner, setPartner] = useState({
    type: '',
    name: '',
    phoneNumber: '',
    status: '',
    fhirId: '',
    id: '',
  })
  const getOrganizationById = () => {
    startSxpProxy(ADMIN_PROJECT_ID, OrganizationIntent?.getOrganizationById, {
      id,
    })?.then((data) => {
      const partner = data?.data?.organization_by_pk
      setPartner((prev) => ({
        ...prev,
        id: partner?.id ?? '',
        name: partner?.name ?? '',
        phoneNumber: partner?.mobile_number ?? '',
        status: partner?.status ?? '',
        type: partner?.type ?? '',
        odoo_partner_id: partner?.odoo_partner_id ?? '',
      }))
    })
  }
  useEffect(() => {
    getOrganizationById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <IconButton onClick={() => navigate('/administration/referredOut')}>
        <KeyboardBackspaceIcon className='back-btn-admin' />
      </IconButton>
      {partner?.id ? (
        <PartnerForm partner={partner} isEdit={true} />
      ) : (
        <p>{PartnerTable?.invalidPartnerID}</p>
      )}
    </>
  )
}
export default EditOrganization
