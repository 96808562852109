import React from 'react'

export function useWebSocket(
  url: string | undefined,
  appType: string | undefined,
  onMessage: (message: any) => void
) {
  const socketRef = React.useRef<WebSocket | null>(null)

  function createSocketConnection() {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.warn('WebSocket is already open.')
      return
    }

    const socket = new WebSocket(`${url}?appType=${appType}`)
    socketRef.current = socket

    socket.onopen = (event) => {
      console.log('WebSocket connection opened:', event)
    }

    socket.onmessage = (event: MessageEvent) => {
      try {
        const message = JSON.parse(event.data)
        onMessage(message)
      } catch (error) {
        console.error('Error parsing WebSocket message:', error)
      }
    }

    socket.onerror = (event: Event) => {
      console.error('WebSocket error observed:', event)
    }

    socket.onclose = (event: CloseEvent) => {
      console.log('WebSocket closed with code:', event.code)
      if (event.code !== 1000 && event.code !== 1001) {
        console.log('Abnormal closure, attempting to reconnect...')
        setTimeout(createSocketConnection, 3000) // Retry after 3 seconds
      }
    }
  }

  function sendData(payload: Record<string, any>) {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      try {
        socketRef.current.send(JSON.stringify(payload))
        console.log('Message Sent', payload)
      } catch (error) {
        console.error('Failed to send WebSocket message:', error)
      }
    } else {
      console.warn('WebSocket is not open. Cannot send message.')
    }
  }

  function closeSocketConnection() {
    if (socketRef.current) {
      socketRef.current.close()
    }
  }

  return {
    createSocketConnection,
    sendData,
    closeSocketConnection,
  }
}
