import React from 'react'
import SxpForm from '../../../../form/SxpForm'
import { OPD_PROJECT_ID } from '../../../../utils/constants'
import { useParams } from 'react-router-dom'
import RenderOnRole from '../../../../components/RenderOnRole'
import { HISTORY_READ, HISTORY_WRITE } from '../../../../utils/roles'
import SxpElement from '../../../../form/SxpElement'

const TreatmentPlanPhysio = () => {
  const { id } = useParams()
  // const [key, setKey] = useState(Date.now())
  return (
    <RenderOnRole
      roles={[HISTORY_READ, HISTORY_WRITE]}
      element={
        <SxpForm
          project={OPD_PROJECT_ID}
          intent='treatmentForPhysio'
          journeyState={{ encounterId: id }}
          // onJourneyEnd={() => setKey(Date.now())}
        >
          <SxpElement reference='treatmentPlan' />
          <SxpElement reference='followUpAdvice' />
          <SxpElement reference='followUpDate' />
          <SxpElement reference='homeAdvice' />
          <SxpElement reference='referralRequired' />
          <SxpElement reference='nameOfDoctor' />
          <SxpElement disabled reference='department' />
          <SxpElement reference='reasonReferral' />
        </SxpForm>
      }
    />
  )
}

export default TreatmentPlanPhysio
