import React from 'react'
import './component.scss'
import { NavLink } from 'react-router-dom'
import Location from '../features/location/Location'
import ProfileDropDown from './ProfileDropDown'
import MainMenu from './MainMenu'
import logoImg from './../assets/images/content-view-grid-icon.svg'
import { Badge, IconButton, BadgeProps } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { styled, useTheme } from '@mui/material/styles'
import useCustomStyles from '../utils/hooks/CustomStylesHook'
import NotificationList from '../features/Notification/Notification'
import { useAppSelector } from '../app/hooks'
import { selectUnreadCount } from '../features/Notification/CarePlanSlice'

const styles = () => ({
  notificationBtn: {
    marginRight: '1rem',
  },
  notificationIcon: {
    color: 'white',
  },
})

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `0.5px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))

const Header = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const [openNotificatons, setOpenNotificatons] = React.useState(false)
  const notificationCount = useAppSelector(selectUnreadCount)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenNotificatons(newOpen)
  }

  return (
    <div className='headerComponent'>
      <div className='headerBodyComponnet'>
        <div className='logo'>
          <NavLink to='/'>
            <img src={logoImg} alt='Logo' style={{ height: '1rem' }} />
          </NavLink>
        </div>
        <div>
          <MainMenu />
        </div>
      </div>
      <div className='location-data'>
        <IconButton
          className={classes?.notificationBtn}
          onClick={toggleDrawer(true)}
        >
          <StyledBadge badgeContent={notificationCount} color='success'>
            <NotificationsIcon className={classes?.notificationIcon} />
          </StyledBadge>
        </IconButton>
        <Location />
        <ProfileDropDown />
      </div>

      <NotificationList
        openNotificatons={openNotificatons}
        toggleDrawer={toggleDrawer}
      />
    </div>
  )
}

export default Header
