import React, { forwardRef } from 'react'

import logo from '../../../assets/icons/logo.svg'
import locationIcon from '../../../assets/icons/location.svg'
import emailIcon from '../../../assets/icons/Email.svg'
import mobileIcon from '../../../assets/icons/mobile.svg'
import { readableDateTime, getTotalAge } from '../../../utils/dateUtils'
import Barcode from 'react-barcode'
import { Patient } from 'fhir/r4'
import { makeName } from '../../lms/utils'
import { getLocationAddress } from '../utils'
import { useAppSelector } from '../../../app/hooks'
import { selectLocations } from '../../location/locationSlice'
import LocationByPrefix from './LocationByPrefix'

type PatientProps = {
  patient: Patient
}

export const getRegistrationNo = (patient: Patient | undefined) => {
  const identifier = patient?.identifier
  if (identifier) {
    const regNo = identifier.find((data: any) =>
      data?.system?.includes('patient-identifier') ? data : ''
    )

    const thopId = identifier.find((data: any) =>
      data?.system?.includes('thop-identifier') ? data : ''
    )
    return { regNo, thopId }
  }
  return ''
}

const RegistrationCard = ({ patient }: PatientProps) => {
  const { name, photo, gender, birthDate, telecom } = patient
  const getRegistrationDate = (patient: Patient | undefined) => {
    const identifier = patient?.identifier
    if (identifier) {
      const registrationDate = identifier.find((data: any) =>
        data?.system?.includes('registrationType') ? data : ''
      )

      return registrationDate?.period?.start
    }
    return ''
  }

  const registrationDate: any = getRegistrationDate(patient)

  const registeredOn = registrationDate
    ? readableDateTime(registrationDate)
    : ''

  const patientName = makeName(name)
  const patientProfImg = photo?.[0]?.url ?? ''
  const age = birthDate ? getTotalAge(new Date(birthDate)) : ''
  const mobileNo = telecom?.[0]?.value ?? ''
  const registrationNoData: any = getRegistrationNo(patient)

  const registrationNo = registrationNoData?.regNo?.value
    ? registrationNoData?.regNo?.value
    : registrationNoData?.thopId?.value

  const barCodeData = registrationNo ?? ''

  const location = registrationNo ? (
    <LocationByPrefix id={registrationNo} />
  ) : (
    '---'
  )
  const locations = useAppSelector(selectLocations)
  const locationAddress = getLocationAddress(registrationNo, locations)

  return (
    <div className='regCardBody'>
      <div>
        <div className='headerRibbon'>
          <div className='orangeline' />
          <div className='blueline' />
        </div>
        <div className='RegCardMain'>
          <div className='regCardHeader'>
            <div className='medUniLogo' style={{ flex: 1 }}>
              <img src={logo ?? ''} alt='Medunited Logo' />
            </div>
            <div className='medUniAdd'>
              <span>
                <img src={locationIcon ?? ''} alt='location icon' />
                <p> {locationAddress?.address?.line?.[0]}</p>
                <br />
                <span>
                  <img src={mobileIcon ?? ''} alt='mobile icon' />
                  <p> {locationAddress?.telecom?.[0]?.value}</p>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span>
                  <img src={emailIcon ?? ''} alt='email icon' />
                  <p
                    style={{
                      marginLeft: '5px',
                      lineHeight: '11px',
                    }}
                  >
                    {locationAddress?.telecom?.[1]?.value}
                  </p>
                </span>
              </span>
            </div>
          </div>
          <hr />
          <div className='title-body'>
            <h2 className='title-main'> Registration Card</h2>
            <div>
              <div className='barcode-style'>
                {barCodeData && (
                  <Barcode
                    value={'' + barCodeData}
                    height={30}
                    width={1.3}
                    margin={0}
                    fontSize={12}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='patientDetail'>
            <div className='tableBorderLeft'>
              <div className='patient-main'>
                <p>
                  <b>Registration ID</b>
                </p>
                <p> :</p>
                <span>{registrationNo ?? ''}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b>Patient Name</b>
                </p>
                <p> :</p>
                <span>{patientName}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b> Age</b>
                </p>
                <p> :</p>
                <span>{age ?? ''}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b>Gender</b>
                </p>
                <p> :</p>
                <span>{gender}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b> Phone Number</b>
                </p>
                <p> :</p>
                <span>{mobileNo}</span>
              </div>
              <div className='patient-main'>
                <p>
                  {' '}
                  <b>Registered On</b>
                </p>
                <p> :</p>
                <span>{registeredOn ?? ''}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b>Registered At</b>
                </p>
                <p> :</p>
                <span>{location ?? '---'}</span>
              </div>
            </div>
            <div className='tableBorderRight'>
              {patientProfImg && (
                <img src={patientProfImg} alt='Patient Profile Picture' />
              )}
            </div>
          </div>
        </div>
        <div className='footer-style'>
          <footer>
            <p>CR Healthcare Services Pvt Ltd* (CIN - U85110TG2021PTC153300)</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

const PatientRegCardPrint = forwardRef<HTMLDivElement, PatientProps>(
  function Label(props, ref) {
    return (
      <div ref={ref}>
        <RegistrationCard patient={props.patient} />
      </div>
    )
  }
)

export default PatientRegCardPrint
