import { CHAT_PROJECT_ID, VERSION } from './constants'
import HttpService from './httpService'

export const startSxpProxy = (
  projectId: string | undefined,
  intent: string,
  state: any = {}
) => {
  const client = HttpService.getAxiosClient()
  const uri = `/api/projectId/${projectId}`
  const body = { intent, state }
  return client.post(uri, body, {
    headers: { version: VERSION },
  })
}

export const updateSxpState = async (
  projectId: string | undefined,
  state: any
) => {
  const client = HttpService.getAxiosClient()
  const uri = `/state/projectId/${projectId}`
  const body = {
    state,
  }
  const response = await client.put(uri, body)
  return response.data
}

export const sendSxpMessage = async (
  projectId: string | undefined,
  message: string
) => {
  const client = HttpService.getAxiosClient()
  const uri = `/message/process/projectId/${CHAT_PROJECT_ID}`
  const body = {
    message,
    version: VERSION,
  }
  const response = await client.post(uri, body)
  return response.data
}

export const startSxpForm = async (
  projectId: string | undefined,
  intent: string,
  state: any,
  submit: boolean
) => {
  const client = HttpService.getAxiosClient()
  const url = `/form/projectId/${projectId}`
  const body = {
    intent,
    state,
    submit,
  }
  const response = await client.post(url, body, {
    headers: {
      version: VERSION,
    },
  })
  return response.data
}

export const uploadImage = async (body: FormData) => {
  const client = HttpService.getFileServerClient()
  const uri = '/sxp/services/upload'
  const response = await client.post(uri, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}
