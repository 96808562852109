import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './opdVisit.scss'
import { startSxpProxy } from '../../utils/api'
import { OPD_PROJECT_ID } from '../../utils/constants'
import { toast } from 'react-toastify'
import { visitSuccess } from './constant'

type Props = {
  onClose: () => void
  patientId: string
  appointmentId: string
  onRefresh?: () => void
}

const validationSchema = Yup.object({
  visitType: Yup.string().required('visit type is required'),
  attendantName: Yup.string(),
  attendantPhNo: Yup.string(),
  attendantEmail: Yup.string().email('Invalid email format'),
  conformPatientAddress: Yup.boolean(),
  isMedicoLegalCase: Yup.boolean(),
  mlcNo: Yup.string(),
  mlcNotes: Yup.string(),
  relationshipWithPatient: Yup.string(),
})

const initialFormState = {
  visitType: '',
  attendantName: '',
  attendantPhNo: '',
  attendantEmail: '',
  attendantAddress: '',
  conformPatientAddress: false,
  relationshipWithPatient: '',
  isMedicoLegalCase: false,
  mlcNo: '',
  mlcNotes: '',
}

const visitTypeOptions = [
  { value: '', label: 'Select visit type' },
  { value: 'general', label: 'General' },
  { value: 'physio', label: 'Physiotherapy' },
  { value: 'dental', label: 'Dental' },
]
const relationOptions = [
  { label: 'Select An Option', value: '' },
  { label: 'Father', value: 'father' },
  { label: 'Mother', value: 'mother' },
  { label: 'Husband', value: 'husband' },
  { label: 'Wife', value: 'wife' },
  { label: 'Son', value: 'son' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Brother', value: 'brother' },
  { label: 'Sister', value: 'sister' },
  { label: 'Guardian', value: 'guardian' },
]

const OPDVisitCreateDialog: React.FC<Props> = ({
  onClose,
  patientId,
  appointmentId,
  onRefresh,
}) => {
  const [formData] = useState(initialFormState)
  const handleSubmit = (
    values: typeof initialFormState,
    { setSubmitting }: any
  ) => {
    const state = {
      isMedicoLegalCase: values.isMedicoLegalCase === true ? 'yes' : 'no',
      mlcNo: values.mlcNo,
      mlcNotes: values.mlcNotes,
      id: patientId,
      appointmentId: appointmentId,
      visitType: values.visitType,
      relationShipPatient: values.relationshipWithPatient,
      attendantName: values.attendantName,
      attendantPhNo: values.attendantPhNo,
      email: values.attendantEmail,
      attendantAddress: values.attendantAddress,
    }
    startSxpProxy(OPD_PROJECT_ID, 'createVisitUI', state)
      .then(() => {
        onRefresh?.()
        onClose()
        setSubmitting(false)
        toast.success(visitSuccess)
      })
      .catch((err) => console.error(err, 'err'))
  }

  const formOne = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })
  const handleConformPatientAddressChange = async (isChecked: boolean) => {
    if (isChecked) {
      try {
        const data = await startSxpProxy(OPD_PROJECT_ID, 'statesAndAddress', {
          id: patientId,
        })
        if (data && data?.data) {
          formOne.setFieldValue('attendantAddress', data.data)
        } else {
          formOne.setFieldValue('attendantAddress', '')
        }
      } catch (error) {
        console.error('Error fetching patient address:', error)
        formOne.setFieldValue('attendantAddress', '')
      }
    } else {
      formOne.setFieldValue('attendantAddress', '')
    }
  }

  return (
    <>
      <div
        className='form-container'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div
          className='form-box'
          style={{
            flex: 1,
            width: '30vw',
            marginBottom: '2rem',
          }}
        >
          <form onSubmit={formOne.handleSubmit} className='form-grid'>
            <div className='form-flex'>
              <label htmlFor='visitType'>Visit Type</label>
              <select
                name='visitType'
                value={formOne?.values?.visitType}
                onChange={formOne?.handleChange}
                onBlur={formOne?.handleBlur}
              >
                {visitTypeOptions?.map((option) => (
                  <option key={option?.value} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </select>
              {formOne?.errors?.visitType && formOne?.touched?.visitType ? (
                <div style={{ fontSize: '12px' }}>
                  {formOne?.errors?.visitType}
                </div>
              ) : null}
            </div>
            <div className='form-flex'>
              <label htmlFor='attendantName'>Attendant Name</label>
              <input
                name='attendantName'
                value={formOne?.values?.attendantName}
                onChange={formOne?.handleChange}
                onBlur={formOne?.handleBlur}
              />
              {formOne?.errors?.attendantName &&
              formOne?.touched?.attendantName ? (
                <div>{formOne?.errors?.attendantName}</div>
              ) : null}
            </div>
            <div className='form-flex'>
              <label htmlFor='attendantPhNo'>Attendant Phone Number</label>
              <input
                name='attendantPhNo'
                value={formOne?.values?.attendantPhNo}
                onChange={formOne?.handleChange}
                onBlur={formOne?.handleBlur}
              />
              {formOne?.errors?.attendantPhNo &&
              formOne?.touched?.attendantPhNo ? (
                <div>{formOne?.errors?.attendantPhNo}</div>
              ) : null}
            </div>
            <div className='form-flex'>
              <label htmlFor='attendantEmail'>Attendant Email</label>
              <input
                name='attendantEmail'
                value={formOne?.values?.attendantEmail}
                onChange={formOne?.handleChange}
                onBlur={formOne?.handleBlur}
              />
              {formOne?.errors?.attendantEmail &&
              formOne?.touched?.attendantEmail ? (
                <div>{formOne?.errors?.attendantEmail}</div>
              ) : null}
            </div>
            <div className='form-flex'>
              <label>Confirm Patient Address</label>
              <input
                type='checkbox'
                name='conformPatientAddress'
                checked={formOne?.values?.conformPatientAddress}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  formOne.handleChange(e)
                  handleConformPatientAddressChange(isChecked)
                }}
                onBlur={formOne.handleBlur}
              />
            </div>
            {formOne?.values?.conformPatientAddress === false && (
              <div className='form-flex'>
                <label htmlFor='attendantEmail'>Attendant Address</label>
                <input
                  name='attendantAddress'
                  value={formOne?.values?.attendantAddress}
                  onChange={formOne?.handleChange}
                  onBlur={formOne?.handleBlur}
                />
                {formOne?.errors?.attendantName &&
                formOne?.touched?.attendantName ? (
                  <div>{formOne?.errors?.attendantName}</div>
                ) : null}
              </div>
            )}
            <div className='form-flex'>
              <label htmlFor='visitType'>Relationship With Patient</label>
              <select
                name='relationshipWithPatient'
                value={formOne?.values?.relationshipWithPatient}
                onChange={formOne?.handleChange}
                onBlur={formOne?.handleBlur}
              >
                {relationOptions?.map((option) => (
                  <option key={option?.value} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </select>
              {formOne?.errors?.relationshipWithPatient &&
              formOne?.touched?.relationshipWithPatient ? (
                <div>{formOne?.errors?.relationshipWithPatient}</div>
              ) : null}
            </div>
            <div className='form-flex'>
              <label>Is Medico Legal Case</label>
              <input
                type='checkbox'
                name='isMedicoLegalCase'
                checked={formOne?.values?.isMedicoLegalCase}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  formOne.handleChange(e)
                  if (!isChecked) {
                    formOne.setFieldValue('mlcNo', initialFormState.mlcNo)
                    formOne.setFieldValue('mlcNotes', initialFormState.mlcNotes)
                  }
                }}
                onBlur={formOne.handleBlur}
              />
            </div>
            {formOne?.values?.isMedicoLegalCase && (
              <div className='form-flex'>
                <label htmlFor='mlcNo'>MLC Number</label>
                <input
                  name='mlcNo'
                  value={formOne?.values?.mlcNo}
                  onChange={formOne?.handleChange}
                  onBlur={formOne?.handleBlur}
                />
              </div>
            )}
            {formOne?.values?.isMedicoLegalCase && (
              <div className='form-flex'>
                <label htmlFor='mlcNotes'>MLC Notes</label>
                <input
                  name='mlcNotes'
                  value={formOne?.values?.mlcNotes}
                  onChange={formOne?.handleChange}
                  onBlur={formOne?.handleBlur}
                />
              </div>
            )}
            <button
              className='createvisit-submitbtn '
              type='submit'
              disabled={formOne?.isSubmitting}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default OPDVisitCreateDialog
