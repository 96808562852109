export const ARRIVAL_MODE_OPTIONS = [
  {
    label: 'Ambulance',
    value: 'ambulance',
  },
  {
    label: 'Private',
    value: 'private',
  },
  {
    label: 'Public',
    value: 'public',
  },
]

export const DECISION_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
]

export const CONSCIOUSNESS_OPTIONS = [
  { label: 'E1', value: 'e1' },
  { label: 'E2', value: 'e2' },
  { label: 'E3', value: 'e3' },
  { label: 'E4', value: 'e4' },
  { label: 'V1', value: 'v1' },
  { label: 'V2', value: 'v2' },
  { label: 'V3', value: 'v3' },
  { label: 'V4', value: 'v4' },
  { label: 'V5', value: 'v5' },
  { label: 'M1', value: 'm1' },
  { label: 'M2', value: 'm2' },
  { label: 'M3', value: 'm3' },
  { label: 'M4', value: 'm4' },
  { label: 'M5', value: 'm5' },
  { label: 'M6', value: 'm6' },
]

export const AIRWAY_OPTIONS = [
  { label: 'Patent', value: 'patent' },
  { label: 'Non-Patent', value: 'nonPatent' },
]

export const BREATHING_OPTIONS = {
  o2: false,
  nrbm: false,
  niv: false,
}

export const REVIEW_SYSTEMS_DATA = [
  {
    system: 'ENT',
    findings: [
      { label: 'Ear Ache', value: 'earAche' },
      { label: 'Ear Discharge', value: 'earDischarge' },
      { label: 'Tinnitus', value: 'tinnitus' },
      { label: 'Vertigo', value: 'vertigo' },
      { label: 'Voice Change', value: 'voiceChange' },
      { label: 'Sore Throat', value: 'soreThroat' },
      { label: 'Swelling Neck', value: 'swellingNeck' },
      { label: 'Difficulty in Swallowing', value: 'difficultySwallowing' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
  {
    system: 'Ophthalmology',
    findings: [
      { label: 'Eye Redness', value: 'eyeRedness' },
      { label: 'Vision Loss', value: 'visionLoss' },
      { label: 'Eye Pain', value: 'eyePain' },
      { label: 'Eye Discharge', value: 'eyeDischarge' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
  {
    system: 'Cardiovascular',
    findings: [
      { label: 'Shortness of Breath', value: 'shortnessOfBreath' },
      { label: 'Chest Pain', value: 'chestPain' },
      { label: 'Oedema', value: 'oedema' },
      { label: 'Palpitation', value: 'palpitation' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },

  {
    system: 'Respiratory',
    findings: [
      { label: 'Hemoptysis', value: 'hemoptysis' },
      { label: 'Cough', value: 'cough' },
      { label: 'Sputum', value: 'sputum' },
      { label: 'Wheeze', value: 'wheeze' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },

  {
    system: 'Gastrointestinal',
    findings: [
      { label: 'Abdominal Pain', value: 'abdominalPain' },
      { label: 'Vomiting', value: 'vomiting' },
      { label: 'Malena', value: 'malena' },
      { label: 'Hepatitis B/C', value: 'hepatitisBC' },
      { label: 'Diarrhea', value: 'diarrhea' },
      { label: 'Hematemesis', value: 'hematemesis' },
      { label: 'Flatus', value: 'flatus' },
      { label: 'Jaundice', value: 'jaundice' },
      { label: 'Constipation', value: 'constipation' },
      { label: 'Bleeding P/R', value: 'bleedingPR' },
      { label: 'Any Other', value: 'anyOther' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },

  {
    system: 'Genitourinary',
    findings: [
      { label: 'Hematuria', value: 'hematuria' },
      { label: 'H/O TURP', value: 'historyOfTURP' },
      { label: 'Dialysis', value: 'dialysis' },
      { label: 'Penile/Vaginal Discharge', value: 'penileVaginalDischarge' },
      { label: 'Dysuria', value: 'dysuria' },
      { label: 'H/O Stones', value: 'historyOfStones' },
      { label: 'Infertility', value: 'infertility' },
      { label: 'Nocturia', value: 'nocturia' },
      { label: 'Renal Failure', value: 'renalFailure' },
      { label: 'Any Other', value: 'anyOther' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
  {
    system: 'Musculoskeletal',
    findings: [
      { label: 'Joint Pain', value: 'jointPain' },
      { label: 'Joint Swelling', value: 'jointSwelling' },
      { label: 'Inability to Walk', value: 'inabilityToWalk' },
      { label: 'Muscle Pain', value: 'musclePain' },
      { label: 'Fracture', value: 'fracture' },
      { label: 'Joint Stiffness', value: 'jointStiffness' },
      { label: 'Trauma', value: 'trauma' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
  {
    system: 'Neurological',
    findings: [
      { label: 'Headache', value: 'headache' },
      { label: 'Ear Discharge', value: 'earDischarge' },
      { label: 'Tinnitus', value: 'tinnitus' },
      { label: 'Vertigo', value: 'vertigo' },
      { label: 'Numbness', value: 'numbness' },
      { label: 'Syncope', value: 'syncope' },
      { label: 'Back Ache', value: 'backAche' },
      { label: 'Diplopia', value: 'diplopia' },
      { label: 'Radiating Pain', value: 'radiatingPain' },
      { label: 'Drowsiness', value: 'drowsiness' },
      { label: 'Weakness in Limbs', value: 'weaknessInLimbs' },
      { label: 'Stroke', value: 'stroke' },
      { label: 'Claudication', value: 'claudication' },
      { label: 'Rest Pain in Legs', value: 'restPainInLegs' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
  {
    system: 'Integumentary',
    findings: [
      { label: 'Skin Disorders', value: 'skinDisorders' },
      { label: 'Rash', value: 'rash' },
      { label: 'Itching', value: 'itching' },
      { label: 'Discoloration', value: 'discoloration' },
      { label: 'Others', value: 'others' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
  {
    system: 'Psychiatric',
    findings: [
      { label: 'Agitated', value: 'agitated' },
      { label: 'Demented', value: 'demented' },
      { label: 'Anxious', value: 'anxious' },
      { label: 'Depression', value: 'depression' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
  {
    system: 'Endocrine/immunologic',
    findings: [
      { label: 'Thyroid Swelling', value: 'thyroidSwelling' },
      { label: 'Diabetes', value: 'diabetes' },
      { label: 'Adrenal Mass', value: 'adrenalMass' },
      { label: 'Pituitary Lesion', value: 'pituitaryLesion' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
  {
    system: 'Hematologic/Lymphatic',
    findings: [
      { label: 'Anemia', value: 'anemia' },
      { label: 'Low Platelets', value: 'lowPlatelets' },
      { label: 'Neutropenia', value: 'neutropenia' },
      { label: 'Blood Transfusions', value: 'bloodTransfusions' },
      { label: 'No Symptoms', value: 'noSymptoms' },
    ],
  },
]

export const OBSTETRIC_OPTION_DATA = {
  normal: 'Normal',
  ceasareanSection: 'Ceasarean Section',
  forceps: 'Forceps',
  ventouse: 'Ventouse',
}

export const OBSTETRIC_RADIO_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

export function displayPeripheralPulse(name: string) {
  switch (name) {
    case 'carotidRight':
      return 'Carotid(R)'
    case 'carotidLeft':
      return 'Carotid(L)'
    case 'brachialRight':
      return 'Brachial(R)'
    case 'brachialLeft':
      return 'Brachial(L)'
    case 'radialRight':
      return 'Radial(R)'
    case 'radialLeft':
      return 'Radial(L)'
    case 'femoralRight':
      return 'Femoral(R)'
    case 'femoralLeft':
      return 'Femoral(L)'
    case 'poplitealRight':
      return 'Popliteal(R)'
    case 'poplitealLeft':
      return 'Popliteal(L)'
    case 'tibalRight':
      return 'Tibal(R)'
    case 'tibalLeft':
      return 'Tibal(L)'
    case 'dorasilRight':
      return 'Dorasil(R)'
    case 'dorasilLeft':
      return 'Dorasil(L)'
    default:
      return name
  }
}

export const EXPOSURE_OPTIONS = [
  { label: 'Positive', value: 'positive' },
  { label: 'Negative', value: 'negative' },
]

export const DEPARTMENT_OPTIONS = [
  { label: 'Cardiology', value: 'cardiology' },
  { label: 'Neurology', value: 'neurology' },
  { label: 'Oncology', value: 'oncology' },
  { label: 'Pediatrics', value: 'pediatrics' },
  { label: 'Orthopedics', value: 'orthopedics' },
  { label: 'Gynecology', value: 'gynecology' },
  { label: 'Emergency', value: 'emergency' },
  { label: 'Radiology', value: 'radiology' },
  { label: 'Gastroenterology', value: 'gastroenterology' },
  { label: 'Urology', value: 'urology' },
  { label: 'Nephrology', value: 'nephrology' },
  { label: 'Dermatology', value: 'dermatology' },
  { label: 'ENT', value: 'ent' },
  { label: 'Endocrinology', value: 'endocrinology' },
  { label: 'Pulmonology', value: 'pulmonology' },
  { label: 'Psychiatry', value: 'psychiatry' },
  { label: 'Ophthalmology', value: 'ophthalmology' },
  { label: 'Hematology', value: 'hematology' },
  { label: 'Rheumatology', value: 'rheumatology' },
  { label: 'General Surgery', value: 'general_surgery' },
  { label: 'Plastic Surgery', value: 'plastic_surgery' },
  { label: 'Vascular Surgery', value: 'vascular_surgery' },
  { label: 'Anesthesiology', value: 'anesthesiology' },
  { label: 'Pathology', value: 'pathology' },
  { label: 'Physiotherapy', value: 'physiotherapy' },
  { label: 'Nutrition and Dietetics', value: 'nutrition_dietetics' },
]

export const OBSTETRIC_FORM_PARITY = [
  { label: 'Live', value: 'live' },
  { label: 'Still Birth', value: 'stillbirth' },
  { label: 'Abortion', value: 'abortion' },
]

export const OBSTETRIC_PARITY = {
  parity: 'Parity',
}
