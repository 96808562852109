import React, { FC, ChangeEvent } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Button,
  Autocomplete,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import './dialog.scss'

interface TransferDialogProps {
  open: boolean
  onClose: () => void
  doctors: any
  department: string
  doctor: string
  notes: string
  onDepartmentChange: (event: ChangeEvent<HTMLInputElement>) => void
  onDoctorChange: (value: any) => void
  onNotesChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
  otherDoctor?: string
  onOtherDoctorChange?: (value: any) => void
}

const TransferDialog: FC<TransferDialogProps> = ({
  open,
  onClose,
  doctors,
  department,
  doctor,
  notes,
  onDepartmentChange,
  onDoctorChange,
  onNotesChange,
  onSubmit,
  otherDoctor,
  onOtherDoctorChange,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        Transfer to Department
        <IconButton
          edge='end'
          color='inherit'
          onClick={onClose}
          aria-label='close'
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* <TextField
          select
          label='Doctors'
          value={doctor}
          onChange={onDoctorChange}
          fullWidth
          margin='normal'
        >
          {doctors.map((doc: any, index: any) => (
            <MenuItem key={index.toString()} value={doc}>
              {doc?.name}
            </MenuItem>
          ))}
        </TextField> */}

        <Box zIndex={1} paddingTop={1}>
          <Autocomplete
            onChange={(_, newValue) => {
              onDoctorChange(newValue)
            }}
            options={doctors}
            // sx={{ width: 300 }}
            getOptionLabel={(option: any) => {
              return option?.name ?? 'searchText'
            }}
            renderInput={(params) => (
              <TextField {...params} label='Doctors Name' />
            )}
          />
        </Box>
        <TextField
          label='Department'
          value={department}
          onChange={onDepartmentChange}
          fullWidth
          margin='normal'
        />
        {doctor == 'Consultant Doctor' ? (
          <TextField
            label='Other Doctor Name'
            value={otherDoctor}
            onChange={onOtherDoctorChange}
            fullWidth
            margin='normal'
          />
        ) : null}
        <TextField
          label='Notes'
          value={notes}
          onChange={onNotesChange}
          fullWidth
          margin='normal'
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 5 }}>
        <Button
          variant='contained'
          size='small'
          onClick={onSubmit}
          className='dialog-button'
        >
          Submit
        </Button>
        <Button
          variant='contained'
          size='small'
          onClick={onClose}
          className='dialog-button'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TransferDialog
