import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { capitalizeFirstLetter } from '../../../patients/utils'
import { allocation } from '../../modals'
import { MealProgress } from './MedicationProgress'
import { useTheme } from '@emotion/react'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { medicationDiscontinue } from './Constants'
import { startSxpProxy } from '../../../../utils/api'
import { IPD_PROJECT_ID } from '../../../../utils/constants'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { toast } from 'react-toastify'
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined'
import { useState } from 'react'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

const styles = () => ({
  medicationDiscontinueBtn: {
    textTransform: 'capitalize',
    backgroundColor: '#4682b4',
  },
  medicationTextDiscontinue: {
    fontSize: '14px',
    fontWeight: 600,
  },
  dialogTextDiscontinue: {
    fontSize: '12px',
  },
  actionsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  medicationDiscontinueActionBtn: {
    backgroundColor: '#4682b4',
    textTransform: 'captilize',
  },
  dialogContentDiscontinue: {
    padding: '10px 24px !important',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
  },
})

const IpdMedicationDashboard = ({ allMedications, setRefresh }: any) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [selectedMedication, setSelectedMedication] = useState<any>({})
  const [openDiscontinue, setOpenDiscontinue] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const [reason, setReason] = useState('')

  const handleCloseConfirm = () => {
    setOpenDiscontinue(false)
  }

  const handleClickConfirm = (medication: any) => {
    setSelectedMedication(medication)
    setOpenDiscontinue(true)
  }

  // const getSelectedDayIndexs = (selectedDays: []) => {
  //   const indexs = selectedDays?.map((d: any, i) => {
  //     switch (d?.value) {
  //       case 'sunday':
  //         return 0
  //       case 'monday':
  //         return 1
  //       case 'tuesday':
  //         return 2
  //       case 'wednesday':
  //         return 3
  //       case 'thursday':
  //         return 4
  //       case 'friday':
  //         return 5
  //       case 'saturday':
  //         return 6
  //       default:
  //         return -1
  //     }
  //   })

  //   return indexs
  // }

  const handleDiscontinue = async () => {
    setIsloading(true)
    const intent = medicationDiscontinue
    const state = {
      id: selectedMedication?.id,
      status: 'INACTIVE',
      discontinuedOn: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
      reason: reason,
    }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.success) {
          toast.success(data?.data?.message)
          setRefresh((prevState: boolean) => !prevState)
          setIsloading(false)
          handleCloseConfirm()
        } else {
          toast.success(data?.data?.message)
          setIsloading(false)
        }
      })
      .catch((err) => {
        console.error(err, 'err')
        setIsloading(false)
      })
  }

  return (
    <>
      <Dialog
        open={openDiscontinue}
        onClose={handleCloseConfirm}
        disableScrollLock
      >
        <DialogTitle>
          <span className={classes?.dialogTextDiscontinue}>
            Are you sure you want to discontinue
          </span>
          &nbsp;
          <span className={classes?.medicationTextDiscontinue}>
            {selectedMedication?.drug_name}
          </span>
          &nbsp; ?
        </DialogTitle>
        <DialogContent className={classes?.dialogContentDiscontinue}>
          <TextField
            multiline
            rows={3}
            fullWidth
            placeholder='Reason'
            label='Reason'
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions className={classes?.actionsCenter}>
          <Button
            variant='contained'
            size='small'
            onClick={handleDiscontinue}
            className={classes?.medicationDiscontinueActionBtn}
            disabled={reason === ''}
          >
            Yes
          </Button>
          <Button
            variant='contained'
            size='small'
            onClick={handleCloseConfirm}
            className={classes?.medicationDiscontinueActionBtn}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          maxHeight: '250px',
          overflowY: 'auto',
        }}
      >
        <table
          className='data-table admin-table'
          style={{ position: 'relative', width: '130%' }}
        >
          <thead className='sticky'>
            <tr>
              <th className='table-w-4'>Sl&nbsp;No</th>
              <th className='text-left w14'>Drug Name</th>
              <th className='text-center w4'>Dose</th>
              <th className='text-center w8'>Dose Units</th>
              <th className='text-center w7'>Duration</th>
              <th className='text-center w9'>Frequency</th>
              <th className='text-center w10'>Route</th>
              <th className='text-center w9'>Start Date</th>
              <th className='text-center w9'>End Date</th>
              <th className='text-center w18'>Intervals</th>
              <th className='text-center w20'>Add.Instructions</th>
              <th className='text-center w12'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allMedications?.length === 0 ? (
              <tr>
                <td colSpan={9} style={{ textAlign: 'center' }}>
                  {allocation.noMedications}
                </td>
              </tr>
            ) : (
              allMedications?.map((medication: any, index: any) => (
                <tr key={index}>
                  <td className='text-center'>{index + 1}</td>
                  <td>{capitalizeFirstLetter(medication?.drug_name)}</td>
                  <td className='text-center'>{medication?.dose}</td>
                  <td className='text-center'>
                    {capitalizeFirstLetter(medication?.dose_units)}
                  </td>
                  <td className='text-center'>{medication?.duration}</td>
                  <td className='text-center'>
                    {capitalizeFirstLetter(medication?.frequency)}
                  </td>
                  <td className='text-center'>
                    {capitalizeFirstLetter(medication?.route)}
                  </td>
                  <td className='text-center'>{medication?.start_date}</td>
                  <td className='text-center'>
                    {medication?.next_visit_date ?? '-'}
                  </td>
                  <td>
                    {medication?.intervals?.length > 0 ? (
                      <div className='drug-flexRow'>
                        {(medication?.frequency === 'Immediately' ||
                          medication?.frequency === 'Once a day' ||
                          medication?.frequency === 'Twice a day' ||
                          medication?.frequency === 'Five times a day' ||
                          medication.frequency === 'Thrice a day' ||
                          medication.frequency === 'Four times a day') && (
                          <MealProgress
                            activeMeals={[
                              {
                                meal: 'breakfast',
                                time: medication.intervals.some(
                                  (option: any) => option?.value === 'bb'
                                )
                                  ? 'before'
                                  : '',
                              },
                              {
                                meal: 'breakfast',
                                time: medication.intervals.some(
                                  (option: any) => option?.value === 'ab'
                                )
                                  ? 'after'
                                  : '',
                              },
                              {
                                meal: 'lunch',
                                time: medication.intervals.some(
                                  (option: any) => option?.value === 'bl'
                                )
                                  ? 'before'
                                  : '',
                              },
                              {
                                meal: 'lunch',
                                time: medication.intervals.some(
                                  (option: any) => option?.value === 'al'
                                )
                                  ? 'after'
                                  : '',
                              },
                              {
                                meal: 'dinner',
                                time: medication.intervals.some(
                                  (option: any) => option?.value === 'bd'
                                )
                                  ? 'before'
                                  : '',
                              },
                              {
                                meal: 'dinner',
                                time: medication.intervals.some(
                                  (option: any) => option?.value === 'ad'
                                )
                                  ? 'after'
                                  : '',
                              },
                            ]}
                          />
                        )}
                        {/* {(medication?.frequency === 'On alternate days' ||
                          medication?.frequency === 'Once a week' ||
                          medication?.frequency === 'Twice a week' ||
                          medication?.frequency === 'Thrice a week' ||
                          medication?.frequency === 'Four days a week' ||
                          medication?.frequency === 'Five days a week' ||
                          medication?.frequency === 'Six days a week') && (
                          <DayProgress
                            activeDays={getSelectedDayIndexs(
                              medication.intervals
                            )}
                          />
                        )} */}
                        {medication?.duration_units == 'hour' &&
                          medication?.intervals[0]?.value}
                        {medication?.duration_units == 'Every 2 weeks' &&
                          medication?.intervals[0]?.value}
                        {medication?.duration_units == 'Every 3 weeks' &&
                          medication?.intervals[0]?.value}
                        {medication?.frequency == 'Day(s)' ||
                          medication?.frequency == 'Week(s)' ||
                          (medication?.frequency == 'Month(s)' &&
                            medication?.intervals[0]?.value)}
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    <span className='fontw600'>
                      {capitalizeFirstLetter(medication?.instructions || '-')}
                    </span>
                    <br />
                    {capitalizeFirstLetter(
                      medication?.additional_instructions || '-'
                    )}
                  </td>
                  <td>
                    <Button
                      disabled={
                        Boolean(medication?.status === 'INACTIVE') || isLoading
                      }
                      variant='contained'
                      size='small'
                      className={classes?.medicationDiscontinueBtn}
                      onClick={() => handleClickConfirm(medication)}
                      startIcon={<EventBusyOutlinedIcon fontSize='small' />}
                    >
                      Discontinue
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default IpdMedicationDashboard
