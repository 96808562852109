export type ipPlaceHolderType = {
  patientUhid: string
  patientName: string
  patientAge: string
  patientMobile: string
  bedNo: string
  location: string
  admissionDate: string
  doctor: string
  department: string
  dateOfVisit: string
  status: string
  patientId: string
  bedPatientId: string
  bedId: string
}
export const ipPlaceHolder = {
  patientUhid: 'UHID',
  patientName: 'Name',
  patientAge: 'Age',
  patientMobile: 'Mobile No.',
  bedNo: 'Bed No',
  location: 'Operating Unit',
  admissionDate: 'Admission Date',
  doctor: 'Doctor',
  department: 'Department',
  dateOfVisit: 'Date Of Visit',
  doctorName: 'doctor',
  ipPatientDashboard: 'IP Patient Dashboard',
  otherDoctor: 'Name of Other Doctor',
}
export const ipVisitApi = {
  masterEncounter: 'masterEncounter',
  createSubEncounter: 'createSubEncounter',
  getPatientByLocationAndStatus: 'getPatientByLocationAndStatus',
  getAllSubEncounters: 'getAllSubEncounters',
  getAllPatientLabOrdersIpd: 'getLabOrdersForIPD',
  getAllDiagnosisIpd: 'getEncounterAndDocName',
}
