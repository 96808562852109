import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import GeneralDetails from './GeneralDetails'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../../../utils/hooks/CustomStylesHook'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AssessmentDetails from './AssessmentDetails'
import ReviewDetails from './ReviewDetails'
import PreviousMedications from './PreviousMedications'

const styles = () => ({
  initialAssessmentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  stepperContainer: {
    width: '80%',
  },
  initialAssessmentStepper: {
    marginBottom: '4%',
  },
})

const steps = ['1', '2', '3', '4']

export default function InitialAssessment() {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [activeStep, setActiveStep] = React.useState(0)

  const initialFormState = {
    mlc: false,
    broughtBy: { name: '', relationship: '', phoneNumber: '' },
    arrivalMode: '',
    arrivalNotification: false,
    hospitalName: '',
    levelOfConsciousness: '',
    pregnant: false,
    allergies: '',
    vitals: {
      pulse: '',
      spo2: '',
      bp: '',
      rr: '',
      grbs: '',
      temperature: '',
      gcs: '',
      painScore: '',
    },
    comorbidities: {
      htn: false,
      dm: false,
      cva: false,
      epilepsy: false,
      cad: false,
      copd: false,
      ckd: false,
      obesity: false,
    },
    assessment: {
      airway: '',
      primarySurveyAirway: '',
      cervicalSpineImmobilization: '',
      primarySurveyCervicalSpineImmobilization: '',
      breathing: {
        o2: false,
        nrbm: false,
        niv: false,
        invasiveVentilation: false,
        o2PrimarySurvey: '',
        nrbmPrimarySurvey: '',
        nivPrimarySurvey: '',
        invasiveVentilationPrimarySurvey: '',
      },
      circulation: {
        peripheralPulses: {},
        capillaryRefillTime: '',
      },
    },
    reviewOfSystems: {},
    gcs: {
      e: '',
      v: '',
      m: '',
    },
    pupils: [{ right: '', left: '' }],
    fnd: '',
    exposure: '',
    chiefComplaints: '',
    previousMedications: [
      { drug: '', dose: '', frequency: '', lastDoseDate: '', lastDoseTime: '' },
    ],
    consultation: {
      department: '',
      timeOfInitiation: '',
      timeOfArrival: '',
      notes: '',
    },
    generalExamination: {
      pallor: '',
      icterus: '',
      cyanosis: '',
      clubbing: '',
      lymphadenopathy: '',
      edema: '',
    },
    obstetric: {
      obstetricValue: '',
      obstetricOptionDetails: '',
      gravidity: '',
      parity: '',
      parityDetails: '',
      lastMenstrualPeriod: '',
      days: '',
      historyOfContraception: '',
      contraceptionDetails: '',
      fertilityTreatment: '',
    },
    erAdvice: [{ lab: '', dose: '', medications: '' }],
  }

  const initialAssessmentForm = useFormik({
    initialValues: initialFormState,
    validationSchema: Yup.object().shape({
      mlc: Yup.boolean(),
      broughtBy: Yup.object().shape({
        name: Yup.string(),
        relationship: Yup.string(),
        phoneNumber: Yup.string(),
      }),
      arrivalMode: Yup.string(),
      arrivalNotification: Yup.boolean(),
      hospitalName: Yup.string(),
      levelOfConsciousness: Yup.string(),
      pregnant: Yup.boolean(),
      allergies: Yup.string(),
      vitals: Yup.object().shape({
        pulse: Yup.string(),
        spo2: Yup.string(),
        bp: Yup.string(),
        rr: Yup.string(),
        grbs: Yup.string(),
        temperature: Yup.string(),
        gcs: Yup.string(),
        painScore: Yup.string(),
      }),
      comorbidities: Yup.object().shape({
        htn: Yup.boolean(),
        dm: Yup.boolean(),
        cva: Yup.boolean(),
        epilepsy: Yup.boolean(),
        cad: Yup.boolean(),
        copd: Yup.boolean(),
        ckd: Yup.boolean(),
        obesity: Yup.boolean(),
      }),
      assessment: Yup.object().shape({
        airway: Yup.string(),
        primarySurveyAirway: Yup.string(),
        cervicalSpineImmobilization: Yup.string(),
        primarySurveyCervicalSpineImmobilization: Yup.string(),
        breathing: Yup.object().shape({
          o2: Yup.boolean(),
          nrbm: Yup.boolean(),
          niv: Yup.boolean(),
          invasiveVentilation: Yup.boolean(),
          o2PrimarySurvey: Yup.string(),
          nrbmPrimarySurvey: Yup.string(),
          nivPrimarySurvey: Yup.string(),
          invasiveVentilationPrimarySurvey: Yup.string(),
        }),
        circulation: Yup.object().shape({
          peripheralPulses: Yup.object(),
          capillaryRefillTime: Yup.string(),
        }),
      }),
      reviewOfSystems: Yup.object(),
      previousMedications: Yup.array().of(
        Yup.object().shape({
          drug: Yup.string(),
          dose: Yup.string(),
          frequency: Yup.string(),
          lastDoseDate: Yup.string(),
          lastDoseTime: Yup.string(),
        })
      ),
      pupils: Yup.array().of(
        Yup.object().shape({
          right: Yup.string(),
          left: Yup.string(),
        })
      ),
      erAdvice: Yup.array().of(
        Yup.object().shape({
          lab: Yup.string(),
          medications: Yup.string(),
        })
      ),
      obstetric: Yup.object().shape({
        obstetricValue: Yup.string(),
        obstetricOptionDetails: Yup.string(),
        gravidity: Yup.number(),
        parity: Yup.string(),
        parityDetails: Yup.string(),
        lastMenstrualPeriod: Yup.date(),
        days: Yup.number(),
        historyOfContraception: Yup.string(),
        contraceptionDetails: Yup.string(),
        fertilityTreatment: Yup.string(),
      }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      console.log(values)
      setSubmitting(false)
    },
    enableReinitialize: true,
  })

  const handleNext = () => {
    initialAssessmentForm?.submitForm()
    if (activeStep === steps.length - 1) {
      setActiveStep(0)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <GeneralDetails form={initialAssessmentForm} />

      case 1:
        return <AssessmentDetails form={initialAssessmentForm} />

      case 2:
        return <PreviousMedications form={initialAssessmentForm} />

      case 3:
        return <ReviewDetails form={initialAssessmentForm} />

      default:
        return <div>1</div>
    }
  }

  return (
    <div className={classes?.initialAssessmentContainer}>
      <Box className={classes?.stepperContainer}>
        <Stepper
          activeStep={activeStep}
          className={classes?.initialAssessmentStepper}
        >
          {steps?.map((label) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <React.Fragment>
          <Box
            mt={0.5}
            mb={1}
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            padding={3}
            borderRadius={5}
            sx={{ background: '#ffffff' }}
          >
            {renderStep()}
          </Box>
          <Box display='flex' flexDirection='row' pt={2}>
            <Button
              size='small'
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant='contained'
              startIcon={<ArrowBackIosIcon fontSize='small' />}
            >
              Back
            </Button>
            <Box flex='1 1 auto' />
            <Button
              size='small'
              onClick={handleNext}
              variant='contained'
              endIcon={
                activeStep === steps.length - 1 ? (
                  ''
                ) : (
                  <ArrowForwardIosIcon fontSize='small' />
                )
              }
            >
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      </Box>
    </div>
  )
}
