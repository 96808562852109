import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export type LabTest = {
  id: number
  name: string
  panelId: number
  sampleId: number
  panelName: string
  sampleName: string
  description: string
}

export type Panel = {
  id: number
  name: string
  sampleId: number
  lab_tests: LabTest[]
  sampleName: string
  description: string
}

export type MedicineSuggestion = {
  sos: boolean
  dose: number
  route: string
  duration: number
  visit_id: number
  drug_name: string
  frequency: string
  intervals: string | null
  dose_units: string
  start_date: string
  instructions: string
  duration_units: string
  quantity_units: string
  total_quantity: number
  next_visit_date: string | null
  additional_instructions: string
}

export type CarePlan = {
  messageType: string
  patientID: string
  patientLocation: string
  patientName: string
  encounterId: string
  visitId: string
  panels: Panel[]
  medicine_suggestions: MedicineSuggestion[]
  treatment_plan: string
  timestamp: string
  suggestedDoctor: string
}

export type CarePlanState = {
  carePlans: CarePlan[]
  unreadCount: number
  lastUpdated: string | null
  isLoading: boolean
}

const initialState: CarePlanState = {
  carePlans: [],
  unreadCount: 0,
  lastUpdated: null,
  isLoading: false,
}

const CarePlanSlice = createSlice({
  name: 'CarePlans',
  initialState,
  reducers: {
    addCarePlan: (state, action: PayloadAction<CarePlan>) => {
      state.carePlans.push(action.payload)
      state.unreadCount += 1
      state.lastUpdated = new Date().toISOString()
    },
    updateCarePlan: (
      state,
      action: PayloadAction<{ index: number; carePlan: CarePlan }>
    ) => {
      state.carePlans[action.payload.index] = action.payload.carePlan
      state.lastUpdated = new Date().toISOString()
    },
    removeCarePlan: (state, action: PayloadAction<number>) => {
      state.carePlans.splice(action.payload, 1)
      state.unreadCount = Math.max(0, state.unreadCount - 1)
      state.lastUpdated = new Date().toISOString()
    },
    removeCarePlanWithPatientId: (state, action: PayloadAction<string>) => {
      const index = state.carePlans.findIndex(
        (carePlan) => carePlan.patientID === action.payload
      )
      if (index !== -1) {
        state.carePlans.splice(index, 1)
        state.unreadCount = Math.max(0, state.unreadCount - 1)
        state.lastUpdated = new Date().toISOString()
      }
    },
    setCarePlans: (state, action: PayloadAction<CarePlan[]>) => {
      state.carePlans = action.payload
      state.unreadCount = 0
      state.lastUpdated = new Date().toISOString()
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  addCarePlan,
  updateCarePlan,
  removeCarePlan,
  removeCarePlanWithPatientId,
  setCarePlans,
  setLoading,
} = CarePlanSlice.actions

export const selectCarePlans = (state: RootState) => state.CarePlans.carePlans
export const selectCarePlanByIndex = (index: number) => (state: RootState) =>
  state.CarePlans.carePlans[index]
export const selectUnreadCount = (state: RootState) =>
  state.CarePlans.unreadCount
export const selectIsLoading = (state: RootState) => state.CarePlans.isLoading
export const selectLastUpdated = (state: RootState) =>
  state.CarePlans.lastUpdated

export default CarePlanSlice.reducer
