import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import './dialog.scss'
import { TextField } from '@mui/material'

interface DoctorInfoDialogProps {
  open: boolean
  onClose: () => void
  rows: Array<{
    department: string
    assignedTime: string
    name: string
    notes?: string
  }>
}

const DoctorInfoDialog: React.FC<DoctorInfoDialogProps> = ({
  open,
  onClose,
  rows,
}) => {
  function formatDateString(dateString: any) {
    const date = new Date(dateString)

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    // Extract hours and minutes
    let hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, '0')

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM'

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, '0')

    // Combine into desired format
    return `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle className='dialog-title-padding'>
        <div className='dialog-header'>
          Primary Doctor Info
          <IconButton
            aria-label='close'
            onClick={onClose}
            className='close-button'
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <TableContainer className='dialog-table-height' component={Paper}>
          <Table stickyHeader>
            <TableHead className='dialog-head'>
              <TableRow>
                <TableCell>Department</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Doctor</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='dialog-body'>
              {rows?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row?.department}</TableCell>
                  <TableCell>{formatDateString(row?.assignedTime)}</TableCell>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>
                    <TextField
                      multiline
                      value={row?.notes}
                      inputProps={{ readOnly: true }}
                      className='dialog-notes'
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DoctorInfoDialog
