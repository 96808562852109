import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  useTheme,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import dayjs from 'dayjs'
import {
  aadharFieldError,
  aadharRegExp,
  CONTACT_RELATION_OPTIONS,
  fieldRequriedError,
  GENDER_OPTIONS,
  GOVERNMENT_ID_OPTIONS,
  INTENT_DISTRICTS,
  INTENT_IP_REGISTRATION,
  INTENT_STATES,
  MARITAL_STATUS_OPTIONS,
  nameSpecialCharFieldError,
  OCCUPATION_OPTIONS,
  PATIENT_SOURCE_OPTIONS,
  phoneFieldError,
  phoneRegExp,
  pinCodeFieldError,
  pincodeRegExp,
  RELIGION_OPTIONS,
  SALUTATION_OPTIONS,
  SOCIO_ECONOMIC_STATUS_OPTIONS,
  specialCharRegExp,
} from './Constants'
import { useAppSelector } from '../../app/hooks'
import { selectSelectedLocationId } from '../location/locationSlice'
import Asterisk from '../../components/Asterisk'
import { useDispatch } from 'react-redux'
import {
  getPatientDetails,
  setFhrId,
  setLoading,
  setMuhId,
  setPatientDetails,
} from './IpdPatientRegisterSlice'
import { useNavigate } from 'react-router-dom'
import { startSxpProxy, uploadImage } from '../../utils/api'
import {
  FILE_SERVER_URL,
  IPD_PROJECT_ID,
  KIMS_LOCATION_ID,
} from '../../utils/constants'
import { toast } from 'react-toastify'

const styles = () => ({
  ipdRegisterFormContainer: {
    width: '100%',
    height: '100%',
    padding: '0.6% 3% 3% 3%',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      fontWeight: 600,
    },
  },
  ipdRegisterFormRow: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '1rem',
    flexWrap: 'wrap',
    rowGap: 20,
  },
  ipdRegisterFormField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
  },
  ipdRegisterHeading: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#090968',
    textDecoration: 'underline',
  },
  ipdRegisterFormActions: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  ipdRegisterFormColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 20,
    marginBottom: 30,
  },
  ipdRegisterMlcDiv: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
  },
  ipdRegisterMlcFormDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
  },
  ipdRegisterNextBtn: {
    width: '10%',
    marginBottom: 10,
  },
})

const calculateAge = (dob: string) => {
  if (dob === '' || dob === null || dob === undefined) {
    return ''
  }
  const birthDate = dayjs(dob)
  const today = dayjs()
  return today.diff(birthDate, 'year')
}

const calculateDob = (age: number): string => {
  if (age < 0) {
    return ''
  }
  const today = dayjs()
  const birthYear = today.year() - age
  return today.set('year', birthYear).format('YYYY-MM-DD')
}

const IpdRegistrationForm = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const locationId = useAppSelector(selectSelectedLocationId)
  const patientDetails = useAppSelector(getPatientDetails)

  const [statesList, setStatesList] = useState([])
  const [districtsList, setdistrictsList] = useState([])

  const initialFormState = {
    fhrId: '',
    locationId: locationId,
    service: locationId === KIMS_LOCATION_ID ? 'qr' : 'fr',
    mrNumber: '',
    salutation: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    dob: '',
    age: '',
    patientSource: '',
    phoneNumber: '',
    alternateNumber: '',
    emailId: '',
    prefferedCommunication: '',
    maritalStatus: '',
    socioEconomicStatus: '',
    governmentId: '',
    idNumber: '',
    aadharId: '',
    religion: '',
    occupation: '',
    houseNo: '',
    area: '',
    town: '',
    state: '',
    district: '',
    postalCode: '',
    relationWithPatient: '',
    emergencyAddress: '',
    emergencyPhoneNumber: '',
    emergencyContactName: '',
    mlc: false,
    mlcForm: '',
  }

  const {
    values,
    setFieldValue,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    submitForm,
  } = useFormik({
    initialValues: initialFormState,
    validationSchema: Yup.object({
      mrNumber: Yup.string().when('locationId', {
        is: (locationId: string) => locationId === KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      salutation: Yup.string().required(fieldRequriedError),
      firstName: Yup.string()
        .matches(specialCharRegExp, nameSpecialCharFieldError)
        .required(fieldRequriedError),
      lastName: Yup.string()
        .matches(specialCharRegExp, nameSpecialCharFieldError)
        .required(fieldRequriedError),
      gender: Yup.string().required(fieldRequriedError),
      age: Yup.number().required(fieldRequriedError).min(0).integer(),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, phoneFieldError)
        .required(fieldRequriedError),
      alternateNumber: Yup.string().matches(phoneRegExp, phoneFieldError),
      emailId: Yup.string().email('Invalid email'),
      prefferedCommunication: Yup.string().required(fieldRequriedError),
      houseNo: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      area: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      town: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      state: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      district: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      postalCode: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () =>
          Yup.string()
            .matches(pincodeRegExp, pinCodeFieldError)
            .required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      maritalStatus: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      // aadharId: Yup.string().when('locationId', {
      //   is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
      //   then: () =>
      //     Yup.string()
      //       .matches(aadharRegExp, aadharFieldError)
      //       .required(fieldRequriedError),
      //   otherwise: () => Yup.string().matches(aadharRegExp, aadharFieldError),
      // }),
      aadharId: Yup.string().matches(aadharRegExp, aadharFieldError),
      relationWithPatient: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      emergencyAddress: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
      emergencyContactName: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () => Yup.string(),
        otherwise: () => Yup.string(),
      }),
      emergencyPhoneNumber: Yup.string().when('locationId', {
        is: (locationId: string) => locationId !== KIMS_LOCATION_ID,
        then: () =>
          Yup.string()
            .matches(phoneRegExp, phoneFieldError)
            .required(fieldRequriedError),
        otherwise: () => Yup.string().matches(phoneRegExp, phoneFieldError),
      }),
      mlc: Yup.boolean(),
      mlcForm: Yup.string().when('mlc', {
        is: true,
        then: () => Yup.string().required(fieldRequriedError),
        otherwise: () => Yup.string(),
      }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleRegistrationSubmit(values, setSubmitting)
    },
    enableReinitialize: true,
  })

  const handleRegistrationSubmit = async (
    data: Record<string, any>,
    setSubmitting: any
  ) => {
    dispatch(
      setLoading({
        active: true,
        message: 'Please Wait Submitting Patient Details',
      })
    )
    setSubmitting(true)
    const {
      locationId,
      service,
      mrNumber,
      salutation,
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      age,
      patientSource,
      phoneNumber,
      alternateNumber,
      emailId,
      preferredCommunication: preferredCommunicationType,
      maritalStatus,
      socioEconomicStatus,
      governmentId,
      idNumber,
      aadharId,
      religion,
      occupation,
      houseNo,
      area,
      town,
      state,
      district,
      postalCode,
      relationWithPatient: emergencyRelationshipWithPatient,
      emergencyAddress,
      emergencyPhoneNumber,
      emergencyContactName,
      mlcForm,
      fhrId,
    } = data

    const formattedData = {
      fhrId,
      locationId,
      service,
      mrNumber,
      salutation,
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      age,
      patientSource,
      phoneNumber,
      alternateNumber,
      emailId,
      preferredCommunicationType:
        preferredCommunicationType === 'sms' ? 'yes' : 'no',
      maritalStatus,
      socioEconomicStatus,
      governmentId,
      idNumber,
      aadharId,
      religion,
      occupation,
      houseNo,
      area,
      town,
      state,
      district,
      postalCode,
      emergencyRelationshipWithPatient,
      emergencyAddress,
      emergencyPhoneNumber,
      emergencyContactName,
      mlcForm,
    }

    const intent = INTENT_IP_REGISTRATION
    const apiState = formattedData

    try {
      const response = await startSxpProxy(IPD_PROJECT_ID, intent, apiState)
      const responseData = response?.data
      if (responseData?.success) {
        dispatch(
          setLoading({
            active: false,
            message: '',
          })
        )
        toast.success(responseData.message)
        try {
          await Promise.all([
            dispatch(
              setPatientDetails({
                patientDetails: { ...data, fhrId: responseData?.data?.fhrId },
              })
            ),
            dispatch(setMuhId({ muhId: responseData?.data?.muhid })),
            dispatch(setFhrId({ fhrId: responseData?.data?.fhrId })),
          ])
          navigate('bedAllocation')
        } catch (error) {
          console.error('Error during dispatch:', error)
        }
      } else {
        dispatch(
          setLoading({
            active: false,
            message: '',
          })
        )
        toast.error(responseData.message)
      }
    } catch (err: any) {
      console.error('Error during registration submission:', err)
      dispatch(
        setLoading({
          active: false,
          message: '',
        })
      )
      toast.error(err?.response?.data?.message)
    }
  }

  const getStatesList = async () => {
    const intent = INTENT_STATES
    const state = {}
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.success) {
          setStatesList(data?.data?.data?.states)
        } else {
          setStatesList([])
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const getDistrictsList = async (selectedState: string) => {
    if (selectedState) {
      const intent = INTENT_DISTRICTS
      const state = {
        state: selectedState,
      }
      await startSxpProxy(IPD_PROJECT_ID, intent, state)
        .then((data) => {
          if (data?.data?.success) {
            setdistrictsList(data?.data?.data?.districts)
          } else {
            setdistrictsList([])
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  const handleUploadDoc = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files?.length) {
      const formData = new FormData()
      formData.append('file', files[0])
      try {
        const response = await uploadImage(formData)
        const urls = `${FILE_SERVER_URL}file/${response.data[0]}`
        await setFieldValue('mlcForm', urls)
      } catch (err) {
        console.error('error uploading the file', err)
      }
    } else {
      console.error('----- No --------')
    }
  }

  useEffect(() => {
    if (values.dob) {
      const age = calculateAge(values.dob)
      setFieldValue('age', age)
    }
  }, [values.dob, setFieldValue])

  useEffect(() => {
    if (values.age) {
      const dob = calculateDob(parseInt(values.age))
      setFieldValue('dob', dob)
    }
  }, [values.age, setFieldValue])

  useEffect(() => {
    setValues({
      ...initialFormState,
      ...patientDetails,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails, locationId])

  useEffect(() => {
    if (values?.state) {
      getDistrictsList(values?.state)
    }
  }, [values?.state])

  useEffect(() => {
    getStatesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes?.ipdRegisterFormContainer}>
      <form>
        <div className={classes?.ipdRegisterFormColumn}>
          <div className={classes?.ipdRegisterHeading}>Basic Details</div>
          <Grid container spacing={1} className={classes?.ipdRegisterFormRow}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterMlcDiv}
            >
              <FormControlLabel
                labelPlacement='top'
                label='MLC'
                control={
                  <Checkbox
                    size='small'
                    checked={values.mlc}
                    onChange={handleChange}
                    name='mlc'
                  />
                }
              />
            </Grid>
            {values?.mlc && (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  MLC Form
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  type='file'
                  name='mlcForm'
                  onChange={handleUploadDoc}
                  error={touched.mlcForm && Boolean(errors.mlcForm)}
                  helperText={touched.mlcForm && errors.mlcForm}
                />
              </Grid>
            )}

            {locationId === KIMS_LOCATION_ID && (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  MR Number
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='mrNumber'
                  placeholder='MR Number'
                  value={values.mrNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.mrNumber && Boolean(errors.mrNumber)}
                  helperText={touched.mrNumber && errors.mrNumber}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>
                Salutation
                <Asterisk />
              </label>
              <TextField
                size='small'
                select
                name='salutation'
                placeholder='Salutation'
                value={values.salutation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.salutation && Boolean(errors.salutation)}
                helperText={touched.salutation && errors.salutation}
              >
                {SALUTATION_OPTIONS?.map(
                  (item: { label: string; value: string }, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    )
                  }
                )}
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>
                First Name
                <Asterisk />
              </label>
              <TextField
                size='small'
                name='firstName'
                placeholder='First Name'
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>Middle Name</label>
              <TextField
                size='small'
                name='middleName'
                placeholder='Middle Name'
                value={values.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>
                Last Name
                <Asterisk />
              </label>
              <TextField
                size='small'
                name='lastName'
                placeholder='Last Name'
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>
                Gender
                <Asterisk />
              </label>
              <TextField
                size='small'
                select
                name='gender'
                placeholder='Gender'
                value={values.gender}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.gender && Boolean(errors.gender)}
                helperText={touched.gender && errors.gender}
              >
                {GENDER_OPTIONS?.map(
                  (item: { label: string; value: string }, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    )
                  }
                )}
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>DOB</label>
              <TextField
                size='small'
                type='date'
                name='dob'
                placeholder='DOB'
                InputLabelProps={{ shrink: true }}
                value={values.dob}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>
                Age
                <Asterisk />
              </label>
              <TextField
                size='small'
                name='age'
                placeholder='Age'
                value={values.age}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.age && Boolean(errors.age)}
                helperText={touched.age && errors.age}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>
                Phone Number
                <Asterisk />
              </label>
              <TextField
                size='small'
                name='phoneNumber'
                placeholder='Phone Number'
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>Email ID</label>
              <TextField
                size='small'
                name='emailId'
                placeholder='Email ID'
                value={values.emailId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.emailId && Boolean(errors.emailId)}
                helperText={touched.emailId && errors.emailId}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.ipdRegisterFormField}
            >
              <label>
                Preferred Communication
                <Asterisk />
              </label>
              <TextField
                size='small'
                select
                name='prefferedCommunication'
                placeholder='Preffered Communication'
                value={values.prefferedCommunication}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.prefferedCommunication &&
                  Boolean(errors.prefferedCommunication)
                }
                helperText={
                  touched.prefferedCommunication &&
                  errors.prefferedCommunication
                }
              >
                <MenuItem value='sms'>SMS</MenuItem>
                <MenuItem value='whatsApp'>WhatsApp</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </div>
        {locationId !== KIMS_LOCATION_ID && (
          <div className={classes?.ipdRegisterFormColumn}>
            <div className={classes?.ipdRegisterHeading}>Other Details</div>
            <Grid container spacing={1} className={classes?.ipdRegisterFormRow}>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>Patient Source</label>
                <TextField
                  size='small'
                  select
                  name='patientSource'
                  placeholder='Patient Source'
                  value={values.patientSource}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {PATIENT_SOURCE_OPTIONS?.map(
                    (item: { label: string; value: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>Alternate Number</label>
                <TextField
                  size='small'
                  name='alternateNumber'
                  placeholder='Alternate Number'
                  value={values.alternateNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.alternateNumber && Boolean(errors.alternateNumber)
                  }
                  helperText={touched.alternateNumber && errors.alternateNumber}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  Marital Status
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  select
                  name='maritalStatus'
                  placeholder='Marital Status'
                  value={values.maritalStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.maritalStatus && Boolean(errors.maritalStatus)}
                  helperText={touched.maritalStatus && errors.maritalStatus}
                >
                  {MARITAL_STATUS_OPTIONS?.map(
                    (item: { label: string; value: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>Socio Economic Status</label>
                <TextField
                  size='small'
                  select
                  name='socioEconomicStatus'
                  value={values.socioEconomicStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {SOCIO_ECONOMIC_STATUS_OPTIONS?.map(
                    (item: { label: string; value: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>Government ID</label>
                <TextField
                  size='small'
                  select
                  name='governmentId'
                  placeholder='Government ID'
                  value={values.governmentId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {GOVERNMENT_ID_OPTIONS?.map(
                    (item: { label: string; value: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>ID Number</label>
                <TextField
                  size='small'
                  name='idNumber'
                  placeholder='ID Number'
                  value={values.idNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>Aadhar ID</label>
                <TextField
                  size='small'
                  name='aadharId'
                  placeholder='Aadhar ID'
                  value={values.aadharId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.aadharId && Boolean(errors.aadharId)}
                  helperText={touched.aadharId && errors.aadharId}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>Religion</label>
                <TextField
                  size='small'
                  select
                  name='religion'
                  placeholder='Religion'
                  value={values.religion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {RELIGION_OPTIONS?.map(
                    (item: { label: string; value: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>Occupation</label>
                <TextField
                  size='small'
                  select
                  name='occupation'
                  placeholder='Occupation'
                  value={values.occupation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {OCCUPATION_OPTIONS?.map(
                    (item: { label: string; value: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>
            </Grid>
          </div>
        )}
        {locationId !== KIMS_LOCATION_ID && (
          <div className={classes?.ipdRegisterFormColumn}>
            <div className={classes?.ipdRegisterHeading}>Address</div>
            <Grid container spacing={1} className={classes?.ipdRegisterFormRow}>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  House No
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='houseNo'
                  placeholder='House No'
                  value={values.houseNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.houseNo && Boolean(errors.houseNo)}
                  helperText={touched.houseNo && errors.houseNo}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  Area
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='area'
                  placeholder='Area'
                  value={values.area}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.area && Boolean(errors.area)}
                  helperText={touched.area && errors.area}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  Town
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='town'
                  placeholder='Town'
                  value={values.town}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.town && Boolean(errors.town)}
                  helperText={touched.town && errors.town}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  State
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  select
                  name='state'
                  placeholder='State'
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                >
                  {statesList?.map(
                    (item: { state_name: string; id: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.state_name}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  District
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  select
                  name='district'
                  placeholder='District'
                  value={values.district}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.district && Boolean(errors.district)}
                  helperText={touched.district && errors.district}
                >
                  {districtsList?.map(
                    (item: { district: string; id: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.district}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  Postal Code
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='postalCode'
                  placeholder='Postal Code'
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.postalCode && Boolean(errors.postalCode)}
                  helperText={touched.postalCode && errors.postalCode}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {locationId !== KIMS_LOCATION_ID && (
          <div className={classes?.ipdRegisterFormColumn}>
            <div className={classes?.ipdRegisterHeading}>Emergency Details</div>
            <Grid container spacing={1} className={classes?.ipdRegisterFormRow}>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  Relation with Patient
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  select
                  name='relationWithPatient'
                  placeholder='Relation with Patient'
                  value={values.relationWithPatient}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.relationWithPatient &&
                    Boolean(errors.relationWithPatient)
                  }
                  helperText={
                    touched.relationWithPatient && errors.relationWithPatient
                  }
                >
                  {CONTACT_RELATION_OPTIONS?.map(
                    (item: { label: string; value: string }, index) => {
                      return (
                        <MenuItem key={index} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>Emergency Contact Name</label>
                <TextField
                  size='small'
                  name='emergencyContactName'
                  placeholder='Emergency Contact Name'
                  value={values.emergencyContactName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.emergencyContactName &&
                    Boolean(errors.emergencyContactName)
                  }
                  helperText={
                    touched.emergencyContactName && errors.emergencyContactName
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  Emergency Phone Number
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='emergencyPhoneNumber'
                  placeholder='Emergency Phone Number'
                  value={values.emergencyPhoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.emergencyPhoneNumber &&
                    Boolean(errors.emergencyPhoneNumber)
                  }
                  helperText={
                    touched.emergencyPhoneNumber && errors.emergencyPhoneNumber
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.ipdRegisterFormField}
              >
                <label>
                  Emergency Address
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  multiline
                  rows={3}
                  name='emergencyAddress'
                  placeholder='Emergency Address'
                  value={values.emergencyAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.emergencyAddress && Boolean(errors.emergencyAddress)
                  }
                  helperText={
                    touched.emergencyAddress && errors.emergencyAddress
                  }
                />
              </Grid>
            </Grid>
          </div>
        )}

        <div className={classes?.ipdRegisterFormActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className='ip-register-btn'
            sx={{
              width: '10%',
              marginBottom: locationId !== KIMS_LOCATION_ID ? 10 : '',
            }}
            onClick={submitForm}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}

export default IpdRegistrationForm
