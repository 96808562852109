import React from 'react'
import { Button, Card, CardActions, CardContent, useTheme } from '@mui/material'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import Typography from '@mui/material/Typography'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'
import { CarePlan } from './CarePlanSlice'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { formatReadableDate } from '../bedManagement/IpdVisit/IpdSummary/Constants'

const styles = () => ({
  notificationCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 0.5,
    width: '100%',
    borderRadius: 5,
    minHeight: '80px',
  },
  cardBtn: {
    textTransform: 'capitalize',
    padding: 'unset',
    background: '#CC5500',
  },
  cardIcon: {
    color: '#183f7c',
    fontSize: '2.5rem',
  },
})

function NotificationCard({
  item,
  index,
  handleOpenClick,
  elevation,
}: {
  item: CarePlan
  index?: number
  handleOpenClick: (carePlan: any) => void
  elevation: number
}) {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  return (
    <Card
      className={classes?.notificationCard}
      key={index}
      elevation={elevation}
    >
      <MedicalServicesIcon className={classes?.cardIcon} />

      <CardContent>
        <Typography component='div' fontSize={'1rem'}>
          {item?.patientID}
        </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          color='text.secondary'
          fontSize={'0.7rem'}
        >
          {item?.patientName}({formatReadableDate(item?.timestamp)})
        </Typography>
      </CardContent>

      <CardActions>
        <Button
          variant='contained'
          size='small'
          className={classes?.cardBtn}
          onClick={() => handleOpenClick(item)}
          endIcon={<FullscreenIcon fontSize='small' />}
          id='open-notification-modal'
        >
          Open
        </Button>
      </CardActions>
    </Card>
  )
}

export default NotificationCard
