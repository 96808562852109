export const startDMSTokenRefresh = () => {
  const callDMSApi = async () => {
    await getDMSToken()
  }
  setInterval(callDMSApi, 14 * 60 * 1000)
  callDMSApi()
}

const getDMSToken = async () => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEDUNITED_AWGMENT_GATEWAY}${process.env.REACT_APP_DMS_TOKEN_ENDPOINT}`,
      requestOptions
    )
    const accessToken = await response.text()
    if (accessToken) {
      sessionStorage.setItem('dms-token', accessToken)
    }
  } catch (error) {
    console.error('Unable to generate dms token', error)
  }
}
