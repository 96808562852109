import JsBarcode from 'jsbarcode'
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer'
import {
  InvoiceData,
  InvoicePanel,
  InvoiceTest,
  numericOutcome,
} from '../../models'
import { invoiceDateTimeFormat } from '../../../../utils/dateUtils'
import {
  getFormattedNumber,
  isAbnormal,
  isOutsideRange,
  summaryReason,
} from '../../utils'
import '../../lms.scss'
import medunitedLogo from '../../../../assets/images/medunited.png'
import locationIcon from '../../../../assets/icons/location.png'
import emailIcon from '../../../../assets/icons/email.png'
import mobileIcon from '../../../../assets/icons/mobile.png'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf',
      fontStyle: 'italic',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    paddingBottom: 30,
    fontSize: 9,
  },
  withoutHeaderPage: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    paddingBottom: 30,
    fontSize: 9,
  },
  technicianContent: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  technicianText: {
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  header: {
    marginLeft: 20,
    marginRight: 30,
    marginTop: 25,
    borderBottom: '1px solid #CDCDCD',
    padding: 4,
    paddingBottom: 8,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
    marginRight: 20,
  },
  headerValue: {
    color: '#343434',
    width: '35%',
  },
  headerKeyView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '30%',
  },
  departmentView: {
    display: 'flex',
    flexDirection: 'column',
  },
  departmentHeader: {
    fontSize: 10,
    marginBottom: 6,
    fontFamily: 'Roboto',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tableHead: {
    marginLeft: 20,
    marginRight: 30,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    backgroundColor: '#F4F4F4',
  },
  panelHead: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F4F4F4',
    padding: 6,
    border: '1px solid #CDCDCD',
    borderTop: 0,
  },
  tableRow: {
    marginLeft: 20,
    marginRight: 30,
    display: 'flex',
    flexDirection: 'row',
  },
  textView: {
    padding: 4,
  },
  textViewHeader: {
    padding: 4,
    border: '1px solid #CDCDCD',
    height: 22,
  },
  headText: {
    textAlign: 'center',
  },
  headLeftText: {
    textAlign: 'left',
  },
  basis10: {
    flexBasis: '10%',
  },
  basis15: {
    flexBasis: '15%',
  },
  basis20: {
    flexBasis: '20%',
  },
  basis25: {
    flexBasis: '25%',
  },
  basis30: {
    flexBasis: '30%',
  },
  basis35: {
    flexBasis: '35%',
  },
  basis40: {
    flexBasis: '40%',
  },
  noRightBorder: {
    borderRight: 0,
  },
  noTopBorder: {
    borderTop: 0,
  },
  smallText: {
    fontSize: 9,
  },
  leftAlign: {
    textAlign: 'left',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  centerAlign: {
    textAlign: 'center',
  },
  observationRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20 32 16 24',
    alignItems: 'flex-start',
  },
  impressionRow: {
    marginLeft: 20,
    marginRight: 30,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
    padding: '3px',
    alignItems: 'flex-start',
  },
  observationTitle: {
    flexBasis: 120,
  },
  observationContent: {
    flexBasis: 400,
    flexGrow: 0,
  },
  impressionTitle: {
    flexBasis: 10,
  },
  impressionContent: {
    marginTop: 1,
  },
  signatureContainer: {
    marginTop: 20,
  },
  margin25: {
    marginTop: 25,
  },
  signatureImage: {
    width: 80,
    height: 25,
  },
  signatureName: {
    marginTop: 7,
    fontSize: 8,
  },
  boldContent: {
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  normalContent: {
    fontSize: 9,
  },
  referredNote: {
    marginLeft: 20,
    marginRight: 30,
    marginTop: 8,
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  lastPage: {
    marginBottom: 150,
  },
  endOfReport: {
    position: 'absolute',
    width: '100%',
    bottom: 35,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  signature: {
    position: 'absolute',
    bottom: 37,
    right: 50,
  },
  summary: {
    marginLeft: 20,
    marginRight: 30,
    marginTop: 12,
  },
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: 10,
    left: 12,
    color: '#343434',
    borderTop: '1px solid #CDCDCD',
    fontSize: 8,
    marginTop: 10,
  },
  withoutFooter: {
    position: 'absolute',
    width: '100%',
    left: 12,
    color: '#343434',
    fontSize: 8,
  },
  footerNotes: {
    position: 'absolute',
    width: '100%',
    bottom: 30,
    left: 12,
    color: '#343434',
  },
  boldText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  interpretation: {
    marginTop: 5,
    marginLeft: 20,
    marginRight: 20,
    paddingBottom: 10,
  },
  interpretationText: {
    marginBottom: 5,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  interpretationValue: {
    fontSize: 9,
    textAlign: 'justify',
  },
  panelText: {
    textAlign: 'left',
    fontSize: 9,
    fontWeight: 800,
  },
  container: {
    top: 0,
    width: '100%',
    height: 8,
    position: 'relative',
  },
  headerTopLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 6,
    width: '30vw',
    backgroundColor: '#F47421',
  },
  headerTopLineAfter: {
    content: '',
    position: 'absolute',
    top: 0,
    right: 0,
    height: 6,
    width: '70vw',
    backgroundColor: '#004D91',
  },
  italicText: {
    color: '#404040',
    fontStyle: 'italic',
    fontSize: 8,
  },
  technician: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  medLogo: {
    position: 'absolute',
    marginLeft: '16',
    height: '17%',
    width: '18%',
    top: '3%',
  },
  logo: {
    width: '8rem',
    height: '8rem',
  },
  pageCount: {
    position: 'absolute',
    bottom: 20,
    right: 12,
    fontSize: 8,
  },
  headerData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 12,
    marginTop: 12,
    fontSize: 8,
  },
  panelContainer: {
    marginBottom: 20,
  },
  pathologyContainer: {
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  pathologyName: {
    marginRight: 10,
    fontWeight: 'bold',
  },
  pathologySignature: {
    width: 90,
    height: 30,
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: {
    // marginBottom:50
  },
  margin60: {
    marginTop: 60,
  },
  margin75: {
    marginTop: 75,
  },
  interpretationImage: {
    height: 550,
  },
})

type Props = {
  data: InvoiceData
}

const barcodeOptions = {
  format: 'CODE128',
  width: 1,
  height: 100,
  displayValue: false,
}

const generateBarcodeImage = (data: any) => {
  const canvas = document.createElement('canvas')
  JsBarcode(canvas, data, barcodeOptions)
  return canvas.toDataURL('image/png')
}

const HeaderRow = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => (
  <View style={styles.headerRow}>
    <View style={styles.headerKeyView}>
      <Text style={styles.boldText}>{label}</Text>
      <Text>{': '}</Text>
    </View>
    <Text style={styles.headerValue}>{value}</Text>
  </View>
)

const InvoiceHeader = ({ data }: { data: InvoiceData }) => (
  <>
    <View fixed style={styles.header}>
      <View>
        <HeaderRow label='Name' value={data?.name} />
        <HeaderRow label='Id' value={data?.id} />
        <HeaderRow label='Age' value={data?.age} />
        <HeaderRow label='Gender' value={data?.gender} />
        <HeaderRow label='Mobile No' value={data?.mobile} />
        <HeaderRow label='Order Type' value={data?.orderType} />
      </View>
      <View>
        {data?.requestedBy?.indexOf('[') > 0 ? (
          <>
            <HeaderRow
              label='Requested By'
              value={data?.requestedBy?.split('[')[0]}
            />
            <HeaderRow
              label='Specialty'
              value={data?.requestedBy?.split('[')?.[1]?.slice(0, -1)}
            />
          </>
        ) : (
          <HeaderRow label='Requested By' value={data?.requestedBy} />
        )}
        <HeaderRow label='LR Number' value={data?.lrNumber} />
        <HeaderRow
          label='Ordered On'
          value={invoiceDateTimeFormat(new Date(data?.orderedOn))}
        />
        <HeaderRow
          label='Collected On'
          value={
            data?.completedOn
              ? invoiceDateTimeFormat(new Date(data?.completedOn))
              : ''
          }
        />
        <HeaderRow
          label='Completed On'
          value={
            data?.validatedOn
              ? invoiceDateTimeFormat(new Date(data?.validatedOn))
              : ''
          }
        />
        <View style={{ width: 180, height: 30, marginLeft: -11 }}>
          <Image src={generateBarcodeImage(data?.lrNumber ?? data?.id)} />
        </View>
      </View>
    </View>
  </>
)

const TableHead = () => (
  <View style={styles.tableHead}>
    <View style={[styles.textView, styles.basis40, styles.noRightBorder]}>
      <Text style={[styles.headLeftText, styles.boldText]}>Parameter Name</Text>
    </View>
    <View style={[styles.textView, styles.basis15, styles.noRightBorder]}>
      <Text style={[styles.headText, styles.boldText]}>Value</Text>
    </View>
    <View style={[styles.textView, styles.basis15, styles.noRightBorder]}>
      <Text style={[styles.headText, styles.boldText]}>Unit</Text>
    </View>
    <View style={[styles.textView, styles.basis30, styles.noRightBorder]}>
      <Text style={[styles.headText, styles.boldText, styles.leftAlign]}>
        Biological-Ref-Range
      </Text>
    </View>
  </View>
)

const TableRow = ({ dt }: { dt: InvoiceTest }) => (
  <View style={styles.tableRow}>
    <View
      style={[
        styles.textView,
        styles.basis40,
        styles.noTopBorder,
        styles.noRightBorder,
      ]}
    >
      <Text style={[styles.smallText, styles.leftAlign]}>{dt?.name}</Text>
      <Text style={[styles.italicText]}>Method: {dt?.method || '-'}</Text>
    </View>
    <View
      style={[
        styles.textView,
        styles.basis15,
        styles.noTopBorder,
        styles.noRightBorder,
      ]}
    >
      <Text
        style={[
          styles.smallText,
          styles.centerAlign,
          isOutsideRange(dt?.value, [dt?.range?.[0], dt?.range?.[1]]) ||
          isAbnormal(dt?.abnormal, dt?.value ?? '')
            ? styles.boldText
            : {},
        ]}
      >
        {getFormattedNumber(dt?.value)}
      </Text>
      {dt?.resultType === numericOutcome && dt?.rangeValue ? (
        <Text style={[styles.smallText, styles.centerAlign]}>
          ({dt?.rangeValue ?? ''})
        </Text>
      ) : null}
      {dt?.extraValue ? (
        <Text style={[styles.smallText, styles.centerAlign]}>
          {dt?.extraValue}
        </Text>
      ) : null}
    </View>
    <View
      style={[
        styles.textView,
        styles.basis15,
        styles.noTopBorder,
        styles.noRightBorder,
      ]}
    >
      <Text style={[styles.smallText, styles.centerAlign]}>
        {dt?.unit || '-'}
      </Text>
    </View>
    <View
      style={[
        styles.textView,
        styles.basis30,
        styles.noTopBorder,
        styles.noRightBorder,
      ]}
    >
      {dt?.resultType === numericOutcome ? (
        dt?.range?.map((rangeValue: any, i: number) => (
          <View key={i} style={styles.flexColumn}>
            <Text>{rangeValue.trim()}</Text>
          </View>
        ))
      ) : (
        <Text style={[styles.smallText, styles.leftAlign]}>
          {dt?.range.join(' - ') || '-'}
        </Text>
      )}
    </View>
  </View>
)

const NormalView = ({ panel }: { panel: InvoicePanel }) => (
  <>
    <View style={panel?.tests?.length > 13 ? styles.marginBottom : {}}>
      <View style={{ marginLeft: 20 }}>
        <Text style={styles.panelText}>
          <Text style={styles.boldText}>Test Name:</Text> {panel?.name}
        </Text>
      </View>
      <View style={{ marginLeft: 20 }}>
        <Text style={styles.panelText}>
          <Text style={styles.boldText}>Sample Type:</Text> {panel?.sample}
        </Text>
      </View>

      <TableHead />
      {panel?.tests?.map((pt) => (
        <TableRow key={pt?.id} dt={pt} />
      ))}
    </View>
  </>
)

type ORProps = {
  title: string
  content: any
  boldContent?: boolean
}

const ObservationRow = ({ title, content }: ORProps) => {
  return (
    <View style={styles.observationRow}>
      <Text style={[styles.observationTitle, styles.boldContent]}>
        {title}:{' '}
      </Text>
      <Text style={[styles.observationContent, styles.normalContent]}>
        {content}
      </Text>
    </View>
  )
}

const ObservationView = ({ panel }: { panel: InvoicePanel }) => {
  const test = panel?.tests?.[0]
  const sample = test?.values?.summary?.replace(/\\+/g, '\\')

  return (
    <View>
      <ObservationRow
        title='Nature of Specimen'
        content={panel?.name}
        boldContent={true}
      />
      {test?.values?.observations?.length > 0 &&
        test?.values?.observations?.map((tvo) => (
          <ObservationRow
            key={tvo?.id}
            title={tvo?.title}
            content={tvo?.content}
          />
        ))}
      <ObservationRow
        title='Summary'
        content={summaryReason(sample)}
        boldContent={true}
      />
    </View>
  )
}
const SignatureView = ({ panel }: { panel: InvoicePanel }) => {
  return (
    <View style={styles.technician}>
      {panel?.tests?.[0]?.enteredName && (
        <View style={styles.technicianContent}>
          <Text style={styles.technicianText}>Technician</Text>
          <Text style={styles.technicianText}>
            {panel?.tests?.[0]?.enteredName}
          </Text>
        </View>
      )}
      <View style={styles.pathologyContainer}>
        {panel?.pathologySignature && (
          <Image
            src={panel?.pathologySignature}
            style={styles.pathologySignature}
          />
        )}
        {panel?.pathologyName && (
          <>
            <Text style={styles.pathologyName}>Dr.{panel?.pathologyName}</Text>
            <Text style={styles.pathologyName}>
              ({panel?.tests?.[0]?.pathologyDepartment})
            </Text>
          </>
        )}
      </View>
    </View>
  )
}

const PanelView = ({
  panel,
  index,
  isLast,
  summary,
  invoiceWithHeaderValue,
}: {
  panel: InvoicePanel
  index: number
  isLast: boolean
  summary: string
  invoiceWithHeaderValue?: boolean
}) => {
  const organization = panel?.tests?.[0]?.organization
  return (
    <View break={index !== 0} style={isLast ? styles.lastPage : {}}>
      <Text style={styles?.departmentHeader}>
        {panel?.department?.department_name?.toUpperCase()}
      </Text>
      {panel?.resultType === 'Observation' ? (
        <ObservationView panel={panel} />
      ) : (
        <NormalView panel={panel} />
      )}
      {panel?.tests?.[0]?.summaryReason && (
        <View style={styles?.interpretation}>
          <Text style={[styles?.impressionTitle, styles?.boldContent]}>
            Impression:
          </Text>
          <Text style={styles?.interpretationValue}>
            {summaryReason(panel?.tests?.[0]?.summaryReason)}
          </Text>
        </View>
      )}
      {organization?.name ? (
        <Text style={styles?.referredNote}>
          Note: Test processed at {organization?.name}
        </Text>
      ) : null}
      {panel?.interpretation && (
        <div style={styles?.interpretation}>
          <Text style={styles.interpretationText}>Interpretation:</Text>
          <Text style={styles.interpretationValue}>
            {panel?.interpretation}
          </Text>
        </div>
      )}
      {panel?.interpretationImage && (
        <View style={styles.interpretationImage}>
          <Image src={panel?.interpretationImage} />
        </View>
      )}
      {isLast && summary ? <InvoiceSummary text={summary} /> : null}
      {panel?.tests?.length === 15 &&
      !panel?.interpretation &&
      !panel?.tests?.[0]?.summaryReason ? (
        <View
          style={invoiceWithHeaderValue ? styles.margin75 : styles.margin60}
        >
          <SignatureView panel={panel} />
        </View>
      ) : (
        <View
          style={
            invoiceWithHeaderValue ? styles.margin25 : styles.signatureContainer
          }
        >
          <SignatureView panel={panel} />
        </View>
      )}
    </View>
  )
}

const InvoiceSummary = ({ text }: { text: string }) => (
  <View style={styles.summary}>
    <Text>
      <Text style={styles.boldText}>Summary:</Text> {text}
    </Text>
  </View>
)

const EndOfReport = ({
  invoiceWithHeaderValue,
}: {
  invoiceWithHeaderValue?: boolean
}) => (
  <>
    <View style={styles.endOfReport}>
      <Text style={{ marginBottom: invoiceWithHeaderValue ? 1 : 30 }}>
        ~~~ End of Report ~~~
      </Text>
    </View>
  </>
)

const InvoiceFooter = () => (
  <View fixed style={styles.footer}>
    <View>
      <Text
        render={() => '1. This Is An Electronically Authenticated Report.'}
        fixed
      />
      <Text>
        2. Please Correlate With Clinical Findings, Consult a Doctor For
        Discussion & Further Medication
      </Text>
    </View>
  </View>
)

const TopLine = () => (
  <View fixed style={styles.container}>
    <View style={styles.headerTopLine} />
    <View style={styles.headerTopLineAfter} />
  </View>
)

const Logo = () => (
  <View style={styles.medLogo} fixed>
    <Image src={medunitedLogo} />
  </View>
)

const HeaderData = ({ data }: { data: InvoiceData }) => (
  <View style={styles.headerData} fixed>
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View style={{ marginRight: 3 }}>
        <Image source={locationIcon} style={styles.logo} />
      </View>
      <Text>Reg. Address: {data?.patientAddress?.address?.line?.[0]}</Text>
    </View>
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ marginRight: 3 }}>
          <Image source={mobileIcon} style={styles.logo} />
        </View>
        <Text>{data?.patientAddress?.telecom?.[0]?.value}</Text>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ marginRight: 2, marginLeft: 3 }}>
          <Image source={emailIcon} style={styles.logo} />
        </View>
        <Text>{data?.patientAddress?.telecom?.[1]?.value}</Text>
      </View>
    </View>
  </View>
)
const PageCount = ({
  invoiceWithHeaderValue,
}: {
  invoiceWithHeaderValue?: boolean
}) => (
  <View
    style={[
      styles.pageCount,
      invoiceWithHeaderValue
        ? { right: 20, bottom: 20 }
        : { left: 20, bottom: 20 },
    ]}
    fixed
  >
    <Text
      render={({ pageNumber, totalPages }) =>
        `Page ${pageNumber} of ${totalPages}`
      }
    />
  </View>
)

const InvoicePdf = ({ data }: Props) => (
  <Document title='Results'>
    <Page
      wrap
      size='A4'
      style={
        data?.invoiceWithHeaderValue ? styles.page : styles.withoutHeaderPage
      }
    >
      {data?.invoiceWithHeaderValue && <TopLine />}
      {data?.invoiceWithHeaderValue && <Logo />}
      {data?.invoiceWithHeaderValue ? (
        <HeaderData data={data} />
      ) : (
        <View fixed style={{ height: '53' }}></View>
      )}

      <InvoiceHeader data={data} />
      {data?.panels?.map((p: InvoicePanel, i: any, arr: any) => (
        <>
          <PanelView
            key={i}
            panel={p}
            index={i}
            isLast={arr.length === i + 1}
            summary={data?.summary}
            invoiceWithHeaderValue={data?.invoiceWithHeaderValue}
          />
        </>
      ))}
      {<EndOfReport invoiceWithHeaderValue={data.invoiceWithHeaderValue} />}
      <PageCount invoiceWithHeaderValue={data.invoiceWithHeaderValue} />
      {data.invoiceWithHeaderValue ? <InvoiceFooter /> : null}
    </Page>
  </Document>
)

export default InvoicePdf
