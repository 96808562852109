import React, { useCallback, useEffect, useRef } from 'react'
import { Tabs, Tab } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'

const styles = () => ({
  ipdTabsContainer: {
    width: '88vw',
    padding: '0px 0px 0px 10px',
  },
  finished: {
    backgroundColor: 'grey !important',
    color: 'white !important',
    padding: '0.3rem',
    borderRadius: '5px',
  },
})

const DateofIpdAppointments = (props: any) => {
  const {
    visitDates,
    dateHandler,
    activeDate,
    allPractitioners,
    visitType,
    selectedMenu,
  } = props
  const navigate = useNavigate()
  const tabsRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const handleDateClick = useCallback(
    (d: any, i: number) => {
      dateHandler(d, i)
      if (visitType === false) {
        const routePath = `/ipdVisit/visitDetails/${d.resource.id}/${selectedMenu}`
        navigate(routePath)
      }
      if (visitType === true) {
        const routePath = `/visits/ipdVisit/${d.resource.id}/${selectedMenu}`
        navigate(routePath)
      }
    },
    [dateHandler, navigate, visitType, selectedMenu]
  )

  const getPractitionerName = (practitionerId: string) => {
    const practitioner = allPractitioners.find(
      (prac: any) => prac?.resource?.id === practitionerId
    )
    return practitioner
      ? `${practitioner?.resource?.name?.[0]?.given?.join(' ')} ${
          practitioner?.resource?.name?.[0]?.family
        }`
      : 'Unknown'
  }

  const timeFormat = (time: any) => {
    const date = new Date(time)
    if (isNaN(date.getTime())) {
      return 'Invalid date'
    }
    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'long',
      timeZone: 'Asia/Calcutta',
    }).format(date)
  }

  const sortedVisitDates = visitDates?.slice().sort((a: any, b: any) => {
    const aStartDate = b?.resource?.id
    const bStartDate = a?.resource?.id
    return aStartDate - bStartDate
  })

  useEffect(() => {
    if (tabsRef?.current) {
      const selectedTab = tabsRef?.current?.querySelector(
        `.MuiTab-root[data-index="${activeDate}"]`
      ) as HTMLElement | null
      if (selectedTab) {
        tabsRef.current.scrollLeft = selectedTab?.offsetLeft || 0
      }
    }
  }, [activeDate])

  return (
    <div className={classes?.ipdTabsContainer}>
      <Tabs
        ref={tabsRef}
        variant='scrollable'
        scrollButtons='auto'
        value={activeDate}
        onChange={(event, newValue) =>
          handleDateClick(sortedVisitDates[newValue], newValue)
        }
        sx={{
          '.MuiButtonBase-root': {
            textTransform: 'capitalize',
          },
          '&.MuiTabs-root .MuiTab-root': {
            fontSize: '12px',
            fontWeight: '500',
          },
          '&.MuiTabs-root .Mui-selected': {
            color: '#183f7c',
            fontWeight: '600',
            fontSize: '12px',
          },
          '&.MuiTabs-root .MuiTabs-indicator': { backgroundColor: '#183f7c' },
        }}
      >
        {sortedVisitDates?.map((d: any, i: any) => {
          const startDate = d?.resource?.period?.start
          const status = d?.resource?.statusHistory?.[0]?.status
          const practitioner = getPractitionerName(
            d?.resource?.participant?.[0]?.individual?.reference.split('/')?.[1]
          )

          return (
            <Tab
              wrapped
              key={i}
              label={
                <div
                  className={`${activeDate === i ? 'activeDateTab' : ''} ${
                    status === 'finished' ? classes?.finished : ''
                  } ${
                    status === 'finished' && activeDate === i
                      ? `activeDateTab ${classes?.finished}`
                      : ''
                  }`}
                  onClick={() => handleDateClick(d, i)}
                >
                  {startDate ? timeFormat(startDate) : 'Date not available'}
                  <br />
                  {practitioner}
                </div>
              }
            />
          )
        })}
      </Tabs>
    </div>
  )
}

export default DateofIpdAppointments
