import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CreateButton from '../../components/CreateButton'
import EditButton from '../../components/EditButton'
import { startSxpProxy } from '../../utils/api'
import { ADMIN_PROJECT_ID } from '../../utils/constants'
import {
  create,
  forms,
  OrganizationIntent,
  PartnerTable,
  update,
} from '../administration/constants'
import ProvenanceView from '../../components/ProvenanceView'

const Organization = () => {
  const [partnerData, setPartnerData] = useState<[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const handleCreate = () => {
    navigate('create')
  }

  const getAllOrganizations = () => {
    setLoading(true)
    startSxpProxy(
      ADMIN_PROJECT_ID,
      OrganizationIntent?.getAllOrganizationForAdmin,
      {}
    )
      ?.then((data: any) => {
        const organization = data?.data?.organization
        if (organization?.length > 0) {
          setPartnerData(organization)
        } else {
          setPartnerData([])
        }
        setLoading(false)
      })
      .catch((error) => {
        setPartnerData([])
        setLoading(false)
      })
  }
  useEffect(() => {
    getAllOrganizations()
  }, [refresh])
  const handleEdit = (id: any) => {
    navigate(`edit/${id}`)
  }
  const filteredPartnerData = partnerData?.filter((partner: any) =>
    partner?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
  )
  return (
    <>
      <div>
        <div className='button-enclosure border-none mb9 flexible'>
          <div>
            <span className='membershipText'>{PartnerTable?.Partner}</span>
          </div>
          <div>
            <input
              type='search'
              className='admin-search'
              value={searchText}
              onChange={({ target: { value } }) => setSearchText(value)}
              placeholder='Search by Name'
            />
            <CreateButton title={'Create'} onClick={handleCreate} />
          </div>
        </div>
        <div>
          <table className='data-table table-fixed admin-table relative'>
            <thead className='admin-table-head sticky'>
              <tr>
                <th className='table-w-5'>{PartnerTable?.SerialNo}</th>
                <th className='table-w-10'>{PartnerTable?.Partner_Type}</th>
                <th className='table-w-15'>{PartnerTable?.Partner_Name}</th>
                <th className='table-w-10'>{PartnerTable?.PhoneNumber}</th>
                <th className='table-w-5'>{PartnerTable?.Status}</th>
                <th className='table-w-8'>{PartnerTable?.CreatedBy}</th>
                <th className='table-w-8'>{PartnerTable?.UpdatedBy}</th>
                <th className='table-w-5'>{PartnerTable?.Action}</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6}>{forms?.loading}</td>
                </tr>
              ) : partnerData?.length === 0 ? (
                <tr>
                  <td colSpan={6}>{PartnerTable?.apierrorMsg}</td>
                </tr>
              ) : (
                filteredPartnerData?.map((e: any, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{e?.type ?? '-'}</td>
                    <td>{e?.name ?? '-'}</td>
                    <td>{e?.mobile_number ?? '-'}</td>
                    <td>{e?.status ?? '-'}</td>
                    <td className='text-center'>
                      <ProvenanceView
                        date={e?.date_created}
                        name={e?.created_by}
                        mode={create}
                      />
                    </td>
                    <td className='text-center'>
                      <ProvenanceView
                        date={e?.date_changed}
                        name={e?.updated_by}
                        mode={update}
                      />
                    </td>
                    <td className='edit-relation'>
                      <EditButton
                        title='Edit Doctor'
                        onEdit={() => handleEdit(e?.id)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default Organization
