import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import {
  fetchPatientsAsync,
  setPatientSearchQueryValue,
} from '../patients/patientsSlice'
import SelectableSearch from './selectedtableseacrh'

const OPDSearch = ({
  onSearch,
  onCreateVisit,
  thopId,
}: {
  onSearch?: (query: string, param: string) => void
  onCreateVisit: () => void
  thopId: any
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const handleSearch = (query: string, param: string) => {
    let q = ''
    switch (query) {
      case 'ID':
        q = 'identifier'
        break
    }
    dispatch(setPatientSearchQueryValue({ query: q, value: param }))
    dispatch(fetchPatientsAsync())
    onSearch?.(q, param)
  }

  return (
    <div className='patient-filter-btn'>
      <div className='patient-search pb10'>
        <SelectableSearch
          items={['ID']}
          onSearch={handleSearch}
          thopId={thopId}
        />
      </div>
      {location.pathname === '/opd' && (
        <div className='create-visit-btn'>
          <button className='btn btn-primaryBtn' onClick={onCreateVisit}>
            Create Visit
          </button>
        </div>
      )}
    </div>
  )
}

export default OPDSearch
