import { useEffect, useRef, useState } from 'react'
import { startSxpProxy } from '../../../../utils/api'
import { useAppSelector } from '../../../../app/hooks'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import {
  LmsOrder,
  ResultCount,
  active,
  dateFormat,
  exportsText,
  intentLab,
  locationValues,
  tableSummaryHeadText,
} from '../../models'
import {
  exportToCsv,
  getLabOrdersByLocation,
  mapPatients,
  mapResults,
} from '../../utils'
import { fullDateTimeFormat, isoDateFormat } from '../../../../utils/dateUtils'
import PatientId from '../dashboard/PatientId'
import {
  emptyString,
  noOrdersFound,
  statuses,
} from '../../../Radiology/constants'
import { selectLocations } from '../../../location/locationSlice'
import add from 'date-fns/add'
const CollectedRow = ({
  item,
  index,
  results,
}: {
  item: LmsOrder
  index: number
  results: Record<string, ResultCount>
}) => {
  const res = results[item.id as string]
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{item.lr_number ?? '-'}</td>
      <td>
        <PatientId order={item} name={item.patientUhid ?? item.patientThopId} />
      </td>
      <td className='patient-name'>{item.patientName}</td>
      <td>{item.patientAge}</td>
      <td className='text-center'>{item.patientGender}</td>
      <td>{item.patientMobile}</td>
      <td className='text-center'>{item.source}</td>
      <td className='text-center'>{item?.origin}</td>
      <td title={item.ordered_on}>
        {fullDateTimeFormat(new Date(item.ordered_on ?? ''))}
      </td>
      <td>
        {item.collection_completed_on
          ? fullDateTimeFormat(new Date(item.collection_completed_on ?? ''))
          : '-'}
      </td>
      <td>
        {item.status === statuses.completed
          ? fullDateTimeFormat(new Date(item.validation_completed_on ?? ''))
          : '-'}
      </td>
      <td>{item.requested_by}</td>
      <td>-</td>
      <td>{res?.total ?? 0}</td>
      <td>{res?.total ? res?.total - (res?.pending ?? 0) : 0}</td>
      <td>{res?.pending ?? 0}</td>
    </tr>
  )
}
const headers = [
  'sl No',
  'UHID',
  'Name',
  'Age',
  'Gender',
  'Mobile No.',
  'Operating Unit',
  'OrderType',
  'Lr Number',
  'Ordered On',
  'Collected On',
  'Completed On',
  'Requested By',
  'Handled At',
  'Total Tests',
  'Completed',
  'Pending',
]

const LmsSummaryReport = () => {
  const [location, setLocation] = useState('Amalapuram')
  const [startDate, setStartDate] = useState(getDefaultStartDate)
  const [endDate, setEndDate] = useState(getDefaultEndDate)
  const [orders, setOrders] = useState<LmsOrder[]>([])
  const [results, setResults] = useState<Record<string, ResultCount>>({})
  const locations = useAppSelector(selectLocations)
  const filteredLocations = locations
    ?.filter((l) => l.resource.status === active)
    ?.map((e) => e.resource.name)

  function getDefaultStartDate() {
    const today = new Date()
    today.setDate(today.getDate() - 30)
    return today.toISOString().split('T')[0]
  }

  function getDefaultEndDate() {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate())
    return tomorrow.toISOString().split('T')[0]
  }
  const handleDateChange = (e: any) => {
    const { name, value } = e.target
    if (name === 'startDate') {
      setStartDate(value)
      if (new Date(value) > new Date(endDate)) {
        setEndDate(value)
      }
    } else if (name === 'endDate') {
      setEndDate(value)
      if (new Date(value) < new Date(startDate)) {
        setStartDate(value)
      }
    }
  }
  useEffect(() => {
    startSxpProxy(LABS_PROJECT_ID, intentLab.getLabOrdersByOrderedDate, {
      startDate: startDate,
      endDate: isoDateFormat(add(new Date(endDate), { days: 1 })),
    })
      .then(async (data) => {
        const nodes: LmsOrder[] = data.data?.lab_orders ?? []
        if (nodes.length) {
          const mapped = await mapPatients(nodes)
          setOrders(mapped)
          const orderIds = nodes.map((n) => n.id)
          if (orderIds.length) {
            const rs = await mapResults(orderIds)
            setResults(rs)
          }
        } else {
          setOrders([])
        }
      })
      .catch(() => {
        setOrders([])
        setResults({})
      })
  }, [startDate, endDate])
  const collectedTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = collectedTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 35}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const filtered = getLabOrdersByLocation(orders, location)
  const handleDownload = async () => {
    const order = orders.map((d: LmsOrder, i: number) => {
      const slNo = i + 1
      const patientMobile = d.patientMobile
      const patientAge = d.patientAge
      const gender = d.patientGender
      const source = d.source
      const origin = d.origin
      const lrNumber = d.lr_number ? JSON.stringify(d.lr_number) : '-'
      const orderedOn = JSON.stringify(
        fullDateTimeFormat(new Date(d.ordered_on ?? ''))
      )
      const requestedBy = d.requested_by
      const handledAt = '-'
      const res = results[d.id as string]
      const total = res?.total ?? 0
      const pending = res?.pending ?? 0
      const patientUhid = d.patientUhid ?? d.patientThopId
      const name = d.patientName
      const collected = d.collection_completed_on
        ? JSON.stringify(
            fullDateTimeFormat(new Date(d.collection_completed_on ?? ''))
          )
        : '-'
      const completed =
        d.status === statuses.completed
          ? JSON.stringify(
              fullDateTimeFormat(new Date(d.validation_completed_on ?? ''))
            )
          : '-'
      const completedCount = res?.total ? res?.total - (res?.pending ?? 0) : 0
      return {
        slNo,
        patientUhid,
        name,
        patientAge,
        gender,
        patientMobile,
        source,
        origin,
        lrNumber,
        orderedOn,
        collected,
        completed,
        requestedBy,
        handledAt,
        total,
        completedCount,
        pending,
      }
    })
    await exportToCsv(headers, order, 'lab')
  }

  return (
    <>
      <div className='overall-summary'>
        <div className='summary'>
          <button onClick={handleDownload} className='downloadSummary'>
            {exportsText}
          </button>
          <select
            className='admin-select'
            name='location'
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            {filteredLocations?.map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
            <option value={emptyString}>{locationValues.All}</option>
          </select>
          <div>
            <label htmlFor='startDate' className='start-date'>
              {dateFormat.startDate}
            </label>
            <input
              className='datePickerUI'
              type='date'
              id='startDate'
              name='startDate'
              value={startDate}
              onChange={handleDateChange}
            />
          </div>
          <div>
            <label htmlFor='endDate' className='start-date'>
              {dateFormat.endDate}
            </label>
            <input
              className='datePickerUI'
              type='date'
              id='endDate'
              name='endDate'
              value={endDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div
          className='data-table table-fixed admin-table-table-container'
          ref={collectedTableContainerRef}
          style={{ overflowY: 'auto' }}
        >
          <table className='data-table table-fixed admin-table relative w120'>
            <thead className='position-top'>
              <tr>
                <th className='table w3'>{tableSummaryHeadText.slNo}</th>
                <th className='text-center w10'>LR.Number</th>
                <th className='text-left w8'>UHID</th>
                <th>{tableSummaryHeadText.name}</th>
                <th className='text-center w4'>{tableSummaryHeadText.age}</th>
                <th className='w4'>{tableSummaryHeadText.gender}</th>
                <th className='text-left w6'>{tableSummaryHeadText.mobile}</th>
                <th className='text-center w7'>Operating Unit</th>
                <th className='text-center w6'>Service</th>
                <th>{tableSummaryHeadText.orderedOn}</th>
                <th>{tableSummaryHeadText.collectedOn}</th>
                <th>{tableSummaryHeadText.completedOn}</th>
                <th>{tableSummaryHeadText.requestedBy}</th>
                <th className='table w4'>{tableSummaryHeadText.handledAt}</th>
                <th className='table-w-3'>{tableSummaryHeadText.total}</th>
                <th className='table w5'>{tableSummaryHeadText.completed}</th>
                <th className='table w4'>{tableSummaryHeadText.pending}</th>
              </tr>
            </thead>
            <tbody>
              {filtered.length > 0 ? (
                filtered.map((item, index) => (
                  <CollectedRow
                    key={item.id}
                    item={item}
                    index={index}
                    results={results}
                  />
                ))
              ) : (
                <tr>
                  <td className='text-center' colSpan={14}>
                    {noOrdersFound}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default LmsSummaryReport
