import { useTheme } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import MedAgDataGrid from '../../components/MedAgDataGrid'
import { ColDef, INumberFilterParams } from 'ag-grid-community'
import AmbulanceForm from './AmbulanceForm'
import CloseIcon from '@mui/icons-material/Close'
import { startSxpProxy } from '../../utils/api'
import { IPD_PROJECT_ID } from '../../utils/constants'
import {
  DeviceDetails,
  INTENT_DELETE_DEVICE,
  INTENT_GET_DEVICES,
} from './Constants'
import { toast } from 'react-toastify'

const styles = () => ({
  proRithmDasboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '2%',
    gap: 20,
  },
  proRithmDasboardTableContainer: {
    width: '100%',
    height: '100%',
  },
  proRithmDasboardheading: {
    color: '#090968',
    fontSize: '16px',
    fontWeight: 600,
  },
  proRithmDasboardCreateBtn: {
    textTransform: 'capitalize',
    backgroundColor: '#4682b4',
  },
  deviceText: {
    color: '#090968',
    fontSize: '16px',
    fontWeight: 600,
  },
  centerHeader: {
    color: '#ffffff',
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
})

const ProRithmDasboard = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const [devices, setDevices] = useState<DeviceDetails[]>([])
  const [selectedDevice, setSelectedDevice] = useState<DeviceDetails>({
    ambulanceIdentifier: '',
    ambulanceNumber: '',
    emtPhone: '',
    driverPhone: '',
    proDeviceId: '',
    ambulanceStatus: '',
    mapId: null,
    totalCount: null,
    id: null,
  })
  const [refresh, setRefresh] = useState(false)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)

  const [open, setOpen] = useState(false)

  const [openDelete, setOpenDelete] = useState(false)

  const handleCloseDelete = () => {
    setSelectedDevice({
      ambulanceIdentifier: '',
      ambulanceNumber: '',
      emtPhone: '',
      driverPhone: '',
      proDeviceId: '',
      ambulanceStatus: '',
      mapId: null,
      totalCount: null,
      id: null,
    })
    setOpenDelete(false)
  }

  const handleOpenDelete = (params: any) => {
    setSelectedDevice(params?.data)
    setOpenDelete(true)
  }

  const RenderActions = (params: any) => {
    return (
      <>
        <IconButton size='small' onClick={() => handleEdit(params)}>
          <ModeEditOutlinedIcon fontSize='small' />
        </IconButton>
        <IconButton size='small' onClick={() => handleOpenDelete(params)}>
          <DeleteOutlineOutlinedIcon fontSize='small' />
        </IconButton>
      </>
    )
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'S No',
      field: 'id',
      width: 80,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'Pro Device ID',
      field: 'proDeviceId',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Ambulance Identifier',
      field: 'ambulanceIdentifier',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Ambulance Number',
      field: 'ambulanceNumber',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'EMT Phone',
      field: 'emtPhone',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Driver Phone',
      field: 'driverPhone',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },

    {
      headerName: 'Status',
      field: 'ambulanceStatus',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: RenderActions,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
  ]

  const FilterActions = () => {
    return (
      <Button
        variant='contained'
        size='small'
        className={classes?.proRithmDasboardCreateBtn}
        onClick={() => setOpen(true)}
      >
        Create
      </Button>
    )
  }

  const handleEdit = (params: any) => {
    setSelectedDevice(params?.data)
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedDevice({
      ambulanceIdentifier: '',
      ambulanceNumber: '',
      emtPhone: '',
      driverPhone: '',
      proDeviceId: '',
      ambulanceStatus: '',
      mapId: null,
      totalCount: null,
      id: null,
    })
    setOpen(false)
  }

  const getDevices = () => {
    const intent = INTENT_GET_DEVICES
    const state = { limit: pageSize, offset: page }
    startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        const formattedData = data?.data?.data?.deviceDetails?.map(
          (item: any, index: number) => {
            return {
              ...item,
              id: index + 1,
            }
          }
        )

        setDevices(formattedData)
        setTotalCount(data?.data?.data?.totalCount)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const deleteDevice = (id: number | null | undefined) => {
    const intent = INTENT_DELETE_DEVICE
    const state = { mapId: id }
    startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        setRefresh((prevState: boolean) => !prevState)
        handleCloseDelete()
        toast.success(data?.data?.message)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        console.log(err)
      })
  }

  useEffect(() => {
    getDevices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, refresh])

  return (
    <Box height={'93%'} width={'100%'}>
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Delete Device</DialogTitle>
        <IconButton
          size='small'
          aria-label='close'
          onClick={handleCloseDelete}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        <DialogContent>
          Are you sure you want to delete device{' '}
          <span className={classes?.deviceText}>
            {selectedDevice?.proDeviceId}
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            variant='contained'
            className={classes?.proRithmDasboardCreateBtn}
            onClick={() => deleteDevice(selectedDevice?.mapId)}
          >
            Delete
          </Button>
          <Button
            size='small'
            variant='contained'
            className={classes?.proRithmDasboardCreateBtn}
            onClick={handleCloseDelete}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Device Form</DialogTitle>
        <IconButton
          size='small'
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        <DialogContent>
          <AmbulanceForm
            selectedDevice={selectedDevice}
            setOpen={setOpen}
            setRefresh={setRefresh}
          />
        </DialogContent>
      </Dialog>
      <Box
        className={classes?.proRithmDasboardContainer}
        height={'100%'}
        width={'100%'}
      >
        <Box
          className={classes?.proRithmDasboardTableContainer}
          height={'100%'}
          width={'100%'}
        >
          <MedAgDataGrid
            tableHeading='ProRithm'
            tableHeight={'100%'}
            rowData={devices}
            columnDefs={columnDefs}
            pagination={true}
            pageSizeOptions={[10, 25, 50, 100]}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalRows={totalCount}
            headerHeight={30}
            filterActions={<FilterActions />}
            searchFieldWidth={200}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ProRithmDasboard
