import React, { useEffect, useState } from 'react'
import { CarePlan, removeCarePlanWithPatientId } from './CarePlanSlice'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
  Box,
  TextField,
  DialogActions,
  Button,
  Autocomplete,
} from '@mui/material'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import CloseIcon from '@mui/icons-material/Close'
import { capitalizeFirstLetter } from '../patients/utils'
import MedAgDataGrid from '../../components/MedAgDataGrid'
import { ColDef } from 'ag-grid-community'
import { formatReadableDate } from '../bedManagement/IpdVisit/IpdSummary/Constants'
import {
  DATA_STATUS,
  DECISION_ACCEPT,
  DECISION_REJECT,
  FROM_NOTIFICATION,
  INTENT_UPDATE_VISIT,
  MESSAGE_TYPES,
  NOTIFICATION_STATUS,
  STATUS,
  TOAST_WARNING_REASON,
} from './Constants'
import { startSxpProxy } from '../../utils/api'
import { IPD_PROJECT_ID } from '../../utils/constants'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { fetchSlotsDoctorsAsync, selectSlotsDoctors } from '../slots/slotsSlice'
import Asterisk from '../../components/Asterisk'

const styles = (theme: any) => ({
  dialogCloseIconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  centerHeader: {
    color: '#ffffff',
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  heading: {
    color: '#090968',
    fontSize: '16px',
    fontWeight: 600,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
  },
  modalActions: { justifyContent: 'center' },
  cardBtn: {
    textTransform: 'capitalize',
    // padding: 'unset',
  },
  disableField: {
    pointerEvents: 'none',
  },
})

const CarePlanModal = ({
  openModal,
  handleCloseModal,
  selectedNotification,
  sendData,
}: {
  openModal: boolean
  handleCloseModal: () => void
  selectedNotification: CarePlan | undefined
  sendData: (payload: Record<string, any>) => void
}) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const dispatch = useAppDispatch()
  const [reason, setReason] = useState('')
  const [doctorName, setDoctorName] = useState('')
  const doctors = useAppSelector(selectSlotsDoctors)

  const RenderDosage = (params: any) => {
    return (
      <>
        {params?.data?.dose}({params?.data?.dose_units})
      </>
    )
  }

  const RenderDuration = (params: any) => {
    return (
      <>
        {params?.data?.duration}({params?.data?.duration_units})
      </>
    )
  }

  const medicationcolumnDefs: ColDef[] = [
    {
      headerName: 'S No',
      field: 'id',
      flex: 1,
      minWidth: 80,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      valueGetter: (params: any) => {
        return params?.node?.rowIndex + 1
      },
    },
    {
      headerName: 'Medicine',
      field: 'medicine',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      valueGetter: (params) => {
        return params?.data?.drug_name
      },
    },
    {
      headerName: 'Dosage',
      field: 'dosage',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      cellRenderer: RenderDosage,
    },
    {
      headerName: 'Duration(units)',
      field: 'duration',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      cellRenderer: RenderDuration,
    },
    {
      headerName: 'Route',
      field: 'route',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      valueGetter: (params) => {
        return capitalizeFirstLetter(params?.data?.route)
      },
    },
    {
      headerName: 'Frequency',
      field: 'frequency',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      valueGetter: (params) => {
        return params?.data?.frequency
      },
    },
  ]

  const labTestcolumnDefs: ColDef[] = [
    {
      headerName: 'S No',
      field: 'id',
      flex: 1,
      minWidth: 80,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      valueGetter: (params: any) => {
        return params?.node?.rowIndex + 1
      },
    },
    {
      headerName: 'Test',
      field: 'test',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      valueGetter: (params) => {
        return params?.data?.name
      },
    },
    {
      headerName: 'Doctor',
      field: 'doctor',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      valueGetter: () => {
        return 'Intensivist Command Centre'
      },
    },
    {
      headerName: 'Date & Time',
      field: 'dateTime',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      valueGetter: () => {
        return formatReadableDate(selectedNotification?.timestamp || '')
      },
    },
  ]

  const handleAcceptRejectSubmit = async (selectedDecision: string) => {
    if (selectedDecision === DECISION_REJECT && !reason) {
      toast.warning(TOAST_WARNING_REASON)
    } else {
      const intent = INTENT_UPDATE_VISIT
      const acceptState = {
        status: STATUS?.FINISHED,
        encounterId: selectedNotification?.encounterId,
        visit_id: selectedNotification?.visitId,
        fromNotification: FROM_NOTIFICATION,
        encounterStatus: STATUS?.FINISHED,
        data_status: DATA_STATUS?.PROCESSED,
        notificationStatus: NOTIFICATION_STATUS?.ACCEPTED,
        reason: reason,
        doctor_name: doctorName,
      }
      const rejectState = {
        status: STATUS?.CANCELLED,
        encounterId: selectedNotification?.encounterId,
        visit_id: selectedNotification?.visitId,
        fromNotification: FROM_NOTIFICATION,
        encounterStatus: STATUS?.CANCELLED,
        data_status: DATA_STATUS?.REJECTED,
        notificationStatus: NOTIFICATION_STATUS.REJECTED,
        reason: reason,
        doctor_name: doctorName,
      }
      await startSxpProxy(
        IPD_PROJECT_ID,
        intent,
        selectedDecision === DECISION_ACCEPT ? acceptState : rejectState
      )
        .then((data) => {
          if (data?.data?.success) {
            sendData({
              messageType:
                selectedDecision === DECISION_ACCEPT
                  ? MESSAGE_TYPES?.ACCEPT_CARE_PLAN
                  : MESSAGE_TYPES?.REJECT_CARE_PLAN,
              patientID: selectedNotification?.patientID,
              patientLocation: selectedNotification?.patientLocation,
              message:
                selectedDecision === DECISION_ACCEPT
                  ? 'CarePlan Accepted'
                  : 'CarePlan Rejected',
              reason: reason,
            })
            dispatch(
              removeCarePlanWithPatientId(selectedNotification?.patientID || '')
            )
            toast.success(data?.data?.message)
            handleCloseModal()
          } else {
            toast.error(data?.data?.message)
          }
        })
        .catch((err) => {
          console.error(err, 'err')
          toast.error(err?.response?.data?.message)
        })
    }
  }

  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
  }, [dispatch])

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth
      maxWidth={false}
    >
      <DialogTitle m={0} p={2} id='customized-dialog-title'>
        {selectedNotification?.patientID} - {selectedNotification?.messageType}
      </DialogTitle>
      <IconButton
        size='small'
        aria-label='close'
        onClick={handleCloseModal}
        className={classes?.dialogCloseIconBtn}
        id='close-notification-modal'
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box className={classes?.modalContainer}>
          <Box height={'30vh'}>
            <MedAgDataGrid
              tableHeading='Medications'
              tableHeight={'100%'}
              rowData={selectedNotification?.medicine_suggestions || []}
              columnDefs={medicationcolumnDefs}
              pagination={false}
              searchField={false}
              exportIcon={false}
              headerHeight={30}
              rowHeight={30}
            />
          </Box>
          <Box height={'30vh'}>
            <MedAgDataGrid
              tableHeading='Lab Tests'
              tableHeight={'100%'}
              rowData={selectedNotification?.panels || []}
              columnDefs={labTestcolumnDefs}
              pagination={false}
              searchField={false}
              exportIcon={false}
              headerHeight={30}
              rowHeight={30}
            />
          </Box>

          <Box className={classes?.container}>
            <Box className={classes?.heading}>Suggested Doctor</Box>
            <TextField
              size='small'
              id='treatmentPlan-notification-doctor'
              value={selectedNotification?.suggestedDoctor}
              className={classes?.disableField}
              fullWidth
            />
          </Box>

          <Box className={classes?.container}>
            <Box className={classes?.heading}>Treatment Plan</Box>
            <TextField
              id='treatmentPlan-notification'
              value={selectedNotification?.treatment_plan}
              multiline
              rows={4}
              className={classes?.disableField}
              fullWidth
            />
          </Box>

          <Box className={classes?.container}>
            <Box className={classes?.heading}>
              Doctor
              <Asterisk />
            </Box>
            <Autocomplete
              id='doctor-notification'
              options={doctors}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value: any) => {
                setDoctorName(value?.name || null)
              }}
              renderInput={(params) => (
                <TextField {...params} size='small' name='doctorName' />
              )}
            />
          </Box>

          <Box className={classes?.container}>
            <Box className={classes?.heading}>Reason</Box>
            <TextField
              id='reason-notification'
              name='reason'
              value={reason}
              multiline
              rows={4}
              fullWidth
              onChange={(e) => setReason(e.target.value)}
              placeholder='Reason'
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes?.modalActions}>
        <Button
          id='accept-notification'
          variant='contained'
          size='small'
          color='success'
          className={classes?.cardBtn}
          onClick={() => handleAcceptRejectSubmit(DECISION_ACCEPT)}
          disabled={!doctorName}
        >
          Accept
        </Button>
        <Button
          id='reject-notification'
          variant='contained'
          size='small'
          color='error'
          className={classes?.cardBtn}
          onClick={() => handleAcceptRejectSubmit(DECISION_REJECT)}
          disabled={!doctorName}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CarePlanModal
