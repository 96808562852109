import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Rating,
  TextField,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import { startSxpProxy } from '../../utils/api'
import { toast } from 'react-toastify'
import { IPD_PROJECT_ID } from '../../utils/constants'

interface Rating {
  deviceName: string
  rating: number | null
  feedback: string
}

const styles = () => ({
  deviceRatingContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    gap: 30,
    width: '100%',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
    },
  },
  deviceRatingFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 15,
    width: '100%',
    border: '1px solid #e6e6e6',
    borderRadius: 8,
    padding: '1rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  deviceRatingField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: '48%',
  },
  deviceRatingFieldText: {
    fontSize: '12px',
    fontWeight: 600,
  },
  deviceRatingSubmitBtn: {
    backgroundColor: '#4682b4',
    textTransform: 'capitalize',
  },
  deviceFeedbackField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: '100%',
  },
  deviceRatingActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deviceRatingFormDisable: {
    pointerEvents: 'none',
  },
})

const DeviceRatingForm = ({
  openRating,
  handleCloseRating,
  encounterId,
  patientId,
}: any) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [deviceRatings, setDeviceRatings] = useState<Rating[]>([
    {
      deviceName: '',
      rating: 0,
      feedback: '',
    },
  ])

  const [deviceList] = useState([
    {
      label: 'ProRithm',
      value: 'prorithm',
    },
    {
      label: 'Ring',
      value: 'ring',
    },
    {
      label: 'Pheezeee',
      value: 'pheezeee',
    },
    {
      label: 'Ayusynk',
      value: 'ayusynk',
    },
    {
      label: 'Gynae',
      value: 'gynae',
    },
    {
      label: 'Mobilab',
      value: 'mobilab',
    },
  ])

  const [submit, setSubmit] = useState(false)

  const handleFieldChange = (index: number, e: any) => {
    const { name, value } = e.target
    setDeviceRatings((prevRatings) => {
      const updatedRatings = [...prevRatings]
      updatedRatings[index] = { ...updatedRatings[index], [name]: value }
      return updatedRatings
    })
  }

  const handleRatingChange = (index: number, newValue: number | null) => {
    setDeviceRatings((prevRatings) => {
      const updatedRatings = [...prevRatings]
      updatedRatings[index].rating = newValue || 0
      return updatedRatings
    })
  }

  const addRatingFields = () => {
    setDeviceRatings((prevRatings) => [
      ...prevRatings,
      { deviceName: '', rating: 0, feedback: '' },
    ])
  }

  const deleteRatingField = (index: number) => {
    setDeviceRatings((prevRatings) => {
      if (prevRatings?.length === 1) {
        return prevRatings
      }

      return prevRatings.filter((_, i) => i !== index)
    })
  }

  const submitRatings = () => {
    const intent = 'devicesFeedback'
    const state = {
      encounterId: encounterId,
      patientId: patientId,
      deviceFeedback: deviceRatings,
    }
    startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        handleCloseRating()
        toast.success(data?.data?.message)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        console.log(err)
      })
  }

  const getDeviceFeedbacks = () => {
    const intent = 'getFeedback'
    const state = {
      encounterId: encounterId,
      patientId: patientId,
    }
    startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.data?.devicesFeedback?.length >= 1) {
          setDeviceRatings(data?.data?.data?.devicesFeedback)
          setSubmit(true)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        console.log(err)
      })
  }

  useEffect(() => {
    getDeviceFeedbacks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Dialog open={openRating} onClose={handleCloseRating} fullWidth>
        <DialogTitle>Device Rating</DialogTitle>
        <IconButton
          size='small'
          aria-label='close'
          onClick={handleCloseRating}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        <DialogContent>
          <div
            className={`${classes?.deviceRatingContainer} ${
              submit ? classes?.deviceRatingFormDisable : ''
            }`}
          >
            {deviceRatings?.map((rating, index) => (
              <div key={index} className={classes?.deviceRatingFieldContainer}>
                <IconButton
                  size='small'
                  aria-label='close'
                  onClick={() => deleteRatingField(index)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
                <div className={classes?.deviceRatingField}>
                  <label className={classes?.deviceRatingFieldText}>
                    Device
                  </label>
                  <TextField
                    size='small'
                    select
                    name='deviceName'
                    value={rating.deviceName}
                    onChange={(e) => handleFieldChange(index, e)}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                      },
                    }}
                  >
                    {deviceList?.map(
                      (item: { label: string; value: string }, index) => (
                        <MenuItem
                          key={index}
                          value={item?.value}
                          disabled={deviceRatings?.some(
                            (rating) => rating?.deviceName === item?.value
                          )}
                        >
                          {item?.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </div>
                <div className={classes?.deviceRatingField}>
                  <label className={classes?.deviceRatingFieldText}>
                    Rating
                  </label>
                  <Rating
                    sx={{ fontSize: '2.5vw' }}
                    size='large'
                    name='rating'
                    value={rating.rating}
                    precision={0.5}
                    onChange={(event, newValue) =>
                      handleRatingChange(index, newValue)
                    }
                    disabled={!rating.deviceName}
                  />
                </div>
                <div className={classes?.deviceFeedbackField}>
                  <label className={classes?.deviceRatingFieldText}>
                    Feedback
                  </label>
                  <TextField
                    multiline
                    rows={6}
                    size='small'
                    name='feedback'
                    placeholder='Feedback'
                    value={rating.feedback}
                    onChange={(e) => handleFieldChange(index, e)}
                    disabled={!rating.deviceName}
                  />
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions className={classes?.deviceRatingActionsContainer}>
          <Button
            size='small'
            variant='contained'
            className={classes?.deviceRatingSubmitBtn}
            onClick={addRatingFields}
            startIcon={<AddCircleIcon fontSize='small' />}
            disabled={submit}
          >
            Add
          </Button>
          <Button
            size='small'
            variant='contained'
            className={classes?.deviceRatingSubmitBtn}
            onClick={submitRatings}
            endIcon={<CheckCircleIcon fontSize='small' />}
            disabled={submit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeviceRatingForm
