import React, { useEffect, useState, useRef, useMemo } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID, OPD_PROJECT_ID } from '../../../utils/constants'
import { mappedPatients } from '../../lms/utils'
import { ipdPatients } from '../constants'
import { allocateApi, allocation, tableHeadText } from '../modals'
import { emptyString } from '../../Radiology/constants'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import UploadDocs from '../../patients/dashboard/UploadDocs'
import uploadIcon from '../../../assets/images/download_uplaod.png'
import visiticon from './../../../assets/images/visit.png'
import patientDashboardIcon from './../../../assets/images/patientDashboardIcon.png'
import { readableDateTimeFormat } from '../../../utils/dateUtils'
import { capitalizeFirstLetter } from '../../patients/utils'

interface Appointment {
  id: string
  resource: {
    participant: {
      individual: {
        reference: string
      }
    }[]
    period: {
      start: string
    }
    statusHistory: {
      status: string
    }[]
    resourceType: string
  }
}

interface Practitioner {
  id: string
  resource: {
    id: string
    name: {
      given: string[]
      family: string
    }[]
    resourceType: string
  }
}

const IPDPatientVisit: React.FC = () => {
  const { patientId } = useParams()
  const [bedPatientList, setBedpatient] = useState<ipdPatients[]>([])
  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  const [locationName] = useState<string>('')
  const [status] = useState<string>('ASSIGNED')
  const [allAppointments, setAllAppointments] = useState<Appointment[]>([])
  const [allPractitioners, setAllPractitioners] = useState<Practitioner[]>([])
  const [allMedications, setAllMedications] = useState([])

  useEffect(() => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getBedPatientAssignment, {}).then(
      async (data) => {
        const nodes = data.data?.bed_patient_assignment_map ?? []
        if (nodes.length) {
          const mapped = await mappedPatients(nodes)
          setBedpatient(mapped)
        }
      }
    )
  }, [])

  const filtered = useMemo(() => {
    if (
      status === emptyString &&
      locationName === emptyString &&
      patientId === emptyString
    ) {
      return bedPatientList
    }
    return bedPatientList?.filter((item: ipdPatients) => {
      return (
        (status === emptyString || item?.bedStatus === status) &&
        (locationName === emptyString || item?.location === locationName)
      )
    })
  }, [bedPatientList, status, locationName, patientId])

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    const fetchAllAppointments = async (patientRegisteredId: string) => {
      try {
        const intent = 'getAllSubEncounters'
        const state = { patientId: patientRegisteredId }
        const resp = await startSxpProxy(IPD_PROJECT_ID, intent, state)
        const data = resp?.data
        if (data && data?.entry && Array.isArray(data.entry)) {
          const appointments = data.entry.map((entry: any) => ({
            id: entry?.resource?.id,
            resource: entry?.resource,
          }))

          setAllAppointments(appointments)

          const practitioners = data?.entry.filter(
            (entry: any) => entry?.resource?.resourceType === 'Practitioner'
          )
          setAllPractitioners(practitioners)
        } else {
          console.error('Unexpected data format:', data)
          setAllAppointments([])
        }
      } catch (e) {
        console.error('Error fetching upcoming appointments:', e)
        setAllAppointments([])
      }
    }

    filtered?.forEach((d) => {
      if (d?.patientId === patientId) {
        fetchAllAppointments(d?.patient_registered_id)
      }
    })
  }, [filtered, patientId])

  const dateObject = (dateString: string) => {
    const dateObject = new Date(dateString)
    return dateObject.toISOString().split('T')[0]
  }

  const [isOpenDocsPage, setIsOpenDocsPage] = useState(false)
  const [selectedPatientId, setSelectedPatientId] = useState<string>('')

  const handleDocClick = (patientId: string) => {
    setIsOpenDocsPage(true)
    setSelectedPatientId(patientId)
  }
  const handleCloseDialog = () => {
    setIsOpenDocsPage(false)
  }

  const differenceInDays = (d1: string, d2: any) => {
    const presentDate = new Date()
    const admittedDate = new Date(d1)
    const dischargedDate = new Date(d2)
    if (d2) {
      const DifferenceInTime = dischargedDate.getTime() - admittedDate.getTime()
      const DifferenceInDays = Math.round(DifferenceInTime / (1000 * 3600 * 24))
      return DifferenceInDays
    } else {
      const DifferenceInTime = presentDate.getTime() - admittedDate.getTime()
      const DifferenceInDays = Math.round(DifferenceInTime / (1000 * 3600 * 24))
      return DifferenceInDays
    }
  }

  const getPractitionerName = (practitionerId: string) => {
    const practitioner = allPractitioners.find(
      (prac) => prac?.resource?.id === practitionerId
    )
    return practitioner
      ? `${practitioner.resource.name?.[0]?.given?.join(' ')} ${
          practitioner.resource.name?.[0]?.family
        }`
      : 'Unknown'
  }
  useEffect(() => {
    if (allAppointments?.length > 0) {
      const encounterIds = allAppointments.map((visitId: any) =>
        parseInt(visitId.id, 10)
      )
      getallVisitmedication(encounterIds)
    }
  }, [allAppointments])

  const getallVisitmedication = async (visitIds: number[]) => {
    try {
      const intent = 'getallVisitmedication'
      const state = { visitIds: visitIds }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const medications = resp?.data?.medications
      setAllMedications(medications)
    } catch (e) {
      console.error('Error fetching medication details:', e)
      setAllMedications([])
    }
  }

  return (
    <>
      <div className='sub-container'>
        <div className='bedTableHead' ref={VisitTableRef}>
          <table className='data-table table-fixed admin-table relative w100'>
            <thead className='sticky'>
              <tr>
                <th className='text-center w12'>{tableHeadText.uhid}</th>
                <th className='text-left w15'>{tableHeadText.name}</th>
                <th className='text-center w10'>{tableHeadText.mobileNo}</th>
                <th className='text-center w10'>{tableHeadText.gender}</th>
                <th className='text-center w10'>DOB</th>
                <th className='text-center w10'>{tableHeadText.bedNo}</th>
                <th className='text-center w10'>{allocation.locationOne}</th>
                <th className='text-center w10'>{tableHeadText.status}</th>
                <th className='text-center w10'>{tableHeadText.admission}</th>
                <th className='text-center w10'>{tableHeadText.discharge}</th>
                <th className='text-center w10'>{tableHeadText.noOfDays}</th>
                <th className='text-center w11'>
                  {tableHeadText.patientdocuments}
                </th>
                <th className='text-center w11'>{tableHeadText.view}</th>
              </tr>
            </thead>
            <tbody>
              {filtered?.map((d: ipdPatients, i: number) => {
                if (d?.patientId === patientId) {
                  return (
                    <tr key={i}>
                      <td className='text-center'>
                        {d?.patientId ? d?.patientId : allocation?.hyphen}
                      </td>
                      <td className='text-left'>{d?.patientName}</td>
                      <td className='text-center'>
                        {d?.patientMobile ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        {d?.patientGender ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        {d?.patientAge ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        {d?.bedNumber ?? emptyString}
                      </td>
                      <td className='text-center'>{d?.location}</td>
                      <td className='text-center'>
                        {d?.bedStatus === allocation?.assigned
                          ? allocation?.admittedList
                          : allocation?.dischargedList}
                      </td>
                      <td className='text-center'>
                        {dateObject(d?.dateCreated)}
                      </td>
                      <td className='text-center'>
                        {d?.dateOfDischarge
                          ? dateObject(d?.dateOfDischarge)
                          : '-'}
                      </td>
                      <td className='text-center'>
                        {differenceInDays(d?.dateCreated, d?.dateOfDischarge)}
                      </td>
                      <td>
                        <div
                          onClick={() =>
                            handleDocClick(d?.patient_registered_id)
                          }
                          className='uploadbtn'
                          style={{ textAlign: 'center' }}
                        >
                          <img
                            title='Upload Document`s'
                            src={uploadIcon}
                            alt='upload button'
                          />
                        </div>
                      </td>
                      <td>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                          <div>
                            <div className='uploadbtn'>
                              <img
                                title='Visits'
                                src={visiticon}
                                alt='Visits'
                                style={{ width: '15px' }}
                              />
                            </div>
                          </div>
                          <div>
                            <div className='uploadbtn'>
                              <img
                                title='patient Dashboard'
                                src={patientDashboardIcon}
                                alt='patient Dashboard'
                                style={{ width: '18px' }}
                              />
                            </div>
                          </div>
                        </div>
                        <Dialog
                          open={isOpenDocsPage}
                          onClose={handleCloseDialog}
                          maxWidth='sm'
                        >
                          <IconButton
                            onClick={handleCloseDialog}
                            style={{ display: 'flex', justifyContent: 'end' }}
                          >
                            x
                          </IconButton>
                          <DialogContent style={{ marginTop: '-2rem' }}>
                            <UploadDocs patientId={selectedPatientId} />{' '}
                          </DialogContent>
                        </Dialog>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className='sub-container'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div className='bedTableHead' ref={VisitTableRef}>
          <div>Visit History</div>

          <table className='data-table table-fixed admin-table relative w100'>
            <thead className='sticky'>
              <tr>
                <th className='table-w-8'>Sl&nbsp;No</th>
                <th className='table-w-50'>Service</th>
                <th className='table-w-50'>Doc Name</th>
                <th className='table-w-50'>Date & Time</th>
                <th className='table-w-50'>Status</th>
                <th className='table-w-10'>{tableHeadText.view}</th>
              </tr>
            </thead>
            <tbody>
              {allAppointments?.length === 0 ? (
                <tr>
                  <td colSpan={4} className='text-center'>
                    No Visits
                  </td>
                </tr>
              ) : (
                allAppointments
                  ?.filter(
                    (appointment) =>
                      appointment?.resource?.resourceType === 'Encounter'
                  )
                  .map((appointment, index) => {
                    const practitionerId =
                      appointment?.resource?.participant?.[0]?.individual?.reference.split(
                        '/'
                      )[1]
                    const practitionerName = getPractitionerName(practitionerId)
                    return (
                      <tr key={appointment.id}>
                        <td className='table-w-4' style={{ padding: '6px' }}>
                          {index + 1}
                        </td>
                        <td className='table-w-8'>{practitionerName}</td>
                        <td>
                          {readableDateTimeFormat(
                            new Date(appointment?.resource?.period?.start)
                          )}
                        </td>
                        <td></td>
                        <td>
                          {appointment?.resource?.statusHistory[0]?.status
                            .charAt(0)
                            .toUpperCase() +
                            appointment?.resource?.statusHistory[0]?.status.slice(
                              1
                            )}
                        </td>
                        <td>
                          <NavLink
                            to={`/visits/ipdVisit/${appointment.id}/vitals`}
                          >
                            view
                          </NavLink>
                        </td>
                        <td> </td>
                      </tr>
                    )
                  })
              )}
            </tbody>
          </table>
        </div>

        <div>
          <div>
            <div style={{ fontSize: '15px', marginTop: '2rem' }}>
              Medications
            </div>
            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
              <table
                className='data-table admin-table'
                style={{ position: 'relative' }}
              >
                <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
                  <tr>
                    <th className='table-w-4'>Sl&nbsp;No</th>
                    <th>Drug Name</th>
                    <th>Dose</th>
                    <th>Dose Units</th>
                    <th>Duration</th>
                    <th>Frequency</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Add.Instructions</th>
                  </tr>
                </thead>
                <tbody>
                  {allMedications?.length === 0 ? (
                    <tr>
                      <td colSpan={5} style={{ textAlign: 'center' }}>
                        No Medications found
                      </td>
                    </tr>
                  ) : (
                    allMedications?.map((medication: any, index: any) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{capitalizeFirstLetter(medication?.drug_name)}</td>
                        <td>{medication?.dose}</td>
                        <td>{capitalizeFirstLetter(medication?.dose_units)}</td>
                        <td>{medication?.duration}</td>
                        <td>{capitalizeFirstLetter(medication?.frequency)}</td>
                        <td>{medication?.start_date}</td>
                        <td>{medication?.next_visit_date}</td>

                        <td>
                          {capitalizeFirstLetter(
                            medication?.additional_instructions || '-'
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IPDPatientVisit
