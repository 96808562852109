import {
  MenuItem,
  TextField,
  useTheme,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'
import useCustomStyles from '../../../../../utils/hooks/CustomStylesHook'
import { DEPARTMENT_OPTIONS, EXPOSURE_OPTIONS } from './Constants'
import { Add, Delete } from '@mui/icons-material'

const styles = (theme: any) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 40,
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
    },
  },

  medicationsFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    rowGap: 25,
    width: '100%',
    overflow: 'auto',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      // fontWeight: 600,
    },
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
    flex: '0 1 23%',
  },
  formFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 30,
  },
  formSubHeading: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#183f7c',
  },
  table: {
    minWidth: 650,
    '& th': {
      backgroundColor: '#4682b4',
      paddingTop: '5px',
      paddingBottom: '5px',
      color: '#ffffff',
      border: '1px solid #e6e6e6',
    },
    '& td': {
      border: '1px solid #e6e6e6',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
  addButton: {
    margin: theme.spacing(1, 0),
  },
})

const PreviousMedications = ({ form }: any) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  return (
    <div className={classes?.formContainer}>
      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>GCS</div>
        <div className={classes?.medicationsFormContainer}>
          <div className={classes?.formField}>
            <label>E</label>
            <TextField
              size='small'
              name='gcs.e'
              placeholder='E'
              value={form?.values?.gcs?.e}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={form?.touched?.gcs?.e && Boolean(form?.errors?.gcs?.e)}
              helperText={form?.touched?.gcs?.e && form?.errors?.gcs?.e}
            />
          </div>

          <div className={classes?.formField}>
            <label>V</label>
            <TextField
              size='small'
              name='gcs.v'
              placeholder='V'
              value={form?.values?.gcs?.v}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={form?.touched?.gcs?.v && Boolean(form?.errors?.gcs?.v)}
              helperText={form?.touched?.gcs?.v && form?.errors?.gcs?.v}
            />
          </div>

          <div className={classes?.formField}>
            <label>M</label>
            <TextField
              size='small'
              name='gcs.m'
              placeholder='M'
              value={form?.values?.gcs?.m}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={form?.touched?.gcs?.m && Boolean(form?.errors?.gcs?.m)}
              helperText={form?.touched?.gcs?.m && form?.errors?.gcs?.m}
            />
          </div>
        </div>
      </div>

      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>Pupils</div>
        <div className={classes?.medicationsFormContainer}>
          <Table className={classes?.table}>
            <TableHead>
              <TableRow>
                <TableCell>Right</TableCell>
                <TableCell>Left</TableCell>
                <TableCell align='center'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {form?.values?.pupils?.map((_: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      size='small'
                      name={`pupils.${index}.right`}
                      onChange={form?.handleChange}
                      onBlur={form?.handleBlur}
                      error={
                        form?.touched?.pupils?.[index]?.right &&
                        Boolean(form?.errors?.pupils?.[index]?.right)
                      }
                      helperText={
                        form?.touched?.pupils?.[index]?.right &&
                        form?.errors?.pupils?.[index]?.right
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size='small'
                      name={`pupils.${index}.left`}
                      onChange={form?.handleChange}
                      onBlur={form?.handleBlur}
                      error={
                        form?.touched?.pupils?.[index]?.left &&
                        Boolean(form?.errors?.pupils?.[index]?.left)
                      }
                      helperText={
                        form?.touched?.pupils?.[index]?.left &&
                        form?.errors?.pupils?.[index]?.left
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton
                      onClick={() => {
                        const updatedPupils = [...(form?.values?.pupils || [])]
                        updatedPupils.splice(index, 1)
                        form?.setFieldValue('pupils', updatedPupils)
                      }}
                      disabled={form?.values?.previousMedications?.length === 1}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            size='small'
            className={classes?.addButton}
            variant='contained'
            color='primary'
            startIcon={<Add />}
            onClick={() => {
              form?.setFieldValue('pupils', [
                ...(form?.values?.pupils || []),
                {
                  right: '',
                  left: '',
                },
              ])
            }}
          >
            Add Row
          </Button>
        </div>
      </div>

      <div className={classes?.formFlexColumn}>
        <div className={classes?.medicationsFormContainer}>
          <div className={classes?.formField}>
            <label>FND</label>
            <TextField
              size='small'
              name='fnd'
              placeholder='FND'
              value={form?.values?.fnd}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={form?.touched?.fnd && Boolean(form?.errors?.fnd)}
              helperText={form?.touched?.fnd && form?.errors?.fnd}
            />
          </div>
          <div className={classes?.formField}>
            <label>Exposure</label>
            <TextField
              select
              size='small'
              name='exposure'
              placeholder='Exposure'
              value={form?.values?.exposure}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={form?.touched?.exposure && Boolean(form?.errors?.exposure)}
              helperText={form?.touched?.exposure && form?.errors?.exposure}
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {EXPOSURE_OPTIONS?.map(
                (item: { label: string; value: string }, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>
          <div className={classes?.formField}>
            <label>Chief Complaints</label>
            <TextField
              size='small'
              name='chiefComplaints'
              placeholder='Chief Complaints'
              value={form?.values?.chiefComplaints}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.chiefComplaints &&
                Boolean(form?.errors?.chiefComplaints)
              }
              helperText={
                form?.touched?.chiefComplaints && form?.errors?.chiefComplaints
              }
            />
          </div>
        </div>
      </div>

      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>Previous Medications</div>
        <div className={classes?.medicationsFormContainer}>
          <Table className={classes?.table}>
            <TableHead>
              <TableRow>
                <TableCell>Drug</TableCell>
                <TableCell>Dose</TableCell>
                <TableCell>Frequency</TableCell>
                <TableCell>Last dose date</TableCell>
                <TableCell>Last dose time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {form?.values?.previousMedications?.map(
                (_: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        size='small'
                        placeholder='Drug'
                        name={`previousMedications.${index}.drug`}
                        onChange={form?.handleChange}
                        onBlur={form?.handleBlur}
                        error={
                          form?.touched?.previousMedications?.[index]?.drug &&
                          Boolean(
                            form?.errors?.previousMedications?.[index]?.drug
                          )
                        }
                        helperText={
                          form?.touched?.previousMedications?.[index]?.drug &&
                          form?.errors?.previousMedications?.[index]?.drug
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        placeholder='Dose'
                        name={`previousMedications.${index}.dose`}
                        onChange={form?.handleChange}
                        onBlur={form?.handleBlur}
                        error={
                          form?.touched?.previousMedications?.[index]?.dose &&
                          Boolean(
                            form?.errors?.previousMedications?.[index]?.dose
                          )
                        }
                        helperText={
                          form?.touched?.previousMedications?.[index]?.dose &&
                          form?.errors?.previousMedications?.[index]?.dose
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        placeholder='Frequency'
                        name={`previousMedications.${index}.frequency`}
                        onChange={form?.handleChange}
                        onBlur={form?.handleBlur}
                        error={
                          form?.touched?.previousMedications?.[index]
                            ?.frequency &&
                          Boolean(
                            form?.errors?.previousMedications?.[index]
                              ?.frequency
                          )
                        }
                        helperText={
                          form?.touched?.previousMedications?.[index]
                            ?.frequency &&
                          form?.errors?.previousMedications?.[index]?.frequency
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        type='date'
                        name={`previousMedications.${index}.lastDoseDate`}
                        onChange={form?.handleChange}
                        onBlur={form?.handleBlur}
                        error={
                          form?.touched?.previousMedications?.[index]
                            ?.lastDoseDate &&
                          Boolean(
                            form?.errors?.previousMedications?.[index]
                              ?.lastDoseDate
                          )
                        }
                        helperText={
                          form?.touched?.previousMedications?.[index]
                            ?.lastDoseDate &&
                          form?.errors?.previousMedications?.[index]
                            ?.lastDoseDate
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        type='time'
                        name={`previousMedications.${index}.lastDoseTime`}
                        onChange={form?.handleChange}
                        onBlur={form?.handleBlur}
                        error={
                          form?.touched?.previousMedications?.[index]
                            ?.lastDoseTime &&
                          Boolean(
                            form?.errors?.previousMedications?.[index]
                              ?.lastDoseTime
                          )
                        }
                        helperText={
                          form?.touched?.previousMedications?.[index]
                            ?.lastDoseTime &&
                          form?.errors?.previousMedications?.[index]
                            ?.lastDoseTime
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          const updatedMedications = [
                            ...(form?.values?.previousMedications || []),
                          ]
                          updatedMedications.splice(index, 1)
                          form?.setFieldValue(
                            'previousMedications',
                            updatedMedications
                          )
                        }}
                        disabled={
                          form?.values?.previousMedications?.length === 1
                        }
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
          <Button
            size='small'
            className={classes?.addButton}
            variant='contained'
            color='primary'
            startIcon={<Add />}
            onClick={() => {
              form?.setFieldValue('previousMedications', [
                ...(form?.values?.previousMedications || []),
                {
                  drug: '',
                  dose: '',
                  frequency: '',
                  lastDoseDate: '',
                  lastDoseTime: '',
                },
              ])
            }}
          >
            Add Row
          </Button>
        </div>
      </div>

      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>ER Advice</div>
        <div className={classes?.medicationsFormContainer}>
          <Table className={classes?.table}>
            <TableHead>
              <TableRow>
                <TableCell>Lab</TableCell>
                <TableCell>Medications</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {form?.values?.erAdvice?.map((_: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      size='small'
                      placeholder='Drug'
                      name={`erAdvice.${index}.lab`}
                      onChange={form?.handleChange}
                      onBlur={form?.handleBlur}
                      error={
                        form?.touched?.erAdvice?.[index]?.lab &&
                        Boolean(form?.errors?.erAdvice?.[index]?.lab)
                      }
                      helperText={
                        form?.touched?.erAdvice?.[index]?.lab &&
                        form?.errors?.erAdvice?.[index]?.lab
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size='small'
                      placeholder='Medications'
                      name={`erAdvice.${index}.medications`}
                      onChange={form?.handleChange}
                      onBlur={form?.handleBlur}
                      error={
                        form?.touched?.erAdvice?.[index]?.medications &&
                        Boolean(form?.errors?.erAdvice?.[index]?.medications)
                      }
                      helperText={
                        form?.touched?.erAdvice?.[index]?.medications &&
                        form?.errors?.erAdvice?.[index]?.medications
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        const updatedMedications = [
                          ...(form?.values?.erAdvice || []),
                        ]
                        updatedMedications.splice(index, 1)
                        form?.setFieldValue('erAdvice', updatedMedications)
                      }}
                      disabled={form?.values?.erAdvice?.length === 1}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            size='small'
            className={classes?.addButton}
            variant='contained'
            color='primary'
            startIcon={<Add />}
            onClick={() => {
              form?.setFieldValue('erAdvice', [
                ...(form?.values?.erAdvice || []),
                {
                  lab: '',
                  medications: '',
                },
              ])
            }}
          >
            Add Row
          </Button>
        </div>
      </div>

      <div className={classes?.formFlexColumn}>
        <div className={classes?.formSubHeading}>Consultation</div>
        <div className={classes?.medicationsFormContainer}>
          <div className={classes?.formField}>
            <label>Department</label>
            <TextField
              select
              size='small'
              name='consultation.department'
              placeholder='Department'
              value={form?.values?.consultation?.department}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.consultation?.department &&
                Boolean(form?.errors?.consultation?.department)
              }
              helperText={
                form?.touched?.consultation?.department &&
                form?.errors?.consultation?.department
              }
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {DEPARTMENT_OPTIONS?.map(
                (item: { label: string; value: string }, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>
          <div className={classes?.formField}>
            <label>Time Of Initiation</label>
            <TextField
              size='small'
              name='timeOfInitiation'
              placeholder='Time of Initiation'
              value={form?.values?.timeOfInitiation}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.timeOfInitiation &&
                Boolean(form?.errors?.timeOfInitiation)
              }
              helperText={
                form?.touched?.timeOfInitiation &&
                form?.errors?.timeOfInitiation
              }
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className={classes?.formField}>
            <label>Time Of Arrival</label>
            <TextField
              size='small'
              name='timeOfArrival'
              placeholder='Time of Arrival'
              value={form?.values?.timeOfArrival}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.timeOfArrival &&
                Boolean(form?.errors?.timeOfArrival)
              }
              helperText={
                form?.touched?.timeOfArrival && form?.errors?.timeOfArrival
              }
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviousMedications
