import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import {
  CHAT_PROJECT_ID,
  IPD_PROJECT_ID,
  LABS_PROJECT_ID,
  OPD_PROJECT_ID,
} from '../../../utils/constants'
import IpdVisitPatientTable from './IpdVisitPatientTable'
import { allocateApi, allocation } from '../modals'
import DateofIpdAppointments from './DateOfIpdAppointment'
import IpdVisitMenu from './IpdVisitMenu'
import { id } from 'date-fns/locale'
import '../bms.scss'
import { selectParam, setQueryParam } from './IpdVisitSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import IpdSearch from './IpdSearch'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import IpdVisitCreateForm from './IpdVisitCreateForm'

// interface Appointment {
//   id: string
//   resource: {
//     participant: {
//       individual: {
//         reference: string
//       }
//     }[]
//     period: {
//       start: string
//     }
//     statusHistory: {
//       status: string
//     }[]
//     resourceType: string
//   }
// }

interface Practitioner {
  id: string
  resource: {
    id: string
    name: {
      given: string[]
      family: string
    }[]
    resourceType: string
  }
}

const IpdVisitsDetails = () => {
  const dispatch = useAppDispatch()
  // const [show, setShow] = useState<boolean>(true)
  const [encounters, setEncounters] = useState<any>([])
  const [registerId, setRegisterId] = useState('')
  const [selectedDate, setSelectedDate] = useState(0)
  // const [vitalsList, setVitalsList] = useState<any>([])
  // const [diagnosisList, setDiagnosisList] = useState<any>([])
  // const [labOrderList, setLabOrderList] = useState<any>([])
  // const [allAppointments, setAllAppointments] = useState<Appointment[]>([])
  // const [medications, setMedications] = useState<any>([])

  const [allPractitioners, setAllPractitioners] = useState<Practitioner[]>([])
  // const [query, setQuery] = useState<string>('')
  const [param, setParam] = useState<string>('')
  const navigate = useNavigate()
  const [executedOnce, setExecutedOnce] = useState(true)
  // const [isTrue, setIsTrue] = useState(true)
  const [selectedFilterDate, setSelectedFilterDate] = useState<string>('')
  const [filteredEncounters, setFilteredEncounters] = useState<[]>([])
  const [selectedMenu, setSelectedMenu] = useState('vitals')
  const [visit, setVisit] = useState(false)

  const handleSearch = (q: string, p: string) => {
    // setQuery(q)
    setParam(p)
    // if (!q || !p) {
    //   setShow(false)
    // } else {
    //   setShow(true)
    // }
    dispatch(setQueryParam({ param: p }))
  }
  const selectParamValue = useAppSelector(selectParam)

  useEffect(() => {
    if (selectParamValue) {
      startSxpProxy(
        CHAT_PROJECT_ID,
        allocateApi.getPatientDetailsByIdentifier,
        { patientIdentifier: selectParamValue }
      )
        .then((data) => {
          if (data?.data?.entry?.length > 0) {
            setRegisterId(data?.data?.entry?.[0]?.resource?.id)
          } else {
            setRegisterId('')
            setEncounters([])
            setFilteredEncounters([])
          }
        })
        .catch((err) => {
          console.error(err, 'err')
          setEncounters([])
          setFilteredEncounters([])
        })
    } else {
      setRegisterId('')
    }
  }, [selectParamValue])
  // useEffect(() => {
  //   setIsTrue(true)
  // }, [registerId])
  const fetchAllAppointments = async (patientRegisteredId: string) => {
    const intent = 'getAllSubEncounters'
    const state = { patientId: patientRegisteredId }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data && data?.data?.entry && Array.isArray(data.data?.entry)) {
          // const appointments = data?.data?.entry.map((entry: any) => ({
          //   id: entry?.resource?.id,
          //   resource: entry?.resource,
          // }))
          // setAllAppointments(appointments)
          const practitioners = data?.data?.entry.filter(
            (entry: any) => entry?.resource?.resourceType === 'Practitioner'
          )
          setAllPractitioners(practitioners)
          const encounter = data?.data?.entry.filter(
            (entry: any) => entry?.resource?.resourceType === 'Encounter'
          )
          setEncounters(encounter)
          setFilteredEncounters(encounter)
        } else {
          console.error('Unexpected data format:', data)
          // setAllAppointments([])
        }
      })
      .catch((e) => {
        console.error('Error fetching upcoming appointments:', e)
        // setAllAppointments([])
      })
  }

  useEffect(() => {
    if (registerId) {
      fetchAllAppointments(registerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerId])
  const fetchVitals = async (resourceId: any) => {
    try {
      const intent = 'getOPDObservations'
      const state = {
        encounterId: resourceId,
        type: 'vitals',
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.entry) {
        // setVitalsList(resp.data.entry[0].resource.component)
      } else {
        // setVitalsList([])
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchDiagnosis = async (resourceId: any) => {
    try {
      const intent = 'getOPDObservations'
      const state = {
        encounterId: resourceId,
        type: 'diagnosis',
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.entry) {
        // setDiagnosisList(resp.data.entry[0].resource.component)
      } else {
        // setDiagnosisList([])
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchLabOrders = async (id: any) => {
    try {
      const intent = 'getLabOrdersByPatientIdAndStatus'
      const state = {
        patientId: id,
        source: 'Amalapuram',
      }
      const resp = await startSxpProxy(LABS_PROJECT_ID, intent, state)
      if (resp?.data?.lab_orders) {
        // const laborders = resp?.data?.lab_orders
        // setLabOrderList(laborders)
      } else {
        // setLabOrderList([])
      }
    } catch (e) {
      console.error(e)
    }
  }
  const fetchMedication = async (resourceId: any) => {
    try {
      const intent = 'getMedicationsVisitIdApi'
      const state = {
        visitId: resourceId,
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.medications) {
        // const drugs = resp.data.medications.map((e: any) => ({
        //   drugName: e.drug_name ?? '',
        //   frequency: e.frequency ?? '',
        //   duration: e.duration ?? '',
        //   dose: e.dose ?? '',
        //   doseUnit: e.dose_units ?? '',
        //   durationUnit: e.duration_units ?? '',
        //   notes: e.additional_instructions ?? '',
        //   instruction: e.instructions ?? '',
        //   nextVisitDate: e.next_visit_date ?? '',
        // }))
        // setMedications(drugs)
      } else {
        // setMedications([])
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    if (encounters.length > 0) {
      const sortedVisitDates = encounters?.slice().sort((a: any, b: any) => {
        const aStartDate = b?.resource.id
        const bStartDate = a?.resource.id
        return aStartDate - bStartDate
      })
      const id = sortedVisitDates?.[0]?.resource?.id
      navigate(`/ipdVisit/visitDetails/${id}/vitals`)
      setExecutedOnce(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounters, executedOnce, registerId])

  const visitDateHandler = (data: any, i: any) => {
    setSelectedDate(i)
    fetchVitals(data.resource.id)
    fetchDiagnosis(data.resource.id)
    fetchMedication(data.resource.id)
    navigate(`/ipdVisit/visitDetails/${data.resource.id}/${selectedMenu}`)
    setExecutedOnce(false)
  }

  useEffect(() => {
    fetchLabOrders(id)
  }, [])
  const uniqueDates = Array.from(
    new Set(
      encounters.map((encounter: any) => {
        const startDate = encounter.resource.period?.start
        if (startDate) {
          const date = new Date(startDate)
          return date.toDateString()
        } else {
          return ''
        }
      })
    )
  ).filter((date) => date !== '')

  const handleDateFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilterDate(e.target.value)
    if (e.target.value === '') {
      setFilteredEncounters(encounters)
      if (encounters.length > 0) {
        const sortedVisitDates = encounters?.slice().sort((a: any, b: any) => {
          const aStartDate = b?.resource?.id
          const bStartDate = a?.resource?.id
          return aStartDate - bStartDate
        })
        setSelectedDate(0)
        navigate(
          `/ipdVisit/visitDetails/${sortedVisitDates?.[0]?.resource?.id}/${selectedMenu}`
        )
      }
    } else {
      const filteredEncounters = encounters?.filter((encounter: any) => {
        const date = new Date(encounter.resource.period.start)
        return date.toDateString() === e.target.value
      })
      setFilteredEncounters(filteredEncounters)
      if (filteredEncounters.length > 0) {
        const sortedVisitDates = filteredEncounters
          .slice()
          .sort((a: any, b: any) => {
            const aStartDate = b?.resource?.id
            const bStartDate = a?.resource?.id
            return aStartDate - bStartDate
          })
        setSelectedDate(0)
        navigate(
          `/ipdVisit/visitDetails/${sortedVisitDates[0].resource.id}/${selectedMenu}`
        )
      }
    }
  }

  const handleCloseForm = () => {
    setVisit(false)
  }

  return (
    <>
      {visit && (
        <Dialog open={visit} onClose={handleCloseForm} maxWidth='sm'>
          <IconButton onClick={handleCloseForm} className='flex-end f12'>
            x
          </IconButton>
          <DialogContent className='padding20 margin2'>
            <IpdVisitCreateForm
              patientExist='noExist'
              onClose={handleCloseForm}
              patientUhid={param ? param : selectParamValue}
            />
          </DialogContent>
        </Dialog>
      )}
      <div className={`${registerId ? 'mt12' : 'mt6'}`}>
        <div className='visit-content fw600'>
          <span className='mt6 ml15 bed-font'>{allocation.ipVisitDetails}</span>
          <div className='uid-container mr15'>
            <div>
              <select
                onChange={handleDateFilterChange}
                value={selectedFilterDate}
                className='unique-date-filter'
              >
                <option value=''>{allocation.allDates}</option>
                {uniqueDates.map((date: any, index) => (
                  <option key={index} value={date}>
                    {date}
                  </option>
                ))}
              </select>
            </div>
            <IpdSearch
              onSearch={handleSearch}
              onCreateVisit={() => setVisit(true)}
            />
          </div>
        </div>
        <div>
          <IpdVisitPatientTable id={registerId} />
        </div>
      </div>
      <div className='flex-display flex-grow ml10'>
        <div className='opd-menu' style={{ marginTop: '40px' }}>
          {encounters?.length > 0 && (
            <IpdVisitMenu setSelectedMenu={setSelectedMenu} />
          )}
        </div>
        <div>
          <div>
            <DateofIpdAppointments
              dateHandler={visitDateHandler}
              activeDate={selectedDate}
              visitDates={filteredEncounters}
              allPractitioners={allPractitioners}
              visitDetails={true}
              registerId={registerId}
            />
          </div>

          <div className='opd-content' style={{ flexGrow: 1 }}>
            {encounters?.length > 0 && <Outlet />}
          </div>
        </div>
      </div>
    </>
  )
}

export default IpdVisitsDetails
