import React, { useEffect } from 'react'
import { Button, useTheme, Box, TextField, MenuItem } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import {
  AMBULANCE_STATUS_OPTIONS,
  DeviceDetails,
  DeviceForm,
  INTENT_CREATE_UPDATE_DEVICE,
} from './Constants'
import { startSxpProxy } from '../../utils/api'
import { IPD_PROJECT_ID } from '../../utils/constants'
import { toast } from 'react-toastify'

const styles = (theme: any) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'start',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      fontWeight: 600,
    },
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    gap: 10,
    width: '48%',
    fontSize: '12px',
    fontWeight: '600',
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: 200,
    textTransform: 'capitalize',
    backgroundColor: '#4682b4',
  },
})

const AmbulanceForm = ({
  selectedDevice,
  setOpen,
  setRefresh,
}: {
  selectedDevice: DeviceDetails
  setOpen: (e: any) => void
  setRefresh: (e: any) => void
}) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const initialValues: DeviceForm = {
    mapId: undefined,
    ambulanceIdentifier: '',
    ambulanceNumber: '',
    emtPhone: '',
    driverPhone: '',
    proDeviceId: '',
    ambulanceStatus: '',
  }

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    submitForm,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      ambulanceIdentifier: Yup.string().required(
        'Ambulance Identifier is required'
      ),
      ambulanceNumber: Yup.string(),
      emtPhone: Yup.string().required('EMT Phone is required'),
      driverPhone: Yup.string(),
      proDeviceId: Yup.string().required('Pro Device ID is required'),
      ambulanceStatus: Yup.string().required('Ambulance Status is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleFormSubmit(values, setSubmitting)
    },
    enableReinitialize: true,
  })

  const handleFormSubmit = (data: any, setSubmitting: any) => {
    setSubmitting(true)
    const intent = INTENT_CREATE_UPDATE_DEVICE
    const state = {
      ambulanceIdentifier: data?.ambulanceIdentifier,
      ambulanceNumber: data?.ambulanceNumber,
      emtPhone: data?.emtPhone,
      driverPhone: data?.driverPhone,
      proDeviceId: data?.proDeviceId,
      ambulanceStatus: data?.ambulanceStatus,
      ...(data?.mapId !== null && { mapId: data.mapId }),
    }
    startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        setValues(data?.data?.data?.update_proRithm_amb_map_by_pk)
        toast.success(data?.data?.message)
        setSubmitting(false)
        setRefresh((prevState: boolean) => !prevState)
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err?.response?.data?.message)
        setSubmitting(false)
      })
  }

  const handlePrefillData = () => {
    setValues({
      mapId: selectedDevice?.mapId || null,
      ambulanceIdentifier: selectedDevice?.ambulanceIdentifier,
      ambulanceNumber: selectedDevice?.ambulanceNumber,
      emtPhone: selectedDevice?.emtPhone,
      driverPhone: selectedDevice?.driverPhone,
      proDeviceId: selectedDevice?.proDeviceId,
      ambulanceStatus: selectedDevice?.ambulanceStatus,
    })
  }

  useEffect(() => {
    handlePrefillData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form>
      <div className={classes?.formContainer}>
        {selectedDevice?.mapId !== null && (
          <div className={classes?.formField}>
            <label>Map ID</label>
            <TextField
              size='small'
              name='mapId'
              placeholder='Map Id'
              // error={touched.mapId && Boolean(errors.mapId)}
              // helperText={touched.mapId && errors.mapId}
              // onChange={handleChange}
              // onBlur={handleBlur}
              disabled
              value={values?.mapId}
            />
          </div>
        )}
        <div className={classes?.formField}>
          <label>Ambulance Identifier</label>
          <TextField
            name='ambulanceIdentifier'
            placeholder='Ambulance Identifier'
            error={
              touched.ambulanceIdentifier && Boolean(errors.ambulanceIdentifier)
            }
            helperText={
              touched.ambulanceIdentifier && errors.ambulanceIdentifier
            }
            size='small'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.ambulanceIdentifier}
          />
        </div>
        <div className={classes?.formField}>
          <label>Ambulance Number</label>
          <TextField
            name='ambulanceNumber'
            placeholder='Ambulance Number'
            error={touched.ambulanceNumber && Boolean(errors.ambulanceNumber)}
            helperText={touched.ambulanceNumber && errors.ambulanceNumber}
            size='small'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.ambulanceNumber}
          />
        </div>
        <div className={classes?.formField}>
          <label>EMT Phone</label>
          <TextField
            name='emtPhone'
            placeholder='EMT Phone'
            error={touched.emtPhone && Boolean(errors.emtPhone)}
            helperText={touched.emtPhone && errors.emtPhone}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            size='small'
            value={values?.emtPhone}
          />
        </div>
        <div className={classes?.formField}>
          <label>Driver Phone</label>
          <TextField
            name='driverPhone'
            placeholder='Driver Phone'
            error={touched.driverPhone && Boolean(errors.driverPhone)}
            helperText={touched.driverPhone && errors.driverPhone}
            size='small'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.driverPhone}
          />
        </div>
        <div className={classes?.formField}>
          <label>Pro Device ID</label>
          <TextField
            name='proDeviceId'
            placeholder='Pro Device ID'
            error={touched.proDeviceId && Boolean(errors.proDeviceId)}
            helperText={touched.proDeviceId && errors.proDeviceId}
            size='small'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.proDeviceId}
          />
        </div>
        <div className={classes?.formField}>
          <label>Ambulance Status</label>
          <TextField
            select
            name='ambulanceStatus'
            placeholder='Ambulance Status'
            error={touched.ambulanceStatus && Boolean(errors.ambulanceStatus)}
            helperText={touched.ambulanceStatus && errors.ambulanceStatus}
            size='small'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.ambulanceStatus}
          >
            {AMBULANCE_STATUS_OPTIONS?.map(
              (item: { label: string; value: string }, index) => {
                return (
                  <MenuItem key={index} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                )
              }
            )}
          </TextField>
        </div>
      </div>
      <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <Button
          className={classes?.submitButton}
          variant='contained'
          size='small'
          onClick={submitForm}
          disabled={isSubmitting}
        >
          Submit
        </Button>
      </Box>
    </form>
  )
}

export default AmbulanceForm
