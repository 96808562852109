import { ToastOptions } from 'react-toastify'

export type TestBase = {
  id: number
  name: string
  active: boolean
}

export type LabTest = TestBase & {
  panelId: number
  panelName: string
  sampleId: number
  sampleName: string
  description: string
  controlled?: boolean
}

export type Panel = TestBase & {
  sampleId: number
  sampleName: string
  radiologyTestName: string
  radiologyTestId: number
}

export type Sample = TestBase & {
  description: string
}

export type OrderEventTestType =
  | 'ORDERED'
  | 'ORDER_MODIFIED'
  | 'COLLECTED'
  | 'COLLECTION_APPROVED'
  | 'COLLECTION_REJECTED'
  | 'ENTERED'
  | 'ENTERED_REFERRED'
  | 'SUBMITTED'
  | 'APPROVED'
  | 'REJECTED'

export type OrderEventTest = {
  test_id: number
  status: OrderEventTestType
  updatedBy: string
  updatedOn: string
}

export type OrderEventType =
  | 'ORDERED'
  | 'ORDER_MODIFIED'
  | 'COLLECTION_IN_PROGRESS'
  | 'COLLECTION_COMPLETED'
  | 'COLLECTION_APPROVED'
  | 'COLLECTION_REJECTED'
  | 'RESULTS_ENTERED'
  | 'REFERRED_RESULTS_ENTERED'
  | 'RESULTS_SUBMITTED'
  | 'RESULTS_APPROVED'
  | 'RESULTS_REJECTED'
  | 'CANCELLED'

export type OrderEvent = {
  id: number
  type: OrderEventType
  tests?: OrderEventTest[]
  comments?: string
  created_at: string
  updated_at: string
  updated_by: string
  order_id: string
}
export type RadiologyOrderTest = {
  id: number
  name: string
  description: string
  sampleId: string
  radiology_sample: any
}
export type RadiologyLabOrder = {
  id?: string
  visit_id?: number
  radiology_lab_tests?: RadiologyOrderTest[] | undefined
  lr_number?: string
  patient_id?: string
  ordered_on?: string
  ordered_by_id?: string
  ordered_by_name?: string
  payment_status?: string
  validated_by_id?: string
  validated_by_name?: string
  status?: string
  source?: string
  price?: number
  package_id?: number | null
  summary?: string
  order_events?: OrderEvent[]
  requested_by?: string
  origin?: string
}

export type RadiologyTest = TestBase & {
  sampleId: number
  description: string
}

export type LabMode = '' | 'package' | 'test'

export type AdminOption = {
  label: string
  value: string
}

export type FormMode = 'none' | 'create' | 'edit'

export type DashboardTab = 'collection' | 'testing' | 'validation' | 'dispatch'
export type RadiologyDashboardTab = 'testing' | 'validation' | 'dispatch'

export type AgeType = 'y' | 'm' | 'd' | null

export type AdminTableProps = {
  data: any
  onEdit: (id: string) => void
}

export type Organization = {
  id: number
  name: string
}

export type TestResultType = {
  id: number
  result_type: string
  options: string[]
}

export type TestResultLimit = {
  id: number
  gender: 'male' | 'female' | 'other' | null
  min_age: number
  max_age: number
  min_value: number
  max_value: number
  abnormal: string
  test_method: {
    id: number
    method_name: string
  }
  test_unit_type: {
    id: number
    unit_type: string
  }
  age_type: AgeType
}

export type CollectTest = {
  id: number
  name: string
  test_result_limits: TestResultLimit[]
  test_result_type: TestResultType
  panel: {
    id: number
    name: string
    lab_test_sequence: {
      sequence: number[]
    }
  }
}

export type CollectOrder = {
  id: number
  lr_number: string
  payment_status: string
  source: string
  status: 'ORDERED' | 'COLLECTED' | 'SUBMITTED' | 'REJECTED' | 'COMPLETED'
}

export type LmsObservation = {
  id: string
  title: string
  content: string
}

export type LmsValues = {
  summary: string
  observations: LmsObservation[]
}

export type LmsPanel = {
  id: number
  name: string
  sampleId: number
  sampleName: string
  disabled: boolean
  checked: boolean
  collectedBy: string
  collectedOn: string
  lab_tests: LabTest[]
}

export type RadiologyResult = {
  id: number
  patient_id: string
  radiology_order_id: number
  radiology_order: CollectOrder
  radiology_test_id: number
  radiology_test: any
  value: string
  extra_value: string
  values?: LmsValues
  result_limit_id: number
  referred_out: boolean | null
  referred_to: number | null
  organization: Organization
  collected_by: string
  collected_by_id: string
  collected_on: string
  result_entered_by: string
  result_entered_by_id: string
  result_entered_on: string
  test_status: 'COLLECTED' | 'ENTERED' | 'SUBMITTED' | 'APPROVED' | 'REJECTED'
  observation: string | null
  document_url: string
  rejected_on?: string
  rejected_by_id?: string
  rejected_by_name?: string
  rejection_reason?: string
  validated_by?: string
  validated_on?: string
}

export type ResultCount = {
  total: number
  referred: number
  pending: number
}

export type RLabel = {
  name: string
  gender: string
  age: string
  patientId: string
  code: string
}

export type Package = {
  id: number
  name: string
  price: number
  panels: Panel[]
  lab_tests: LabTest[]
  active_from: string
  active_till: string
  created_by: string
  created_at: string
  updated_by: string
  updated_at: string
}

export type TestSequence = {
  id: number
  sequence: number[]
}

export type SequenceTest = {
  id: number
  name: string
}

export type SequencePanel = {
  id: number
  name: string
  lab_tests: SequenceTest[]
  lab_test_sequence?: TestSequence
}

export type InvoiceTest = {
  id: string
  name: string
  value: string
  enteredOn: string
  referred: string
  organization: {
    id: number
    name: string
  }
  unit: string
  range: number[]
  method: string
  abnormal: string
  extraValue: string
  values: LmsValues
  resultType: string
}
export type ModifiedTestPanel = {
  [key: string]: normal[]
}

export type panelObject = Record<string, testObject[]>

export type panelTests = {
  id: number
  name: string
  value: string
  enteredOn: string
  referredOn: string
  organization?: {
    id: number
    name: string
  }
  unit: string
  range: number[]
  abnormal: string
  method: string
  department: InvoiceDepartment
  resultType: string
  extraValue: string
  values: any
  interpretation?: string
}

export type testObject = {
  id: string | number
  name: string
  sequence?: number[] | null
  tests: panelTests[]
  department: InvoiceDepartment
  resultType: string
  interpretation?: string
}

export type InvoicePanel = {
  id: string
  name: string
  sequence: number[]
  tests: InvoiceTest[]
  department: InvoiceDepartment
  resultType: string
  interpretation: string
}

export type InvoiceDepartment = {
  id: string
  department_name: string
}
export type normal = {
  id: number
  name: string
  value: string
  enteredOn: string
  referredOn: string
  organisation: string | undefined
  unit: string
  range: number[]
  abnormal: string
  method: string
  department: InvoiceDepartment
  panel: panelOne
  resultType: string
  extraValue: string
  values: any
  interpretation: string
}
export type panelOne = {
  id: number
  name: string
  interpretation: string | null
  lab_test_sequence?: { sequence: number[] }
}
export type InvoiceData = {
  id: string
  name: string
  age: string
  gender: string
  mobile: string
  requestedBy: string
  lrNumber: string
  orderedOn: string
  completedOn: string
  validatedOn: string
  panels: panelObject[]
  summary: string
  orderType: string
}

export const toastOptions: ToastOptions = {
  position: 'bottom-center',
  hideProgressBar: true,
  closeButton: false,
  theme: 'dark',
  autoClose: 2000,
  bodyStyle: { padding: 0 },
}

export type PatientVisit = {
  id: string
  source: string
  doctor: string
  specialty: string
  date: string
}
export const membership = 'Membership'
export const packageName = 'Package'
export const noOrdersFound = 'No Orders found'
export const noMembersFound = 'No Members found'
export const noTestsFound = 'No Tests found'
export const printLogo = 'Print Label'
export const noData = '-'
export const emptyString = ''
export const loadingString = 'Loading Orders...'
export const collectSamples = 'Collect Samples'
export const checked = 'Checked'
export const category = 'Category'
export const appointments = 'Appointments'
export const createAppointment = 'Create Appointment'
export const bedalloction = 'Bed Alloction'
export const bedAssignmentList = 'IPD Patient List'
export const bedoverview = 'Bed Allocation'
export const bedManagementDashboard = 'Bed Dashboard'
export const radiology = 'Radiology'
export const lmsdashboard = 'LMS Dashboard'
export const createlaborders = 'Create LabOrders'
export const modes = {
  ordered: 'ordered',
  collected: 'collected',
  completed: 'completed',
  ordersToCollect: 'Orders to Collect',
  ordersToDispatch: 'Orders to Test/Dispatch',
  completedOrders: 'Completed Orders',
  modifiedOrder: 'ORDER_MODIFIED',
  package: 'package',
  test: 'test',
  mode: 'mode',
}
export const activeTabs = {
  ordered: 'ordered',
  testing: 'testing',
  completed: 'completed',
}
export const samplesHeading = 'Samples'
export const noSamplesFound = 'No Samples Found'
export const remove = 'remove'
export const removePatient = 'Register Patient'
export const previous = 'Previous'
export const next = 'Next'
export const tests = 'Tests'
export const createPackages = 'Create'
export const view = 'View'
export const activeText = 'active'
export const inactiveText = 'inactive'
export const statusentered = 'ENTERED'
export const statussubmitted = 'SUBMITTED'
export const save = 'Save'
export const deleted = 'Deleted'
export const create = 'Create'
export const createprovenance = 'CREATE'
export const noPatientsFound = 'No Patients Found'
export const noRadiologySamplesFound = 'No radiology samples Found'
export const selectTests = ' Select Tests'
export const selectPackage = 'Select Package'
export const testParameters = 'Test Parameters'
export const selectedTests = 'SelectedTests'
export const labSamplesCollected = 'Lab Samples Collected'
export const noTestParameters = 'No Test Parameters Found'
export const noTestSelected = 'No Test Selected'
export const panelSelected = ' - Panel Selected'
export const selected = ' - Selected'
export const searchHolder = 'Search by Id or Name or Source'
export const testSearchHolder = 'Search by panel name or test name'
export const createOrderText = 'Create Order'
export const patient = 'patient'
export const radiologyMessage = 'Radiology Tests Saved'
export const goBackString = 'Go Back'
export const backToSamples = 'Back to Samples'
export const selectCategory = 'Select Category'
export const dateFormatText = 'DD-MM-YYYY'
export const changeMode =
  'Do you wish to change mode? This will remove selected lab tests'
export const externalRequest = 'Requested By is mandatory for external tests...'
export const originRequest = 'Origin is mandatory for external tests...'
export const success = 'success'
export const opd = 'OPD'
export const searchError = 'Search text should be at least 3 characters long'

export const intent = {
  radiologyBacklogOrders: 'getBacklogRadiologyOrders',
  generateGlobalId: 'generateGlobalId',
  getRadiologyLabTestById: 'getRadiologyLabTestById',
  createRadiologyEvent: 'createRadiologyEvent',
  getDoctorByVisit: 'getDoctorByVisit',
  getPatientVisits: 'getPatientVisits',
  getRadiologyOrdersByDate: 'getRadiologyOrdersByDate',
  getPatientsByIdsApi: 'getPatientsByIdsApi',
  getRadiologySamplesAPI: 'getRadiologySamplesAPI',
  getRadiologyOrdersByPatientId: 'getRadiologyOrdersByPatientId',
  getPatientApi: 'getPatientApi',
  getRadiologyOrdersByPatientIdAndStatus:
    'getRadiologyOrdersByPatientIdAndStatus',
}
export const radiologyMenu = {
  dashboard: 'Dashboard',
  search: 'Search',
  referredOut: 'Referred Out',
}
export const activeButtons = {
  validation: 'Validation',
  testing: 'Testing',
  completed: 'Dispatch',
}
export const statuses = {
  ordered: 'ORDERED',
  completed: 'COMPLETED',
}

export const tableHeadText = {
  id: 'Id',
  make: 'Make',
  model: 'Model',
  port: 'Port',
  IpAddress: 'IpAddress',
  subnetMask: 'SubnetMask',
  patientId: 'UHID',
  name: 'Name',
  source: 'Operating Unit',
  mobile: 'Mobile No.',
  orderType: 'Service',
  orderedOn: 'Ordered On',
  tests: 'Tests',
  category: 'Category',
  actions: 'Actions',
  orderedBy: 'Ordered By',
  department: 'Department',
  description: 'Description',
  handledAt: 'Handled At',
  interpretation: 'Interpretation',
}
