import { useEffect, useState } from 'react'
import { IPD_PROJECT_ID, OPD_PROJECT_ID } from '../../../../utils/constants'
import { useParams } from 'react-router-dom'
import { startSxpProxy } from '../../../../utils/api'
import './medications.scss'
import RenderOnRole from '../../../../components/RenderOnRole'
import { MEDICATIONS_READ, MEDICATIONS_WRITE } from '../../../../utils/roles'
import { Medication } from '../../models'
import MedicationForm from './MedicationForm'
import { toast } from 'react-toastify'
const Medications = () => {
  const { id } = useParams()
  // const [location, setLocation] = useState('')
  const [reload, setReload] = useState(false)
  const [medications, setMedications] = useState<Medication[]>([])
  const [journeyState] = useState({
    sos: 'no',
    visitId: id,
    untilNextVisit: 'no',
  })
  const [drugName] = useState('')

  useEffect(() => {
    startSxpProxy(OPD_PROJECT_ID, 'getMedicationsVisitIdApi', { visitId: id })
      .then((data) => {
        setMedications(data?.data?.medications ?? [])
      })
      .catch((err) => {
        console.log(err)
        setMedications([])
      })
    // startSxpProxy(OPD_PROJECT_ID, 'getDoctorByVisit', { id: id })
    //   .then((resp) =>
    //     setLocation(
    //       resp?.data?.location ?? localStorage.getItem('locationName')
    //     )
    //   )
    //   .catch((err) => console.log(err))
  }, [id, journeyState, reload])

  const handleItemDelete = (medId: number) => {
    startSxpProxy(IPD_PROJECT_ID, 'deleteMedicationById', {
      medicationId: medId,
    })
      .then(() => {
        setMedications(medications?.filter((med) => med?.id !== medId))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
  }

  return (
    <RenderOnRole
      roles={[MEDICATIONS_READ, MEDICATIONS_WRITE]}
      element={
        <div className='medication-container'>
          <div className='medication-search-form'>
            <MedicationForm
              drugName={drugName}
              medications={medications}
              id={id}
              onItemDelete={handleItemDelete}
              setReload={setReload}
            />
          </div>
        </div>
      }
    />
  )
}

export default Medications
