import Circle from '../assets/greenCircle.svg'
import RedOval from '../assets/redCircle.svg'
import Available from '../assets/AvailableBed.svg'
import Occupied from '../assets/OccupiedBed.svg'
import '../bms.scss'
import { useEffect, useState, useMemo } from 'react'
import Dialog from '../../../components/Dialog'
import { useNavigate } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import KeycloakService from '../../../utils/keycloakService'
import AddDischargeDialog from '../dialog/AddDischargeDialog'
import { WardDetail, bedFilter } from '../constants'
import { allocateApi, allocation } from '../modals'
import { emptyString, toastOptions } from '../../Radiology/constants'
import { useBeds } from '../layout/useBeds'
import { ipVisitApi } from '../models'
import ToastMessage from '../../lms/components/ToastMessage'
import { toast } from 'react-toastify'

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '60%',
//   height: 400,
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
//   outline: 'none',
// }

const BedAllocation = () => {
  const navigate = useNavigate()
  const [wardDetails, setWardDetails] = useState<WardDetail[]>([])
  const [wardAllocatedId, setWardAllocatedId] = useState<any>('')
  const [refresh, setRefresh] = useState<boolean>(false)
  const locations = useBeds()
  const [status, setStatus] = useState<string>('')
  const [locationName, setLocationName] = useState<string>('Amalapuram')
  const [isAllocateOpen, setIsAllocateOpen] = useState<boolean>(false)
  const [wardId, setWardId] = useState<bedFilter>({
    id: '',
    patientId: '',
    patientName: '',
    bedNumber: '',
    bedCategory: 0,
    roomDetail: '',
    status: '',
    admission: '',
    dischargedDate: '',
    ward: 0,
    wardName: '',
    floorNumber: '',
    locationId: '',
    patientRegisteredId: '',
  })
  const [bedDetails, setBedDetails] = useState<bedFilter[]>([])
  const [patientId, setPatientId] = useState<string>('')
  const [assignedDoctor, setAssignedDoctor] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState('')

  useEffect(() => {
    if (wardDetails.length === 0) {
      setWardAllocatedId('')
    } else {
      setWardAllocatedId(wardDetails[0]?.id)
      setStatus('')
    }
  }, [wardDetails])
  const handleAllocate = (e: boolean, i: bedFilter) => {
    setIsAllocateOpen(e)
    setWardId(i)
  }
  useEffect(() => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getWardsByLocation, {
      locationId: locationName,
    })?.then((data) => {
      const wards = data.data.ward_details?.map((e: WardDetail) => {
        const id = e.id
        const name = e?.ward_name
        return {
          id,
          name,
        }
      })
      setWardDetails(wards)
      setStatus('')
    })
  }, [locationName])
  useEffect(() => {
    const state = { wardId: Number(wardAllocatedId), locationId: locationName }
    startSxpProxy(
      IPD_PROJECT_ID,
      allocateApi.getBedDetailsByLocationAndWards,
      state
    )?.then((data) => {
      const result: bedFilter[] = data.data.ward_details?.flatMap(
        (ward: any) => {
          return ward?.room_details?.flatMap((room: any) => {
            return room?.beds?.map((bed: any) => {
              const status = bed?.bed_patient_assignment_maps?.find(
                (status: any) => status?.bed_status === allocation?.assigned
              )
              return {
                id: bed.id,
                bedNumber: bed.bed_number,
                patientName: bed.patient_name,
                admission: bed.admission_date ?? emptyString,
                dischargedDate: bed.discharged_date ?? emptyString,
                bedCategory:
                  bed.bed_category_id === 3
                    ? allocation.critical
                    : allocation.nonCritical,
                status: bed.status,
                roomId: bed.room_id,
                patientId: bed.patient_id,
                roomName: room.room_name,
                roomNumber: room.room_number,
                roomDetail: `${room.room_name} ${room.room_number ?? ''}`,
                locationId: room.location_id,
                wardName: ward.ward_name,
                floorNumber: ward.floor_number,
                bedPatientMapId: status?.bed_id,
                bedPatientId: status?.id,
                bedPatientAssignedId: status?.patient_id,
                bedPatientStatus: status?.bed_status,
                bedPatientDischargeDate: status?.date_of_discharge,
              }
            })
          })
        }
      )
      setBedDetails(result)
    })
  }, [wardAllocatedId, locationName])
  useEffect(() => {
    if (patientId !== emptyString) {
      const state = {
        id: parseInt(wardId.id),
        status: allocation?.occupied,
        patientId: patientId,
        createdBy: KeycloakService?.getUsername(),
        admissionDate: selectedDate ? selectedDate : new Date().toISOString(),
        dateCreated: new Date().toISOString(),
        patientName: wardId.patientName ?? emptyString,
        patientRegisteredId: wardId.patientRegisteredId,
        assignedDoctor: assignedDoctor,
      }
      startSxpProxy(IPD_PROJECT_ID, allocateApi?.createAndUpdateBed, state)
        ?.then(() => {
          startSxpProxy(IPD_PROJECT_ID, ipVisitApi?.masterEncounter, {
            patientId: wardId?.patientRegisteredId,
          })
            ?.then(() => {
              setWardId((prevBed: any) => ({
                ...prevBed,
                patientName: '',
                patientId: '',
                gender: '',
                age: '',
              }))
              setPatientId('')
              setSelectedDate('')
              setAssignedDoctor('')
              if (window?.location?.pathname === '/bedIpd') {
                navigate('/bedIpd/bedOverview')
              } else {
                navigate('/bedIpd')
              }
            })
            .catch((err) => console.error(err, 'err'))
        })
        .catch((err) => {
          console.error(err, 'err')
          toast?.error(
            <ToastMessage
              message={err?.response?.data?.message ?? 'Unable to fetch api'}
            />,
            toastOptions
          )
          setPatientId('')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientId,
    wardId.id,
    navigate,
    wardId.patientName,
    wardId.patientRegisteredId,
  ])
  const handleWardChange = (e: any) => {
    setWardAllocatedId(e.target.value)
  }
  const handleAllocateClose = () => {
    setIsAllocateOpen(false)
  }

  // const handleAllocateConfirm = (
  //   e: boolean,
  //   bed: bedFilter,
  //   doctor?: any,
  //   date?: any
  // ) => {
  //   setIsAllocateOpen(e)
  //   setWardId(bed)
  //   setAssignedDoctor(doctor)
  //   setSelectedDate(date)
  //   setPatientId(bed?.patientId)
  // }

  const handleDischargeConfirm = (e: boolean) => {
    setIsAllocateOpen(e)
  }
  const availableBeds = bedDetails?.filter(
    (e: bedFilter) => e.status === allocation.available
  )?.length
  const occupiedBeds = bedDetails?.filter(
    (e: bedFilter) => e.status === allocation.occupied
  )?.length
  const filtered = useMemo(() => {
    if (status === emptyString) {
      return bedDetails
    }
    return bedDetails?.filter((item: any) => {
      return status === emptyString || item.status === status
    })
  }, [status, bedDetails])
  return (
    <>
      {/* {isAllocateOpen && wardId.status === allocation.available && (
        <Modal open={isAllocateOpen} onClose={handleAllocateClose}>
          <Box sx={style}>
            <AddAlllocationDialog
              onClose={() => setIsAllocateOpen(false)}
              onConfirm={handleAllocateConfirm}
              wardId={wardId}
            />
          </Box>
        </Modal>
      )} */}
      {isAllocateOpen && wardId?.status === allocation?.occupied && (
        <Dialog
          open={isAllocateOpen}
          onClose={handleAllocateClose}
          style={{ width: '67%' }}
        >
          <AddDischargeDialog
            onConfirm={handleDischargeConfirm}
            wardId={wardId}
            refresh={refresh}
            onClose={() => setIsAllocateOpen(false)}
            setRefresh={setRefresh}
            mode='layout'
          />
        </Dialog>
      )}
      <div>
        <div className='lms-filters'>
          <div>
            <select
              className='category-filter'
              name='location'
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
            >
              {locations?.map((op: any, i: number) => (
                <option key={i} value={op}>
                  {op}
                </option>
              ))}
            </select>
          </div>
          <div className='input-container'>
            {wardDetails?.length !== 0 ? (
              <select
                className='admin-bed'
                value={wardAllocatedId}
                onChange={handleWardChange}
              >
                {wardDetails?.map((op: any) => (
                  <option className='option-content' key={op.id} value={op.id}>
                    {op?.name}
                  </option>
                ))}
              </select>
            ) : (
              <span className='inform-text'>{allocation?.noWards}</span>
            )}
          </div>
          <div>
            <select
              className='category-filter'
              name='status'
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value={emptyString}>{allocation?.selectStatus}</option>
              <option value={allocation?.available}>
                {allocation?.available}
              </option>
              <option value={allocation?.occupied}>
                {allocation?.occupied}
              </option>
            </select>
          </div>
          <div className='input-container'>
            <div className='available'>
              <img src={Circle} alt='available-image' className='green' />
              <span className='inform-text'>
                {allocation?.availabeNo}
                {availableBeds}
              </span>
              <div></div>
            </div>
            <div className='occupied'>
              <img src={RedOval} alt='occupied-image' className='red' />
              <span className='inform-text'>
                {allocation?.occupiedNo}
                {occupiedBeds}
              </span>
            </div>
          </div>
        </div>
        <div className='flex-container'>
          <div className='beds-container'>
            <div className='available-container'>
              {filtered?.length > 0 ? (
                filtered?.map((d: bedFilter, i: number) => (
                  <div
                    className='overall-action'
                    onClick={() => handleAllocate(true, d)}
                    key={i}
                  >
                    <img
                      src={
                        d.status === allocation.available ? Available : Occupied
                      }
                      className='bed-image'
                    />
                    <span className='bed-action'>{d.bedNumber}</span>
                  </div>
                ))
              ) : (
                <div>{allocation.noBeds}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BedAllocation
