import React from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectLocations } from '../../location/locationSlice'

interface LocationByPrefixProps {
  id: string
}

const LocationByPrefix: React.FC<LocationByPrefixProps> = ({ id }) => {
  const locations = useAppSelector(selectLocations)
  const prefix = id?.slice(0, 6)
  const getLocationName = () => {
    switch (prefix) {
      case 'MUH777':
        return 'Mental Health App'
      case 'MUH888':
        return 'Dengue App'
      default: {
        const location = locations?.find((loc: any) =>
          loc?.resource?.identifier?.some(
            (identifier: any) => identifier?.value === prefix
          )
        )
        return location ? location?.resource?.name : 'Unknown'
      }
    }
  }

  return <span>{getLocationName()}</span>
}

export default LocationByPrefix
