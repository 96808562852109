import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Patient, Practitioner, Provenance } from 'fhir/r4'
import { makeName } from '../utils'
import { getAgeInYears } from '../../../utils/dateUtils'
import { getProvenanceData } from '../../patients/utils'
import { startSxpProxy } from '../../../utils/api'
import { REGISTRATION_PROJECT_ID } from '../../../utils/constants'
import ProvenanceView from '../../../components/ProvenanceView'
import { ProvenanceData } from '../../patients/patientsSlice'
import LocationByPrefix from '../../patients/dashboard/LocationByPrefix'

type Props = {
  patient: Patient
}

const PatientInfo = ({ patient }: Props) => {
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const age = patient ? getAgeInYears(patient.birthDate) : undefined
  const ids = patient ? patient.id : undefined

  useEffect(() => {
    if (ids) {
      startSxpProxy(REGISTRATION_PROJECT_ID, 'fetchProvenance', {
        patientIds: ids,
      })
        .then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((err) => console.log(err))
    }
  }, [ids])

  if (!patient) return null
  return (
    <div className='appointments-table-container'>
      <table className='data-table admin-table'>
        <thead>
          <tr>
            <th>UHID</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Age</th>
            <th>Mobile No.</th>
            <th>Operating Unit</th>
            <th>Created</th>
            <th>Updated</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {patient.identifier?.[2]?.value ??
                patient.identifier?.[0]?.value ??
                '-'}
            </td>
            <td>{makeName(patient.name)}</td>
            <td className='pat-gender'>{patient.gender}</td>
            <td>{age ?? '-'}</td>
            <td>{patient?.telecom?.[0]?.value}</td>
            <td>
              <LocationByPrefix
                id={
                  patient?.identifier?.[2]?.value ??
                  patient?.identifier?.[0]?.value ??
                  '-'
                }
              />
            </td>
            <td className='text-center'>
              <ProvenanceView
                date={provenances[ids ?? '']?.createdOn ?? ''}
                name={provenances[ids ?? '']?.createdBy ?? 'Unknown'}
                mode={'CREATE'}
              />
            </td>
            <td className='text-center'>
              <ProvenanceView
                date={
                  provenances[ids ?? '']?.updatedOn ??
                  provenances[ids ?? '']?.createdOn ??
                  ''
                }
                name={
                  provenances[ids ?? '']?.updatedBy ??
                  provenances[ids ?? '']?.createdBy ??
                  'Unknown'
                }
                mode={'UPDATE'}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

PatientInfo.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    birthDate: PropTypes.string,
    identifier: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      })
    ),
    name: PropTypes.array,
    gender: PropTypes.string,
    telecom: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      })
    ),
  }),
}

export default PatientInfo
