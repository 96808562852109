import React from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Button,
  useTheme,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PainScale from './PainScale'
import './IpdProgress.scss'
import { SYMPTOMS, PROGRESSNOTE_ERROR_MESSAGES } from './Constants'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'

interface FormValues {
  issues: {
    signsAndSymptoms: {
      tachypnea: boolean
      tachycardia: boolean
      fever: boolean
      headache: boolean
      abdominalPain: boolean
      bleeding: boolean
      otherObservations: string
    }
    allergies: string
    pastMedicalHistory: string
  }
  hoPresentingcomplaints: {
    chestPain: {
      chestPainDuration: string
      chestPainOnset: string
      chestPainProgression: string
      chestPainOtherFindings: string
    }
    palpitations: {
      palpitationsDuration: string
      palpitationsOnset: string
      palpitationsProgression: string
      palpitationsOtherFindings: string
    }
    breathlessness: {
      breathlessnessDuration: string
      breathlessnessOnset: string
      breathlessnessProgression: string
      breathlessnessOtherFindings: string
    }
  }
  assessment: {
    airway: {
      patent: string
      advancedAirway: string
      confirmPlacement: string
    }
    breathing: {
      ventilator: string
      mode: string
      saturation: string
      respiratoryRate: string
    }
    circulation: {
      bloodPressure: string
      heartRate: string
      vasopressors: string
      rhythmChange: string
      defibMedication: string
    }
    disability: {
      gcs: string
      grbs: string
      pupils: string
      temperature: string
    }
    exposure: {
      bedSores: string
      abrasions: string
    }
  }
  others: {
    feeding: {
      lastMealConsumedEvents: string
      NBM: string
      Enteral: string
      TPN: string
    }
    analgesia: {
      VASscore: number | null
    }
    sedation: string
    thromboprophylaxis: string
    headAndElevation: boolean
    ulcerProhylaxis: string
    glycemicControl: string
    bowelBladder: string
    intravascularDevices: string
    drugsDeescalationOfAntibiotics: string
    spontaneousBreathingTrial: string
  }
}

const styles = () => ({
  progressNoteMain: {
    '& .MuiInputBase-root': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
      marginBottom: '4px !important',
    },
    '& .MuiSelect-select': {
      fontSize: '12px !important',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
    },
  },
  progressNoteSubmitBtn: {
    backgroundColor: '#4682b4',
    textTransform: 'capitalize',
  },
})

const ProgressNoteForm: React.FC = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const initialValues: FormValues = {
    issues: {
      signsAndSymptoms: {
        tachypnea: false,
        tachycardia: false,
        fever: false,
        headache: false,
        abdominalPain: false,
        bleeding: false,
        otherObservations: '',
      },
      allergies: '',
      pastMedicalHistory: '',
    },
    hoPresentingcomplaints: {
      chestPain: {
        chestPainDuration: '',
        chestPainOnset: '',
        chestPainProgression: '',
        chestPainOtherFindings: '',
      },
      palpitations: {
        palpitationsDuration: '',
        palpitationsOnset: '',
        palpitationsProgression: '',
        palpitationsOtherFindings: '',
      },
      breathlessness: {
        breathlessnessDuration: '',
        breathlessnessOnset: '',
        breathlessnessProgression: '',
        breathlessnessOtherFindings: '',
      },
    },
    assessment: {
      airway: {
        patent: '',
        advancedAirway: '',
        confirmPlacement: '',
      },
      breathing: {
        ventilator: '',
        mode: '',
        saturation: '',
        respiratoryRate: '',
      },
      circulation: {
        bloodPressure: '',
        heartRate: '',
        vasopressors: '',
        rhythmChange: '',
        defibMedication: '',
      },
      disability: {
        gcs: '',
        grbs: '',
        pupils: '',
        temperature: '',
      },
      exposure: {
        bedSores: '',
        abrasions: '',
      },
    },
    others: {
      feeding: {
        lastMealConsumedEvents: '',
        NBM: '',
        Enteral: '',
        TPN: '',
      },
      analgesia: {
        VASscore: null,
      },
      sedation: '',
      thromboprophylaxis: '',
      headAndElevation: false,
      ulcerProhylaxis: '',
      glycemicControl: '',
      bowelBladder: '',
      intravascularDevices: '',
      drugsDeescalationOfAntibiotics: '',
      spontaneousBreathingTrial: '',
    },
  }

  const validationSchema = Yup.object().shape({
    issues: Yup.object().shape({
      signsAndSymptoms: Yup.object().shape({
        tachypnea: Yup.boolean().required(
          PROGRESSNOTE_ERROR_MESSAGES.issues.signsAndSymptoms.tachypnea
        ),
        tachycardia: Yup.boolean().required(
          PROGRESSNOTE_ERROR_MESSAGES.issues.signsAndSymptoms.tachycardia
        ),
        fever: Yup.boolean().required(
          PROGRESSNOTE_ERROR_MESSAGES.issues.signsAndSymptoms.fever
        ),
        headache: Yup.boolean().required(
          PROGRESSNOTE_ERROR_MESSAGES.issues.signsAndSymptoms.headache
        ),
        abdominalPain: Yup.boolean().required(
          PROGRESSNOTE_ERROR_MESSAGES.issues.signsAndSymptoms.abdominalPain
        ),
        bleeding: Yup.boolean().required(
          PROGRESSNOTE_ERROR_MESSAGES.issues.signsAndSymptoms.bleeding
        ),
        otherObservations: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.issues.signsAndSymptoms.otherObservations
        ),
      }),
      allergies: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.issues.allergies
      ),
      pastMedicalHistory: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.issues.pastMedicalHistory
      ),
    }),
    hoPresentingcomplaints: Yup.object().shape({
      chestPain: Yup.object().shape({
        chestPainOnset: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.chestPain
            .chestPainOnset
        ),
        chestPainProgression: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.chestPain
            .chestPainProgression
        ),
        chestPainOtherFindings: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.chestPain
            .chestPainOtherFindings
        ),
      }),
      palpitations: Yup.object().shape({
        palpitationsDuration: Yup.string(),
        palpitationsOnset: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.palpitations
            .palpitationsOnset
        ),
        palpitationsProgression: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.palpitations
            .palpitationsProgression
        ),
        palpitationsOtherFindings: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.palpitations
            .palpitationsOtherFindings
        ),
      }),
      breathlessness: Yup.object().shape({
        breathlessnessDuration: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.breathlessness
            .breathlessnessDuration
        ),
        breathlessnessOnset: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.breathlessness
            .breathlessnessOnset
        ),
        breathlessnessProgression: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.breathlessness
            .breathlessnessProgression
        ),
        breathlessnessOtherFindings: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.hoPresentingcomplaints.breathlessness
            .breathlessnessOtherFindings
        ),
      }),
    }),
    assessment: Yup.object().shape({
      airway: Yup.object().shape({
        patent: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.airway.patent
        ),
        advancedAirway: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.airway.advancedAirway
        ),
        confirmPlacement: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.airway.confirmPlacement
        ),
      }),
      breathing: Yup.object().shape({
        ventilator: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.breathing.ventilator
        ),
        mode: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.breathing.mode
        ),
        saturation: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.breathing.saturation
        ),
        respiratoryRate: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.breathing.respiratoryRate
        ),
      }),
      circulation: Yup.object().shape({
        bloodPressure: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.circulation.bloodPressure
        ),
        heartRate: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.circulation.heartRate
        ),
        vasopressors: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.circulation.vasopressors
        ),
        rhythmChange: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.circulation.rhythmChange
        ),
        defibMedication: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.circulation.defibMedication
        ),
      }),
      disability: Yup.object().shape({
        gcs: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.disability.gcs
        ),
        grbs: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.disability.grbs
        ),
        pupils: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.disability.pupils
        ),
        temperature: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.disability.temperature
        ),
      }),
      exposure: Yup.object().shape({
        bedSores: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.exposure.bedSores
        ),
        abrasions: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.assessment.exposure.abrasions
        ),
      }),
    }),
    others: Yup.object().shape({
      feeding: Yup.object().shape({
        lastMealConsumedEvents: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.others.feeding.lastMealConsumedEvents
        ),
        NBM: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.others.feeding.NBM
        ),
        Enteral: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.others.feeding.Enteral
        ),
        TPN: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.others.feeding.TPN
        ),
      }),
      analgesia: Yup.object().shape({
        VASscore: Yup.string().required(
          PROGRESSNOTE_ERROR_MESSAGES.others.analgesia.VASscore
        ),
      }),
      sedation: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.sedation
      ),
      thromboprophylaxis: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.thromboprophylaxis
      ),
      headAndElevation: Yup.boolean().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.headAndElevation
      ),
      ulcerProhylaxis: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.ulcerProhylaxis
      ),
      glycemicControl: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.glycemicControl
      ),
      bowelBladder: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.bowelBladder
      ),
      intravascularDevices: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.intravascularDevices
      ),
      drugsDeescalationOfAntibiotics: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.drugsDeescalationOfAntibiotics
      ),
      spontaneousBreathingTrial: Yup.string().required(
        PROGRESSNOTE_ERROR_MESSAGES.others.spontaneousBreathingTrial
      ),
    }),
  })
  const progressForm: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
    },
    enableReinitialize: true,
  })

  return (
    <Box p={3} className={classes?.progressNoteMain}>
      <Typography variant='h4' className='progressNote-issues-header'>
        Issues
      </Typography>
      <Grid container spacing={2}>
        <Grid item container>
          <Grid item xs={12}>
            <Typography className='progressNote-symptoms'>
              Signs and Symptoms
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={2}
            direction='row'
            className='progressNote-symptoms-box'
          >
            {SYMPTOMS?.map((symptom) => (
              <Grid item key={symptom}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={`issues.signsAndSymptoms.${symptom}`}
                      checked={
                        progressForm.values.issues.signsAndSymptoms[symptom] ||
                        false
                      }
                      onChange={progressForm.handleChange}
                      size='small'
                    />
                  }
                  label={symptom.charAt(0).toUpperCase() + symptom.slice(1)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TextField
            fullWidth
            label='Other Observations'
            name='issues.signsAndSymptoms.otherObservations'
            value={
              progressForm.values.issues.signsAndSymptoms.otherObservations ||
              ''
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            multiline
            size='small'
            error={
              progressForm.touched.issues?.signsAndSymptoms
                ?.otherObservations &&
              Boolean(
                progressForm.errors.issues?.signsAndSymptoms?.otherObservations
              )
            }
            helperText={
              progressForm.touched.issues?.signsAndSymptoms
                ?.otherObservations &&
              progressForm.errors.issues?.signsAndSymptoms?.otherObservations
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TextField
            fullWidth
            label='Allergies'
            size='small'
            name='issues.allergies'
            value={progressForm.values.issues.allergies}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            multiline
            error={
              progressForm.touched.issues?.allergies &&
              Boolean(progressForm.errors.issues?.allergies)
            }
            helperText={
              progressForm.touched.issues?.allergies &&
              progressForm.errors.issues?.allergies
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TextField
            fullWidth
            label='Past Medical History'
            name='issues.pastMedicalHistory'
            size='small'
            value={progressForm.values.issues.pastMedicalHistory}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            multiline
            error={
              progressForm.touched.issues?.pastMedicalHistory &&
              Boolean(progressForm.errors.issues?.pastMedicalHistory)
            }
            helperText={
              progressForm.touched.issues?.pastMedicalHistory &&
              progressForm.errors.issues?.pastMedicalHistory
            }
          />
        </Grid>
      </Grid>

      <Typography variant='h6' mt={3} className='progressNote-issues-header'>
        H/O Presenting Complaints
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className='progressNote-subHeader'>Chest Pain</Typography>
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Duration'
            name='hoPresentingcomplaints.chestPain.chestPainDuration'
            value={
              progressForm?.values?.hoPresentingcomplaints?.chestPain
                ?.chestPainDuration
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.hoPresentingcomplaints?.chestPain
                ?.chestPainDuration &&
              Boolean(
                progressForm.errors.hoPresentingcomplaints?.chestPain
                  ?.chestPainDuration
              )
            }
            helperText={
              progressForm.touched.hoPresentingcomplaints?.chestPain
                ?.chestPainDuration &&
              progressForm.errors.hoPresentingcomplaints?.chestPain
                ?.chestPainDuration
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Onset'
            name='hoPresentingcomplaints.chestPain.chestPainOnset'
            value={
              progressForm.values.hoPresentingcomplaints?.chestPain
                ?.chestPainOnset
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.chestPainOnset &&
              Boolean(progressForm.errors.chestPainOnset)
            }
            helperText={
              progressForm.touched.chestPainOnset &&
              progressForm.errors.chestPainOnset
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Progression'
            name='hoPresentingcomplaints.chestPain.chestPainProgression'
            value={
              progressForm.values.hoPresentingcomplaints?.chestPain
                .chestPainProgression
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.chestPainProgression &&
              Boolean(progressForm.errors.chestPainProgression)
            }
            helperText={
              progressForm.touched.chestPainProgression &&
              progressForm.errors.chestPainProgression
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Other Findings'
            name='hoPresentingcomplaints.chestPain.chestPainOtherFindings'
            value={
              progressForm.values.hoPresentingcomplaints?.chestPain
                .chestPainOtherFindings
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.chestPainOtherFindings &&
              Boolean(progressForm.errors.chestPainOtherFindings)
            }
            helperText={
              progressForm.touched.chestPainOtherFindings &&
              progressForm.errors.chestPainOtherFindings
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography className='progressNote-subHeader'>
            Palpitations
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Duration'
            name='hoPresentingcomplaints.palpitations.palpitationsDuration'
            value={
              progressForm?.values?.hoPresentingcomplaints?.palpitations
                ?.palpitationsDuration
            }
            onChange={progressForm?.handleChange}
            onBlur={progressForm?.handleBlur}
            size='small'
            error={
              progressForm?.touched?.hoPresentingcomplaints?.palpitations
                ?.palpitationsDuration &&
              Boolean(
                progressForm?.errors?.hoPresentingcomplaints?.palpitations
                  ?.palpitationsDuration
              )
            }
            helperText={
              progressForm?.touched?.hoPresentingcomplaints?.palpitations
                ?.palpitationsDuration &&
              progressForm.errors.hoPresentingcomplaints?.palpitations
                ?.palpitationsDuration
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Onset'
            name='hoPresentingcomplaints.palpitations.palpitationsOnset'
            value={
              progressForm?.values?.hoPresentingcomplaints?.palpitations
                ?.palpitationsOnset
            }
            onChange={progressForm?.handleChange}
            onBlur={progressForm?.handleBlur}
            size='small'
            error={
              progressForm?.touched?.palpitationsOnset &&
              Boolean(progressForm?.errors?.palpitationsOnset)
            }
            helperText={
              progressForm?.touched?.palpitationsOnset &&
              progressForm?.errors?.palpitationsOnset
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Progression'
            name='hoPresentingcomplaints.palpitations.palpitationsProgression'
            value={
              progressForm?.values?.hoPresentingcomplaints?.palpitations
                .palpitationsProgression
            }
            onChange={progressForm?.handleChange}
            onBlur={progressForm?.handleBlur}
            size='small'
            error={
              progressForm?.touched?.palpitationsProgression &&
              Boolean(progressForm?.errors?.palpitationsProgression)
            }
            helperText={
              progressForm?.touched?.palpitationsProgression &&
              progressForm?.errors?.palpitationsProgression
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Other Findings'
            name='hoPresentingcomplaints.palpitations.palpitationsOtherFindings'
            value={
              progressForm?.values?.hoPresentingcomplaints?.palpitations
                .palpitationsOtherFindings
            }
            onChange={progressForm?.handleChange}
            onBlur={progressForm?.handleBlur}
            size='small'
            error={
              progressForm?.touched?.palpitationsOtherFindings &&
              Boolean(progressForm?.errors?.palpitationsOtherFindings)
            }
            helperText={
              progressForm?.touched?.palpitationsOtherFindings &&
              progressForm?.errors?.palpitationsOtherFindings
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' className='progressNote-subHeader'>
            Breathlessness
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Duration'
            name='hoPresentingcomplaints.breathlessness.breathlessnessDuration'
            value={
              progressForm?.values?.hoPresentingcomplaints?.breathlessness
                ?.breathlessnessDuration
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm?.touched?.hoPresentingcomplaints?.breathlessness
                ?.breathlessnessDuration &&
              Boolean(
                progressForm?.errors?.hoPresentingcomplaints?.breathlessness
                  ?.breathlessnessDuration
              )
            }
            helperText={
              progressForm?.touched?.hoPresentingcomplaints?.breathlessness
                ?.breathlessnessDuration &&
              progressForm?.errors?.hoPresentingcomplaints?.breathlessness
                ?.breathlessnessDuration
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Onset'
            name='hoPresentingcomplaints.breathlessness.breathlessnessOnset'
            value={
              progressForm?.values?.hoPresentingcomplaints?.breathlessness
                ?.breathlessnessOnset
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm?.touched?.breathlessnessOnset &&
              Boolean(progressForm?.errors?.breathlessnessOnset)
            }
            helperText={
              progressForm?.touched?.breathlessnessOnset &&
              progressForm?.errors?.breathlessnessOnset
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Progression'
            name='hoPresentingcomplaints.breathlessness.breathlessnessProgression'
            value={
              progressForm?.values?.hoPresentingcomplaints?.breathlessness
                ?.breathlessnessProgression
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm?.touched?.breathlessnessProgression &&
              Boolean(progressForm?.errors?.breathlessnessProgression)
            }
            helperText={
              progressForm?.touched?.breathlessnessProgression &&
              progressForm?.errors?.breathlessnessProgression
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Other Findings'
            name='hoPresentingcomplaints.breathlessness.breathlessnessOtherFindings'
            value={
              progressForm?.values?.hoPresentingcomplaints?.breathlessness
                ?.breathlessnessOtherFindings
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm?.touched?.breathlessnessOtherFindings &&
              Boolean(progressForm?.errors?.breathlessnessOtherFindings)
            }
            helperText={
              progressForm?.touched?.breathlessnessOtherFindings &&
              progressForm?.errors?.breathlessnessOtherFindings
            }
          />
        </Grid>
      </Grid>

      <Typography variant='h6' mt={3} className='progressNote-issues-header'>
        Assessment
      </Typography>

      <Typography
        variant='subtitle1'
        mt={2}
        className='progressNote-subHeader-assesment'
      >
        Airway
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Patent'
            name='assessment.airway.patent'
            value={progressForm.values.assessment?.airway?.patent}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.patent && Boolean(progressForm.errors.patent)
            }
            helperText={
              progressForm.touched.patent && progressForm.errors.patent
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Advanced Airway'
            name='assessment.airway.advancedAirway'
            value={progressForm.values.assessment?.airway?.advancedAirway}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.advancedAirway &&
              Boolean(progressForm.errors.advancedAirway)
            }
            helperText={
              progressForm.touched.advancedAirway &&
              progressForm.errors.advancedAirway
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Confirm Placement'
            name='assessment.airway.confirmPlacement'
            value={progressForm.values.assessment?.airway?.confirmPlacement}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.confirmPlacement &&
              Boolean(progressForm.errors.confirmPlacement)
            }
            helperText={
              progressForm.touched.confirmPlacement &&
              progressForm.errors.confirmPlacement
            }
          />
        </Grid>
      </Grid>

      <Typography mt={2} className='progressNote-subHeader-assesment'>
        Breathing
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Ventilator'
            name='assessment.breathing.ventilator'
            value={progressForm.values?.assessment?.breathing?.ventilator}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.ventilator &&
              Boolean(progressForm.errors.ventilator)
            }
            helperText={
              progressForm.touched.ventilator && progressForm.errors.ventilator
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Mode'
            name='assessment.breathing.mode'
            value={progressForm.values?.assessment?.breathing?.mode}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.mode && Boolean(progressForm.errors.mode)
            }
            helperText={progressForm.touched.mode && progressForm.errors.mode}
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Saturation'
            name='assessment.breathing.saturation'
            value={progressForm.values?.assessment?.breathing?.saturation}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.saturation &&
              Boolean(progressForm.errors.saturation)
            }
            helperText={
              progressForm.touched.saturation && progressForm.errors.saturation
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Respiratory Rate'
            name='assessment.breathing.respiratoryRate'
            value={progressForm.values.assessment.breathing.respiratoryRate}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.respiratoryRate &&
              Boolean(progressForm.errors.respiratoryRate)
            }
            helperText={
              progressForm.touched.respiratoryRate &&
              progressForm.errors.respiratoryRate
            }
          />
        </Grid>
      </Grid>

      <Typography
        variant='subtitle1'
        mt={2}
        className='progressNote-subHeader-assesment'
      >
        Circulation
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Blood Pressure'
            name='assessment.circulation.bloodPressure'
            value={progressForm.values?.assessment?.circulation?.bloodPressure}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.bloodPressure &&
              Boolean(progressForm.errors.bloodPressure)
            }
            helperText={
              progressForm.touched.bloodPressure &&
              progressForm.errors.bloodPressure
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Heart Rate'
            name='assessment.circulation.heartRate'
            value={progressForm.values.assessment?.circulation?.heartRate}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.heartRate &&
              Boolean(progressForm.errors.heartRate)
            }
            helperText={
              progressForm.touched.heartRate && progressForm.errors.heartRate
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Vasopressors'
            name='assessment.circulation.vasopressors'
            value={progressForm.values.assessment?.circulation?.vasopressors}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.vasopressors &&
              Boolean(progressForm.errors.vasopressors)
            }
            helperText={
              progressForm.touched.vasopressors &&
              progressForm.errors.vasopressors
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Rhythm Change'
            name='assessment.circulation.rhythmChange'
            value={progressForm.values.assessment?.circulation?.rhythmChange}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.rhythmChange &&
              Boolean(progressForm.errors.rhythmChange)
            }
            helperText={
              progressForm.touched.rhythmChange &&
              progressForm.errors.rhythmChange
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Defib Medication'
            name='assessment.circulation.defibMedication'
            value={
              progressForm.values?.assessment?.circulation?.defibMedication
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched.defibMedication &&
              Boolean(progressForm.errors.defibMedication)
            }
            helperText={
              progressForm.touched.defibMedication &&
              progressForm.errors.defibMedication
            }
          />
        </Grid>
      </Grid>

      <Typography mt={2} className='progressNote-subHeader-assesment'>
        Disability
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            select
            label='GCS'
            name='assessment.disability.gcs'
            value={progressForm.values?.assessment?.disability?.gcs}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            className='progressNote-gcs'
            error={
              progressForm.touched.assessment?.disability?.gcs &&
              Boolean(progressForm.errors.assessment?.disability?.gcs)
            }
            helperText={
              progressForm.touched.assessment?.disability?.gcs &&
              progressForm.errors.assessment?.disability?.gcs
            }
          >
            <MenuItem value='E4,V5,M6'>E4,V5,M6</MenuItem>
            <MenuItem value='E3,V4,M5'>E3,V4,M5</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} sm={6} xl={3} lg={3}>
            <TextField
              fullWidth
              label='Grbs'
              name='assessment.disability.grbs'
              value={progressForm.values.assessment.disability.grbs}
              onChange={progressForm.handleChange}
              onBlur={progressForm.handleBlur}
              size='small'
              error={
                progressForm.touched.grbs && Boolean(progressForm.errors.grbs)
              }
              helperText={progressForm.touched.grbs && progressForm.errors.grbs}
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={3} lg={3}>
            <TextField
              fullWidth
              label='Pupils'
              name='assessment.disability.pupils'
              value={progressForm.values.assessment.disability.pupils}
              onChange={progressForm.handleChange}
              onBlur={progressForm.handleBlur}
              size='small'
              error={
                progressForm.touched.pupils &&
                Boolean(progressForm.errors.pupils)
              }
              helperText={
                progressForm.touched.pupils && progressForm.errors.pupils
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={3} lg={3}>
            <TextField
              fullWidth
              label='Temperature'
              name='assessment.disability.temperature'
              value={progressForm.values.assessment.disability.temperature}
              onChange={progressForm.handleChange}
              onBlur={progressForm.handleBlur}
              size='small'
              error={
                progressForm.touched.temperature &&
                Boolean(progressForm.errors.temperature)
              }
              helperText={
                progressForm.touched.temperature &&
                progressForm.errors.temperature
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography
        variant='subtitle1'
        mt={2}
        className='progressNote-subHeader-assesment'
      >
        Exposure
      </Typography>
      <Grid container spacing={2}>
        <Grid item container spacing={2} sm={6} xl={3} lg={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Bed Sores'
              name='assessment.exposure.bedSores'
              value={progressForm.values.assessment.exposure.bedSores}
              onChange={progressForm.handleChange}
              onBlur={progressForm.handleBlur}
              size='small'
              error={
                progressForm.touched.bedSores &&
                Boolean(progressForm.errors.bedSores)
              }
              helperText={
                progressForm.touched.bedSores && progressForm.errors.bedSores
              }
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2} sm={6} xl={3} lg={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Abrasions'
              name='assessment.exposure.abrasions'
              value={progressForm.values.assessment.exposure.abrasions}
              onChange={progressForm.handleChange}
              onBlur={progressForm.handleBlur}
              size='small'
              error={
                progressForm.touched.abrasions &&
                Boolean(progressForm.errors.abrasions)
              }
              helperText={
                progressForm.touched.abrasions && progressForm.errors.abrasions
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography
        mt={2}
        paddingBottom={1}
        className='progressNote-issues-header'
      >
        Others
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Last Meal Consumed Events'
            name='others.feeding.lastMealConsumedEvents'
            value={
              progressForm.values?.others?.feeding?.lastMealConsumedEvents ?? ''
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.feeding?.lastMealConsumedEvents &&
              Boolean(
                progressForm.errors?.others?.feeding?.lastMealConsumedEvents
              )
            }
            helperText={
              progressForm.touched?.others?.feeding?.lastMealConsumedEvents &&
              progressForm.errors?.others?.feeding?.lastMealConsumedEvents
            }
          />
        </Grid>
        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='NBM'
            name='others.feeding.NBM'
            value={progressForm.values?.others?.feeding?.NBM ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.feeding?.NBM &&
              Boolean(progressForm.errors?.others?.feeding?.NBM)
            }
            helperText={
              progressForm.touched?.others?.feeding?.NBM &&
              progressForm.errors?.others?.feeding?.NBM
            }
          />
        </Grid>
        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Enteral'
            name='others.feeding.Enteral'
            value={progressForm.values?.others?.feeding?.Enteral ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.feeding?.Enteral &&
              Boolean(progressForm.errors?.others?.feeding?.Enteral)
            }
            helperText={
              progressForm.touched?.others?.feeding?.Enteral &&
              progressForm.errors?.others?.feeding?.Enteral
            }
          />
        </Grid>
        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='TPN'
            name='others.feeding.TPN'
            value={progressForm.values?.others?.feeding?.TPN ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.feeding?.TPN &&
              Boolean(progressForm.errors?.others?.feeding?.TPN)
            }
            helperText={
              progressForm.touched?.others?.feeding?.TPN &&
              progressForm.errors?.others?.feeding?.TPN
            }
          />
        </Grid>

        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='VAS Score'
            name='others.analgesia.VASscore'
            value={progressForm.values?.others?.analgesia?.VASscore ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            disabled
            error={
              progressForm.touched?.others?.analgesia?.VASscore &&
              Boolean(progressForm.errors?.others?.analgesia?.VASscore)
            }
            helperText={
              progressForm.touched?.others?.analgesia?.VASscore &&
              progressForm.errors?.others?.analgesia?.VASscore
            }
          />
        </Grid>

        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Sedation'
            name='others.sedation'
            value={progressForm.values?.others?.sedation ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.sedation &&
              Boolean(progressForm.errors?.others?.sedation)
            }
            helperText={
              progressForm.touched?.others?.sedation &&
              progressForm.errors?.others?.sedation
            }
          />
        </Grid>
        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Thromboprophylaxis'
            name='others.thromboprophylaxis'
            value={progressForm.values?.others?.thromboprophylaxis ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.thromboprophylaxis &&
              Boolean(progressForm.errors?.others?.thromboprophylaxis)
            }
            helperText={
              progressForm.touched?.others?.thromboprophylaxis &&
              progressForm.errors?.others?.thromboprophylaxis
            }
          />
        </Grid>

        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Ulcer Prophylaxis'
            name='others.ulcerProhylaxis'
            value={progressForm.values?.others?.ulcerProhylaxis ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.ulcerProhylaxis &&
              Boolean(progressForm.errors?.others?.ulcerProhylaxis)
            }
            helperText={
              progressForm.touched?.others?.ulcerProhylaxis &&
              progressForm.errors?.others?.ulcerProhylaxis
            }
          />
        </Grid>
        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Glycemic Control'
            name='others.glycemicControl'
            value={progressForm.values?.others?.glycemicControl ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.glycemicControl &&
              Boolean(progressForm.errors?.others?.glycemicControl)
            }
            helperText={
              progressForm.touched?.others?.glycemicControl &&
              progressForm.errors?.others?.glycemicControl
            }
          />
        </Grid>
        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Bowel Bladder'
            name='others.bowelBladder'
            value={progressForm.values?.others?.bowelBladder ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.bowelBladder &&
              Boolean(progressForm.errors?.others?.bowelBladder)
            }
            helperText={
              progressForm.touched?.others?.bowelBladder &&
              progressForm.errors?.others?.bowelBladder
            }
          />
        </Grid>

        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Intravascular Devices'
            name='others.intravascularDevices'
            value={progressForm.values?.others?.intravascularDevices ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.intravascularDevices &&
              Boolean(progressForm.errors?.others?.intravascularDevices)
            }
            helperText={
              progressForm.touched?.others?.intravascularDevices &&
              progressForm.errors?.others?.intravascularDevices
            }
          />
        </Grid>
        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='De-escalation of Antibiotics'
            name='others.drugsDeescalationOfAntibiotics'
            value={
              progressForm.values?.others?.drugsDeescalationOfAntibiotics ?? ''
            }
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.drugsDeescalationOfAntibiotics &&
              Boolean(
                progressForm.errors?.others?.drugsDeescalationOfAntibiotics
              )
            }
            helperText={
              progressForm.touched?.others?.drugsDeescalationOfAntibiotics &&
              progressForm.errors?.others?.drugsDeescalationOfAntibiotics
            }
          />
        </Grid>

        <Grid item xs={3} sm={6} xl={3} lg={3}>
          <TextField
            fullWidth
            label='Spontaneous Breathing Trial'
            name='others.spontaneousBreathingTrial'
            value={progressForm.values?.others?.spontaneousBreathingTrial ?? ''}
            onChange={progressForm.handleChange}
            onBlur={progressForm.handleBlur}
            size='small'
            error={
              progressForm.touched?.others?.spontaneousBreathingTrial &&
              Boolean(progressForm.errors?.others?.spontaneousBreathingTrial)
            }
            helperText={
              progressForm.touched?.others?.spontaneousBreathingTrial &&
              progressForm.errors?.others?.spontaneousBreathingTrial
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                name='others.headAndElevation'
                checked={progressForm.values?.others?.headAndElevation ?? false}
                onChange={progressForm.handleChange}
                onBlur={progressForm.handleBlur}
                size='small'
              />
            }
            label='Head and Elevation'
          />
        </Grid>
      </Grid>
      <PainScale setFieldValue={progressForm.setFieldValue} />
      <Box mt={3} display='flex' justifyContent='center'>
        <Button
          type='button'
          variant='contained'
          className={classes?.progressNoteSubmitBtn}
          onClick={() => progressForm.handleSubmit()}
        >
          Submit
        </Button>
      </Box>
    </Box>
  )
}

export default ProgressNoteForm
