import React, { useEffect, useState } from 'react'
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom'
import RenderOnRole from '../../../components/RenderOnRole'
import { startSxpProxy } from '../../../utils/api'
import {
  CHAT_PROJECT_ID,
  OPD_PROJECT_ID,
  LABS_PROJECT_ID,
  IPD_PROJECT_ID,
} from '../../../utils/constants'
import '../bms.scss'
import {
  HISTORY_READ,
  VITALS_READ,
  SYSTEMIC_READ,
  DIAGNOSIS_READ,
  MEDICATIONS_READ,
  LAB_ORDERS_READ,
} from '../../../utils/roles'
import IpdVisitMenu from './IpdVisitMenu'
import DateofIpdAppointments from './DateOfIpdAppointment'
import IpdVisitPatientTable from './IpdVisitPatientTable'
import { allocateApi, allocation } from '../modals'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectPatientId, setQueryParam } from './IpdVisitSlice'
import '../../../components/component.scss'
import IpdSearch from './IpdSearch'
import { Dialog, IconButton, DialogContent } from '@mui/material'
import IpdVisitCreateForm from './IpdVisitCreateForm'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

// interface Appointment {
//   id: string
//   resource: {
//     participant: {
//       individual: {
//         reference: string
//       }
//     }[]
//     period: {
//       start: string
//     }
//     statusHistory: {
//       status: string
//     }[]
//     resourceType: string
//   }
// }

interface Practitioner {
  id: string
  resource: {
    id: string
    name: {
      given: string[]
      family: string
    }[]
    resourceType: string
  }
}

const IpdVisit = () => {
  const { id } = useParams()
  const location = useLocation()
  const { status, index } = location.state || {}
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [selectedDate, setSelectedDate] = useState<number | null>(null)
  // const [vitalsList, setVitalsList] = useState<any>([])
  // const [diagnosisList, setDiagnosisList] = useState<any>([])
  // const [labOrderList, setLabOrderList] = useState<any>([])
  // const [medications, setMedications] = useState<any>([])
  // const [allAppointments, setAllAppointments] = useState<Appointment[]>([])
  const [encounters, setEncounters] = useState<any>([])
  const [registerId, setRegisterId] = useState('')
  const [allPractitioners, setAllPractitioners] = useState<Practitioner[]>([])
  // const [show, setShow] = useState<boolean>(false)
  // const [query, setQuery] = useState<string>('')
  const [param, setParam] = useState<string>('')
  // const [patientId, setPatientId] = useState<string>('')
  const [selectedFilterDate, setSelectedFilterDate] = useState<string>('')
  const [filteredEncounters, setFilteredEncounters] = useState<[]>([])
  // const [selectedEncounterStatus, setSelectedEncounterStatus] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState('vitals')
  // const [useStatus, setUseStatus] = useState(false)
  const [dataUpdated, setDataUpdated] = useState(false)
  const [visit, setVisit] = useState(false)
  const [isTrue, setIsTrue] = useState(false)
  useEffect(() => {
    if (status) {
      setIsTrue(true)
    }
  }, [status])
  useEffect(() => {
    if (param) {
      setIsTrue(false)
      setSelectedDate(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  const value = useAppSelector(selectPatientId)

  useEffect(() => {
    if (status && !param) {
      // setUseStatus(true)
      setSelectedDate(index)
    } else {
      // setUseStatus(false)
      setSelectedDate(index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = (q: string, p: string) => {
    // setQuery(q)
    setParam(p)
    // setPatientId('')
    // if (!q || !p) {
    //   setShow(false)
    // } else {
    //   setShow(true)
    // }
    dispatch(setQueryParam({ patientId: p }))
  }

  const handleBackClickIpVisit = () => {
    navigate('/bedIpd/ipVisit/dashboard')
  }

  useEffect(() => {
    if (value) {
      startSxpProxy(
        CHAT_PROJECT_ID,
        allocateApi.getPatientDetailsByIdentifier,
        { patientIdentifier: value }
      )
        .then((data) => {
          if (data?.data?.entry?.length > 0) {
            setRegisterId(data?.data?.entry?.[0]?.resource?.id)
          } else {
            setRegisterId('')
            setEncounters([])
            setFilteredEncounters([])
          }
        })
        .catch((err) => {
          console.error(err, 'err')
          setEncounters([])
          setFilteredEncounters([])
        })
    } else {
      setRegisterId('')
      setEncounters([])
      setFilteredEncounters([])
    }
  }, [value])

  const fetchAllAppointments = async (patientRegisteredId: string) => {
    const intent = 'getAllSubEncounters'
    const state = { patientId: patientRegisteredId }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then(async (data) => {
        if (data && data?.data?.entry && Array.isArray(data.data?.entry)) {
          // const appointments = data?.data?.entry.map((entry: any) => ({
          //   id: entry?.resource?.id,
          //   resource: entry?.resource,
          // }))
          // setAllAppointments(appointments)

          const practitioners = data?.data?.entry.filter(
            (entry: any) => entry?.resource?.resourceType === 'Practitioner'
          )
          setAllPractitioners(practitioners)
          const encounter = data?.data?.entry.filter(
            (entry: any) => entry?.resource?.resourceType === 'Encounter'
          )

          setEncounters(encounter)
          setFilteredEncounters(encounter)
        } else {
          console.error('Unexpected data format:', data)
          // setAllAppointments([])
        }
      })
      .catch((e) => {
        console.error('Error fetching upcoming appointments:', e)
        // setAllAppointments([])
        setEncounters([])
        setFilteredEncounters([])
        setAllPractitioners([])
      })
  }

  useEffect(() => {
    if (registerId) {
      fetchAllAppointments(registerId)
    }
  }, [registerId, dataUpdated])

  useEffect(() => {
    if (encounters.length > 0) {
      const sortedVisitDates = encounters?.slice().sort((a: any, b: any) => {
        const aStartDate = b?.resource?.id
        const bStartDate = a?.resource?.id
        return aStartDate - bStartDate
      })
      if (isTrue) {
        const findIndex = sortedVisitDates.findIndex(
          (e: any) => e?.resource?.id === id
        )
        setSelectedDate(findIndex)
      } else {
        navigate(
          `/visits/ipdVisit/${sortedVisitDates?.[0]?.resource?.id}/${selectedMenu}`
        )
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounters, isTrue])

  const fetchVitals = async (resourceId: any) => {
    try {
      const intent = 'getOPDObservations'
      const state = {
        encounterId: resourceId,
        type: 'vitals',
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.entry) {
        // setVitalsList(resp.data.entry[0].resource.component)
      } else {
        // setVitalsList([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchDiagnosis = async (resourceId: any) => {
    try {
      const intent = 'getOPDObservations'
      const state = {
        encounterId: resourceId,
        type: 'diagnosis',
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.entry) {
        // setDiagnosisList(resp.data.entry[0].resource.component)
      } else {
        // setDiagnosisList([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchLabOrders = async (id: any) => {
    try {
      const intent = 'getLabOrdersByPatientIdAndStatus'
      const state = {
        patientId: id,
        source: 'Amalapuram',
      }
      const resp = await startSxpProxy(LABS_PROJECT_ID, intent, state)
      if (resp?.data?.lab_orders) {
        // const laborders = resp?.data?.lab_orders
        // setLabOrderList(laborders)
      } else {
        // setLabOrderList([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchLabOrders(id)
  }, [id])

  const fetchMedication = async (resourceId: any) => {
    try {
      const intent = 'getMedicationsVisitIdApi'
      const state = {
        visitId: resourceId,
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.medications) {
        // const drugs = resp.data.medications.map((e: any) => ({
        //   drugName: e.drug_name ?? '',
        //   frequency: e.frequency ?? '',
        //   duration: e.duration ?? '',
        //   dose: e.dose ?? '',
        //   doseUnit: e.dose_units ?? '',
        //   durationUnit: e.duration_units ?? '',
        //   notes: e.additional_instructions ?? '',
        //   instruction: e.instructions ?? '',
        //   nextVisitDate: e.next_visit_date ?? '',
        // }))
        // setMedications(drugs)
      } else {
        // setMedications([])
      }
    } catch (e) {
      console.error(e)
    }
  }

  const visitDateHandler = (data: any, i: any) => {
    setSelectedDate(i)
    fetchVitals(data.resource.id)
    fetchDiagnosis(data.resource.id)
    fetchMedication(data.resource.id)
    // const isFinished = data.resource.status === 'finished'
    // setSelectedEncounterStatus(isFinished)
  }
  const handleCloseForm = () => {
    setVisit(false)
    setDataUpdated(true)
  }

  const uniqueDates = Array.from(
    new Set(
      encounters.map((encounter: any) => {
        const startDate = encounter.resource.period?.start
        if (startDate) {
          const date = new Date(startDate)
          return date.toDateString()
        } else {
          return ''
        }
      })
    )
  ).filter((date) => date !== '')

  const handleDateFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilterDate(e.target.value)
    if (e.target.value === '') {
      setFilteredEncounters(encounters)
      if (encounters.length > 0) {
        const sortedVisitDates = encounters?.slice().sort((a: any, b: any) => {
          const aStartDate = b?.resource?.id
          const bStartDate = a?.resource?.id
          return aStartDate - bStartDate
        })
        setSelectedDate(0)
        navigate(
          `/visits/ipdVisit/${sortedVisitDates?.[0]?.resource?.id}/${selectedMenu}`
        )
      }
    } else {
      const filteredEncounters = encounters?.filter((encounter: any) => {
        const date = new Date(encounter.resource.period.start)
        return date.toDateString() === e.target.value
      })
      setFilteredEncounters(filteredEncounters)
      if (filteredEncounters.length > 0) {
        const sortedVisitDates = filteredEncounters
          .slice()
          .sort((a: any, b: any) => {
            const aStartDate = b?.resource?.id
            const bStartDate = a?.resource?.id
            return aStartDate - bStartDate
          })
        setSelectedDate(0)
        navigate(
          `/visits/ipdVisit/${sortedVisitDates[0].resource.id}/${selectedMenu}`
        )
      }
    }
  }

  const visitCreate = () => {
    setVisit(true)
  }

  const element = (
    <div
      className='opd-container'
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          color: '#090968',
          fontWeight: 600,
        }}
      >
        <span
          onClick={handleBackClickIpVisit}
          className='back-button-cursor-dashboard'
        >
          <KeyboardBackspaceIcon />
        </span>
        <span className='s12 mt5 ml15'>IP Patient Visit</span>
        <div className='uid-container'>
          <div>
            <select
              onChange={handleDateFilterChange}
              value={selectedFilterDate}
              style={{
                padding: '4px',
                borderRadius: '5px',
                backgroundColor: 'white',
                border: '1px solid  #aeafb5',
              }}
            >
              <option value=''>{allocation.allDates}</option>
              {uniqueDates.map((date: any, index) => (
                <option key={index} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </div>
          <IpdSearch onSearch={handleSearch} onCreateVisit={visitCreate} />
        </div>
      </div>
      <div>
        <IpdVisitPatientTable id={registerId} />
      </div>
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <div className='mt3rem'>
          {encounters?.length > 0 && (
            <IpdVisitMenu
              setSelectedDate={() => {
                setSelectedDate(selectedDate)
              }}
              setSelectedMenu={setSelectedMenu}
            />
          )}
        </div>
        <div>
          <div>
            <DateofIpdAppointments
              dateHandler={visitDateHandler}
              activeDate={selectedDate}
              visitDates={filteredEncounters}
              allPractitioners={allPractitioners}
              visitType={true}
              selectedMenu={selectedMenu}
              setIndex={setSelectedDate}
            />
          </div>
          <div className='opd-content ml5 flex-grow'>
            {encounters?.length > 0 && <Outlet />}
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <>
      {visit && (
        <Dialog open={visit} onClose={handleCloseForm} maxWidth='sm'>
          <IconButton
            onClick={handleCloseForm}
            className='flex-end f12 ml-auto'
          >
            x
          </IconButton>
          <DialogContent className='padding20 margin2'>
            <IpdVisitCreateForm
              patientExist='noExist'
              onClose={handleCloseForm}
              patientUhid={param ? param : value}
            />
          </DialogContent>
        </Dialog>
      )}
      <RenderOnRole
        roles={[
          HISTORY_READ,
          VITALS_READ,
          SYSTEMIC_READ,
          DIAGNOSIS_READ,
          MEDICATIONS_READ,
          LAB_ORDERS_READ,
        ]}
        element={element}
      />
    </>
  )
}

export default IpdVisit
