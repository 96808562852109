import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { startSxpProxy, uploadImage } from '../../../utils/api'
import { FILE_SERVER_URL, OPD_PROJECT_ID } from '../../../utils/constants'
import { toast } from 'react-toastify'
import Lightbox from '../../../components/LightBox/LightBox'
import Loading from '../../../form/fields/Loading'
import uploadIcon from '../../../assets/icons/Upload.svg'
import ToastMessage from '../../lms/components/ToastMessage'

interface Document {
  docId: string
  title: string
  url: string
}

const UploadDocs = (patientId: any) => {
  const docsRef = useRef<HTMLInputElement | null>(null)
  const [uploadedDoc, setUploadedDoc] = useState<Document[]>([])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDocUrl, setSelectedDocUrl] = useState('')

  const [loading, setLoading] = useState(false)
  const [closeLightbox, setCloseLightbox] = useState<boolean>(true)

  const handleLightBoxClose = () => {
    setIsModalOpen(false)
    setSelectedDocUrl('')
    setCloseLightbox(false)
  }

  const fetchPatientDocs = useCallback(
    async (id: any = patientId) => {
      setLoading(true)
      try {
        const intent = 'getPatientReports'
        const state = {
          patientId: id.patientId,
        }
        const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
        if (resp.data.entry) {
          const docData: Document[] = resp.data.entry.map((e: any) => ({
            docId: e.resource.id,
            url: e.resource.content[0].attachment.url,
            title: e.resource.content[0].attachment.title,
          }))
          setUploadedDoc(docData)
        } else {
          setUploadedDoc([])
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    },
    [patientId]
  )

  useEffect(() => {
    fetchPatientDocs(patientId)
  }, [patientId, fetchPatientDocs])

  const uploadPatientDocs = async (
    urls: string,
    title = '',
    id = patientId
  ) => {
    if (urls) {
      setLoading(true)

      try {
        const intent = 'uploadPatientReport'
        const state = {
          patientId: id.patientId,
          reportUrl: urls,
          title: title,
        }
        const resp: any = await startSxpProxy(OPD_PROJECT_ID, intent, state)
        if (resp.data) {
          const docData: Document[] = [
            {
              docId: resp.data.id,
              url: resp.data.content[0].attachment.url,
              title: resp.data.content[0].attachment.title,
            },
          ]
          setUploadedDoc((prev) => [...prev, ...docData])
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleUploadDoc = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)

    const files = e.target.files
    if (files?.length) {
      const formData = new FormData()
      // for (let i = 0; i < files.length; i++) {
      //   formData.append('file', files[i])
      // }
      formData.append('file', files[0])
      const fileName = files?.[0]?.name ?? ''
      try {
        const response = await uploadImage(formData)
        const urls = `${FILE_SERVER_URL}file/${response.data[0]}`
        // for (const name of response.data) {
        //   urls.push(`${FILE_SERVER_URL}file/${name}`)
        // }
        // setUploadedDoc((prev) => [...(prev || []), ...urls])
        await uploadPatientDocs(urls, fileName)
      } catch (err) {
        console.error('error uploading the file', err)
      }
    } else {
      console.error('----- No --------')
    }
    setLoading(false)
  }

  const handlePreview = (url: string) => {
    setSelectedDocUrl(url)
    setIsModalOpen(true)
    setCloseLightbox(true)
  }

  const handleDownload = (url: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = ''
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.click()
  }

  const handleDelete = async (DocId: number) => {
    try {
      const intent = 'deletePatientReport'
      const state = {
        id: DocId,
      }
      await startSxpProxy(OPD_PROJECT_ID, intent, state)
      toast(<ToastMessage message={'Report Deleted Successfully'} />, {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false,
        theme: 'dark',
        autoClose: 1500,
        data: {
          completed: true,
        },
      })
      await fetchPatientDocs()
    } catch (e) {
      console.error(e)
    }
  }

  const DocumentModal = () => {
    const handleCloseModal = () => {
      setIsModalOpen(false)
      setSelectedDocUrl('')
    }
    return (
      <div className={`document-modal ${isModalOpen ? 'open' : ''}`}>
        {selectedDocUrl?.endsWith('.pdf') && (
          <div className='document-modal-content'>
            <button className='close-modal-button' onClick={handleCloseModal}>
              Close
            </button>
            <iframe
              src={selectedDocUrl}
              width='100%'
              height='100%'
              title='Document Preview'
            />
          </div>
        )}
        {!selectedDocUrl?.endsWith('.pdf') && closeLightbox && (
          <Lightbox
            image={selectedDocUrl}
            title='image'
            onClose={handleLightBoxClose}
          />
        )}
      </div>
    )
  }

  const handleIconClick = () => {
    if (docsRef.current) {
      docsRef.current.click()
    }
  }

  return (
    <div className='DocContainer'>
      <div className='mainCard'>
        <h3>
          <div className='docViewBody'>
            <div className='docUploadBtn'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between !important',
                }}
              >
                <label htmlFor='docUrl'>
                  <span className='docUrl'>Patient Documents </span>
                </label>
                <img
                  title='Upload Documents'
                  src={uploadIcon}
                  alt='upload button'
                  onClick={handleIconClick}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <input
                id='docUrl'
                ref={docsRef}
                type='file'
                accept='image/*, application/pdf'
                style={{ display: 'none' }}
                onChange={handleUploadDoc}
              />
            </div>
          </div>
        </h3>
        <div className='docView'>
          <ul className='docListView'>
            {uploadedDoc.length > 0 &&
              uploadedDoc.map((data: any, index: number) => {
                const { docId, url } = data
                const serialNumber = index + 1

                return (
                  <li className='docListItem' key={index}>
                    <div className='docItemInfo'>
                      <div className='docItemSerial'>{`${serialNumber} - `}</div>
                      <div className='docItemTitle'>{data.title}</div>
                      <div className='docItemButtons'>
                        <button
                          className='docItemButton'
                          onClick={() => handlePreview(url)}
                        >
                          Preview
                        </button>
                        <button
                          className='docItemButton'
                          onClick={() => handleDownload(url)}
                        >
                          Download
                        </button>
                        <button
                          className='docItemButton'
                          onClick={() => handleDelete(docId)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
      {isModalOpen && <DocumentModal />}
      {loading && <Loading />}
    </div>
  )
}

export default UploadDocs
