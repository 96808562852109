import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import useCustomStyles from '../../../../../utils/hooks/CustomStylesHook'
import {
  AIRWAY_OPTIONS,
  BREATHING_OPTIONS,
  DECISION_OPTIONS,
  displayPeripheralPulse,
} from './Constants'
import MenBodySvgComponent from '../../../../InvasiveLines/MenBodySvgComponent'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

const styles = () => ({
  assessmentDetailsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '2%',
    flexWrap: 'wrap',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    width: '48%',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  formFieldContainer: {
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    flexDirection: 'row',
    gap: 20,
    width: '100%',
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
    flex: '0 1 50%',
  },
  humanBodyContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 'auto',
    width: '50%',
    '@media (max-width: 768px)': {
      width: '100%',
      marginTop: '2rem',
      height: '70vh',
    },
  },
  checkBoxField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    fontSize: '12px',
    fontWeight: '600',
  },
  invasiveLinesPopoverBtn: {
    backgroundColor: '#4682b4',
    textTransform: 'capitalize',
    width: 100,
    alignSelf: 'center',
  },
  invasiveLinesPopoverHeading: {
    color: '#183f7c',
    textTransform: 'capitalize',
    fontSize: '13px',
    fontWeight: 600,
  },
  invasiveLinesPopoverTextField: {
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
      minWidth: '240px',
    },
  },
  invasiveLinesPopoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 8,
  },
  invasiveLinesTableContainer: {
    paddingTop: 30,
    width: '100%',
  },
  invasiveLinesTable: {
    border: '1px solid #e6e6e6',
    width: '95%',
  },
  invasiveLinesTableHead: {
    backgroundColor: '#4682b4',
    '& th': {
      color: '#ffffff',
      padding: '4px',
      fontSize: '12px',
    },
  },
  invasiveLinesTableCell: {
    border: '1px solid #e6e6e6',
    padding: '4px',
    fontSize: '12px',
  },
  widthInvasiveCell: {
    width: '15%',
  },
  widthValueCell: {
    width: '15%',
  },
  widthActionsCell: {
    width: '3%',
  },
  peripheralPulsesTable: {
    height: 300,
    minWidth: 320,
    '@media (max-width: 768px)': {
      maxHeight: 300,
      height: 'auto',
    },
  },
  assessmentDetailsHeading: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#183f7c',
  },
})

function AssessmentDetails({ form }: any) {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const [currentInvasiveLine, setCurrentInvasiveLine] = React.useState('')
  const [selectedDot, setSelectedDot] = React.useState('')

  const bodyPeripheralPulses = [
    { cx: 76, cy: 44, name: 'carotidRight' },
    { cx: 90, cy: 44, name: 'carotidLeft' },
    { cx: 40, cy: 108, name: 'brachialRight' },
    { cx: 127, cy: 108, name: 'brachialLeft' },
    { cx: 20, cy: 148, name: 'radialRight' },
    { cx: 148, cy: 146, name: 'radialLeft' },
    { cx: 67, cy: 155, name: 'femoralRight' },
    { cx: 98, cy: 155, name: 'femoralLeft' },
    { cx: 64, cy: 217, name: 'poplitealRight' },
    { cx: 106, cy: 217, name: 'poplitealLeft' },
    { cx: 53, cy: 290, name: 'tibalRight' },
    { cx: 120, cy: 290, name: 'tibalLeft' },
    { cx: 47, cy: 307, name: 'dorasilRight' },
    { cx: 118, cy: 307, name: 'dorasilLeft' },
  ]

  const [anchorElPeripheral, setAnchorElPeripheral] =
    React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAnchorElPeripheral(event.currentTarget)
  }

  const handleClose = (e: any) => {
    e.preventDefault()
    setAnchorElPeripheral(null)
  }

  const openPeripheral = Boolean(anchorElPeripheral)
  const idPopover = openPeripheral ? 'simple-popover-peripheral' : undefined

  const handleClickDots = (e: any) => {
    e.preventDefault()
    setSelectedDot(e.target.id)
    setCurrentInvasiveLine(
      form?.values?.assessment?.circulation?.peripheralPulses?.[e.target.id]
    )
    handleClick(e)
  }

  const handleInvasiveLineSubmit = (e: any) => {
    e.preventDefault()
    form?.setFieldValue('assessment.circulation.peripheralPulses', {
      ...form?.values?.assessment?.circulation?.peripheralPulses,
      [selectedDot]: currentInvasiveLine,
    })
    setAnchorElPeripheral(null)
  }

  const handleCurrentInvasiveLine = (e: any) => {
    setCurrentInvasiveLine(e.target.value)
  }

  const removeInvasiveLine = (invasiveLine: string) => {
    const newObject = {
      ...form?.values?.assessment?.circulation?.peripheralPulses,
    }
    delete newObject[invasiveLine]
    form?.setFieldValue('assessment.circulation.peripheralPulses', newObject)
  }

  return (
    <div className={classes?.assessmentDetailsContainer}>
      <Popover
        id={idPopover}
        open={openPeripheral}
        anchorEl={anchorElPeripheral}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableScrollLock={true}
      >
        <div className={classes?.invasiveLinesPopoverContainer}>
          <div className={classes?.invasiveLinesPopoverHeading}>
            {displayPeripheralPulse(selectedDot)}
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 2,
                top: 2,
                color: (theme) => theme.palette.grey[500],
              }}
              size='small'
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
          <TextField
            className={classes?.invasiveLinesPopoverTextField}
            name={selectedDot}
            value={currentInvasiveLine}
            onChange={handleCurrentInvasiveLine}
            size='small'
            multiline
            rows={2}
          />
          <Button
            variant='contained'
            onClick={handleInvasiveLineSubmit}
            size='small'
            className={classes?.invasiveLinesPopoverBtn}
          >
            Save
          </Button>
        </div>
      </Popover>
      <div className={classes?.formContainer}>
        <div className={classes?.formFieldContainer}>
          <div className={classes?.assessmentDetailsHeading}>Assessment</div>
        </div>
        <div className={classes?.formFieldContainer}>
          <div className={classes?.formField}>
            <label>Airway</label>
            <TextField
              size='small'
              select
              name='assessment.airway'
              placeholder='Airway'
              value={form?.values?.assessment?.airway}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.assessment?.airway &&
                Boolean(form?.errors?.assessment?.airway)
              }
              helperText={
                form?.touched?.assessment?.airway &&
                form?.errors?.assessment?.airway
              }
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {AIRWAY_OPTIONS?.map(
                (item: { label: string; value: string }, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>
          {form?.values?.assessment?.airway && (
            <div className={classes?.formField}>
              <label>Primary Survey</label>
              <TextField
                size='small'
                name='assessment.primarySurveyAirway'
                placeholder='Primary Survey'
                value={form?.values?.assessment?.primarySurveyAirway}
                onChange={form?.handleChange}
                onBlur={form?.handleBlur}
                error={
                  form?.touched?.assessment?.primarySurveyAirway &&
                  Boolean(form?.errors?.assessment?.primarySurveyAirway)
                }
                helperText={
                  form?.touched?.assessment?.primarySurveyAirway &&
                  form?.errors?.assessment?.primarySurveyAirway
                }
              />
            </div>
          )}
        </div>
        <div className={classes?.formFieldContainer}>
          <div className={classes?.formField}>
            <label>Cervical Spine Immobilization</label>
            <TextField
              size='small'
              select
              name='assessment.cervicalSpineImmobilization'
              placeholder='Cervical Spine Immobilization'
              value={form?.values?.assessment?.cervicalSpineImmobilization}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.assessment?.cervicalSpineImmobilization &&
                Boolean(form?.errors?.assessment?.cervicalSpineImmobilization)
              }
              helperText={
                form?.touched?.assessment?.cervicalSpineImmobilization &&
                form?.errors?.assessment?.cervicalSpineImmobilization
              }
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              {DECISION_OPTIONS?.map(
                (item: { label: string; value: string }, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                }
              )}
            </TextField>
          </div>
          {form?.values?.assessment?.cervicalSpineImmobilization && (
            <div className={classes?.formField}>
              <label>Primary Survey</label>
              <TextField
                size='small'
                name='assessment.primarySurveyCervicalSpineImmobilization'
                placeholder='Primary Survey'
                value={
                  form?.values?.assessment
                    ?.primarySurveyCervicalSpineImmobilization
                }
                onChange={form?.handleChange}
                onBlur={form?.handleBlur}
                error={
                  form?.touched?.assessment
                    ?.primarySurveyCervicalSpineImmobilization &&
                  Boolean(
                    form?.errors?.assessment
                      ?.primarySurveyCervicalSpineImmobilization
                  )
                }
                helperText={
                  form?.touched?.assessment
                    ?.primarySurveyCervicalSpineImmobilization &&
                  form?.errors?.assessment
                    ?.primarySurveyCervicalSpineImmobilization
                }
              />
            </div>
          )}
        </div>
        <div className={classes?.formFieldContainer}>
          <FormControl>
            <FormLabel className={classes?.formField}>Breathing</FormLabel>
            <FormGroup>
              {Object.keys(BREATHING_OPTIONS)?.map((key) => (
                <Box
                  key={key}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                >
                  <FormControlLabel
                    labelPlacement='top'
                    control={
                      <Checkbox
                        size='small'
                        checked={form?.values?.assessment?.breathing[key]}
                        onChange={(event) =>
                          form?.setFieldValue(
                            `assessment.breathing.${key}`,
                            event.target.checked
                          )
                        }
                        name={`assessment.breathing.${key}`}
                      />
                    }
                    label={key?.toUpperCase()}
                    key={key}
                  />
                  {form?.values?.assessment?.breathing?.[key] && (
                    <div className={classes?.checkBoxField}>
                      <label>Primary Survey</label>
                      <TextField
                        size='small'
                        variant='outlined'
                        name={`assessment.breathing.${key}PrimarySurvey`}
                        value={
                          form?.values?.assessment?.breathing?.[
                            `${key}PrimarySurvey`
                          ]
                        }
                        onChange={form?.handleChange}
                        placeholder='Primary Survey'
                      />
                    </div>
                  )}
                </Box>
              ))}
            </FormGroup>
          </FormControl>
        </div>
        <div className={classes?.formFieldContainer}>
          <div className={classes?.formField}>
            <label>Capillary Refill Time (in sec)</label>
            <TextField
              size='small'
              name='assessment.circulation.capillaryRefillTime'
              placeholder='Capillary Refill Time (in sec)'
              value={form?.values?.assessment?.circulation?.capillaryRefillTime}
              onChange={form?.handleChange}
              onBlur={form?.handleBlur}
              error={
                form?.touched?.assessment?.circulation?.capillaryRefillTime &&
                Boolean(
                  form?.errors?.assessment?.circulation?.capillaryRefillTime
                )
              }
              helperText={
                form?.touched?.assessment?.circulation?.capillaryRefillTime &&
                form?.errors?.assessment?.circulation?.capillaryRefillTime
              }
            />
          </div>
        </div>

        <div className={classes?.invasiveLinesTableContainer}>
          <TableContainer className={classes?.peripheralPulsesTable}>
            <Table className={classes?.invasiveLinesTable}>
              <TableHead className={classes?.invasiveLinesTableHead}>
                <TableRow>
                  <TableCell
                    className={`${classes?.invasiveLinesTableCell} ${classes?.widthInvasiveCell}`}
                  >
                    Peripheral Pulses
                  </TableCell>
                  <TableCell
                    className={`${classes?.invasiveLinesTableCell} ${classes?.widthValueCell}`}
                  >
                    Value
                  </TableCell>
                  <TableCell
                    className={`${classes?.invasiveLinesTableCell} ${classes?.widthActionsCell}`}
                    align='center'
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(
                  form?.values?.assessment?.circulation?.peripheralPulses ?? {}
                )
                  .filter(
                    ([key]) =>
                      key !== 'startTime' &&
                      key !== 'observationId' &&
                      key !== 'patientId' &&
                      key !== 'encounterId'
                  )
                  ?.map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell className={classes?.invasiveLinesTableCell}>
                        {displayPeripheralPulse(key)}
                      </TableCell>
                      <TableCell className={classes?.invasiveLinesTableCell}>
                        {value as any}
                      </TableCell>
                      <TableCell
                        className={classes?.invasiveLinesTableCell}
                        align='center'
                      >
                        <IconButton
                          size='small'
                          onClick={() => removeInvasiveLine(key)}
                        >
                          <DeleteOutlineOutlinedIcon fontSize='small' />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className={classes?.humanBodyContainer}>
        <Box>
          <MenBodySvgComponent
            handlePieceClick={handleClickDots}
            invasiveLines={bodyPeripheralPulses}
          />
        </Box>
      </div>
    </div>
  )
}

export default AssessmentDetails
