export function displayInvasiveLine(name: string) {
  switch (name) {
    case 'evd':
      return 'EVD'
    case 'ryles':
      return 'Ryles'
    case 'etTube':
      return 'ET Tube'
    case 'cvcHdRight':
      return 'CVC/HD(R)'
    case 'cvcHdLeft':
      return 'CVC/HD(L)'
    case 'icdRight':
      return 'ICD(R)'
    case 'icdLeft':
      return 'ICD(L)'
    case 'peripheralLinesRightHand':
      return 'Peripheral Lines(R)'
    case 'peripheralLinesLeftHand':
      return 'Peripheral Lines(L)'
    case 'peg':
      return 'PEG'
    case 'arterialLinesRight':
      return 'Arterial Lines(R)'
    case 'arterialLinesLeft':
      return 'Arterial Lines(L)'
    case 'femoralRight':
      return 'Femoral(R)'
    case 'femoralLeft':
      return 'Femoral(L)'
    case 'foleysCatheter':
      return 'Foleys Catheter'
    case 'peripheralLinesRightLeg':
      return 'Peripheral Lines(R)'
    case 'peripheralLinesLeftLeg':
      return 'Peripheral Lines(L)'
    case 'flatusTube':
      return 'Flatus Tube'
    case 'tracheostomyTube':
      return 'Tracheostomy Tube'
    default:
      return name
  }
}

export const invasiveLinesPost = 'inasiveLinesIPD'
export const invasiveLinesGet = 'getInvasiveLine'
