// import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './App'
import './index.css'
import KeycloakService from './utils/keycloakService'
import HttpService from './utils/httpService'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

const renderRoot = () => {
  root.render(
    // <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
    // </StrictMode>
  )
}

KeycloakService.initKeycloak(renderRoot)
HttpService.configure()
